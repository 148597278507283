import React, { useEffect, useState } from "react";
import style from "./CampaignDash.module.css";
import dots from "../../../Assets/dots.png";
import { useNavigate } from "react-router-dom";
import Drawer from "../../Drawer/Drawer";
import backendUrl from "../../../config";
import toast from "react-hot-toast";
import oopsimage from "../../../Assets/oops.png";
import editimage from "../../../Assets/edit1.png";
import deleteimage from "../../../Assets/delete1.png";

const CampaignDash = () => {
  const [mydata, setMydata] = useState([]);
  const [companyData, setCompanydata] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem("campaigncustomers");
    localStorage.removeItem("campaigntemplate");
    localStorage.removeItem("campaignname");
    localStorage.removeItem("campaignId");
    localStorage.removeItem("campaigntimings");
  }, []);

  useEffect(() => {
    const id1 = localStorage.getItem("token1");
    console.log("company id", id1);
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendUrl}/campaign/all/${id1}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("response", response);
        const responseData = await response.json();
        console.log("responseData", responseData);

        if (response.ok) {
          setMydata(responseData);
        } else {
          console.error("Did not receive the  campaigns ");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);


  const handleDelete = async (id) => {
    console.log("id", id);
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      try {
        const response = await fetch(`${backendUrl}/campaign/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("response", response);
        /* const responseData = await response.json();
             console.log("responseData", responseData);*/
        const responseData = await response.json();
        console.log("responseData", responseData);

        if (response.ok) {
          toast.success(responseData.message);
          window.location.reload();
        } else {
          toast.error(responseData.message);
          console.error("Did not receive the  campaigns ");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    } else {
      toast.error("deletion cancelled");
    }
  };
  const handleEdit = (item) => {
    // Assuming 'id' is nested inside '_id'
    console.log("edit company id", item.id);

    // Your other logic here

    localStorage.setItem("campaignname", item.name);
    localStorage.setItem("campaigncustomers", JSON.stringify(item.customers));
    localStorage.setItem("campaigntemplate", item.template);
    localStorage.setItem("campaigntimings", item.timings);
    localStorage.setItem("campaignId", item.id);
    navigate("/createcampaign");
  };
  
  const fetchData1 = async (id) => {
    try {
      const response = await fetch(`${backendUrl}/company/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("response", response);
      const responseData = await response.json();
      console.log("responseData", responseData);

      if (response.ok) {
        setCompanydata(responseData);
      } else {
        console.error("Did not receive the  campaigns ");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }
  };

  const handlenewclick = () => {
    localStorage.removeItem("campaigncustomers");
    localStorage.removeItem("campaigntemplate");
    localStorage.removeItem("campaignname");
    localStorage.removeItem("campaignId");
    localStorage.removeItem("campaigntimings");

    navigate("/createcampaign");
  };

  return (
    <>
      <Drawer />
      <div className={style.mainCamOuter}>
        <div className={style.campaignOuter}>
          <div className={style.camheadingOuter}>
            <div className={style.camheading}>My Campaign</div>
            <div className={style.camheadingbutton} onClick={handlenewclick}>
              + New Campaign
            </div>
          </div>

          <div className={style.camMiddle}>
            {mydata.length > 0 ? (
              mydata?.map((item) => (
                <div className={style.camone}>
                  <div className={style.camfirst}>
                    <div className={style.camNT}>
                      <div className={style.camname}>{item.name}</div>
                      <div className={style.camtime}>{item.createdAt}</div>
                    </div>
                    {/* <div className={style.camanalytics}>Analytics</div> */}
                  </div>
                  <div className={style.camsecond}>
                    <div
                      className={style.camDraftbtn}
                      onClick={() => handleEdit(item)}
                    >
                      <img src={editimage} />
                    </div>
                    <div
                      className={style.camDraftbtn}
                      onClick={() => handleDelete(item.id)}
                    >
                      <img src={deleteimage} />
                    </div>
                    {/* <img className={style.dots} src={dots} alt="img" /> */}
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className={style.adoopsOuter}>
                  <div className={style.cdheading1}>Oops</div>
                  <div className={style.adoopstext}>
                    Looks like you have not added any campaign
                  </div>
                  <div className={style.adoopsimg}>
                    <img style={{ width: "54%" }} src={oopsimage} alt="img" />
                  </div>
                  <div
                    className={style.cdbtn}
                    onClick={() => navigate("/createcampaign")}
                  >
                    + Add Campaign
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CampaignDash;
