import React, { useEffect, useState } from "react"
import style from "./AgentDetails.module.css"
import whatsapp from '../../../Assets/whatsapp.png';
import deleteimage from "../../../Assets/delete.png"
import editimage from '../../../Assets/editpng.png'
import Drawer from '../../Drawer/Drawer'
import arrow from '../../../Assets/arrow.png'
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import backendUrl from "../../../config";
import { collection, onSnapshot, query, where, getFirestore, getDocs,doc,getDoc } from "firebase/firestore";
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDESCOInR4uOQJxMVnqDvX19SVOI7SBI78",
  authDomain: "whatsapp-46bee.firebaseapp.com",
  projectId: "whatsapp-46bee",
  storageBucket: "whatsapp-46bee.appspot.com",
  messagingSenderId: "890469867275",
  appId: "1:890469867275:web:d9123e1f429a56c2e00c0e",
  measurementId: "G-M7QKZ8Z9FV"
};

// initialize firebase app 
initializeApp(firebaseConfig)

// initialize firestore
const db = getFirestore()



const AgentDetails = () => {
    const [mydata, setMydata] = useState({})
    const [list,setlist]=useState()
    const { id } = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${backendUrl}/agent/${id}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                console.log("response", response)

                if (response.ok) {
                    const responseData = await response.json();
                    console.log("responseData", responseData);
                    setMydata(responseData)
                    const temp=responseData.assignedCustomers
                    console.log("temp",temp)

                    const querySnapshot = await getDocs(collection(db, 'Customers'));
                    const documents = querySnapshot.docs.map(doc => doc.data());
                    console.log("documents".documents)
                    const filtereddocuments=documents.filter((item)=>temp.includes(item.comid))
                    console.log("filtered documents",filtereddocuments)
                    setlist(filtereddocuments)

                } else {
                    console.error('Did not receive the  agent ');
                }
            } catch (error) {
                console.error('Error during Fetching:', error);
            }
        }
        fetchData()
    }, [])

    const handleDeleteAgent = async () => {
        try {
            const response = await fetch(`${backendUrl}/agent/delete/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-type": "application/json",
                },
            });

            if (response.ok) {
                navigate(`/agent/${localStorage.getItem("token")}`)
            } else {
                console.log("Delete Failed")
            }

        }
        catch (error) {
            console.log("Error during deleting agent ", error)
        }
    }







    return (
      <>
        <Drawer />
        <div className={style.profileOuter}>
          <div className={style.detOuter}>
            <div className={style.detHeading}>
              <div
                className={style.addHeading}
                onClick={() =>
                  navigate(`/agent/${localStorage.getItem("token")}`)
                }
              >
                <img src={arrow} alt="img" />
                <div className={style.addHeadingtext}>Agent Details</div>
              </div>
              <div className={style.detHeading2}>
                <div
                  className={style.deteditbtn}
                  onClick={() => navigate(`/agent/update/${id}`)}
                >
                  <img src={editimage} alt="img" />
                  Edit
                </div>
                <div
                  className={style.detdeletebtn}
                  onClick={() => handleDeleteAgent()}
                >
                  <img src={deleteimage} alt="img" />
                  Delete
                </div>
              </div>
            </div>
            <div className={style.detMiddle}>
              <div className={style.detname}>{mydata.name}</div>
              <div className={style.detinfo}>
                <div className={style.detsmallheading}>Email</div>
                <div className={style.detsmallinfo}>{mydata.email}</div>
              </div>
              <div className={style.detinfo}>
                <div className={style.detsmallheading}>Phone Number :</div>
                <div className={style.detsmallinfo}>{mydata.phoneNumber}</div>
              </div>
              <div className={style.detinfo}>
                <div className={style.detsmallheading}>Notes :</div>
                <div className={style.detsmallinfo}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
                </div>
              </div>
            </div>
            <div className={style.adtableheading}>Assigned Customers</div>
            <div className={style.adtable}>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Contact</th>
                    <th>Email</th>

                    {/*<th>Assigned On</th>*/}
                    <th>Chat</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {list?.map((row, index) => (
                    <tr key={index}>
                      <td>{row.name}</td>
                      <td>{row.phone}</td>
                      <td>{row.email}</td>
                      {/*<td>{row.assignedOn}</td>*/}
                      <td
                        className={style.chattd}
                        onClick={() => navigate(`/chat/${row.comid}`)}
                      >
                        Chat
                      </td>
                      <td
                        className={style.chattd}
                        onClick={() =>
                          navigate(`/customerdetails/${row.comid}`)
                        }
                      >
                        Details
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );

}

export default AgentDetails;