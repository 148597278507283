import { initializeApp } from "firebase/app";
import { collection, doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import {
    getDownloadURL,
    getStorage,
    ref,
    uploadBytesResumable,
} from "firebase/storage";



const firebaseConfig = {

    apiKey: "AIzaSyDESCOInR4uOQJxMVnqDvX19SVOI7SBI78",

    authDomain: "whatsapp-46bee.firebaseapp.com",

    projectId: "whatsapp-46bee",

    storageBucket: "whatsapp-46bee.appspot.com",

    messagingSenderId: "890469867275",

    appId: "1:890469867275:web:d9123e1f429a56c2e00c0e",

    measurementId: "G-M7QKZ8Z9FV"

};
// firebaseConfig is required

initializeApp(firebaseConfig)
const app = initializeApp(firebaseConfig)
const messaging = getMessaging();
const storage = getStorage(app);

export const requestToken = async (id) => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: process.env.VAPID_KEY })

        const mod = await getAdminOrAgent(id);

        if (mod.type === "admin") {
            await setDoc(doc(Admin, id), { ...mod.data, fcm_token: currentToken })
        }
        if (mod.type === "agent") {
            await setDoc(doc(Agent, id), { ...mod.data, fcm_token: currentToken })
        }
        console.log("token", currentToken)
        return currentToken;
    } catch (error) {
        return new Error("Error getting token")
    }
}

export const onMessageListener = () => {
    return new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })
}

const db = getFirestore()

const Customer = collection(db, "Customers")
const Admin = collection(db, "Admins")
const Agent = collection(db, "Agents")

const getAdminOrAgent = async (id) => {
    const admin = await getDoc(doc(Admin, id)).then(res => res.data())
    if (admin) return {
        type: "admin",
        data: admin
    }
    const agent = await getDoc(doc(Agent, id)).then(res => res.data())
    if (agent) return {
        type: "agent",
        data: agent
    }
    return new Error("No Admin or Agent found")
}

const uploadMedia = async (media, path) => {
    if (media !== "") {
        try {
            const upload = await uploadBytesResumable(ref(storage, `${path}/${media.name}`), media);
            console.log(upload)
            const getMedia = await ref(storage, `${path}/${media.name}`);
            console.log(getMedia)
            const mediaLink = await getDownloadURL(getMedia);
            console.log(mediaLink)
            return mediaLink;
        } catch (err) {
            console.log("Err: ", err);
            return false;
        }
    }
};
export { Customer, Admin, Agent, getAdminOrAgent, uploadMedia, db }