import React, { useState, useEffect } from "react";
import "./index.css";
import { MdOutlineBackup } from "react-icons/md";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import UploadIcon from "@mui/icons-material/Upload";
import { GrDocumentPdf } from "react-icons/gr";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Pdfpriview from "./Pdfpriview";
import Navbar from "../../Navbar/Navbar";
import Drawer from "../../Drawer/Drawer";
import pdf from "../../../Assets/pdfpng.png";
import backendUrl from "../../../config";
import { Link, NavLink, useNavigate } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#2fe825",
  },
}));

const Dropbox = () => {
  const [file, setFile] = useState();
  const [isUploaded, setIsUPloaded] = useState(false);
  const [checkUpload, setcheckUpload] = useState(false);
  const [showPriview, setShowPriview] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [companyData, setcompanyData] = useState();
  const [uploadComplete, setUploadComplete] = useState(false);
  const navigate=useNavigate()

  const apiCalls = async () => {
    try {
      const response = await fetch(
        `${backendUrl}/company/storeData/${localStorage.getItem("token1")}`
      );
      const data  = await response.json();

      if (response) {
        console.log("data", data);
        setcompanyData(data);
      } else {
        console.error("Fetching failed");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor:
        uploadProgress === 100
          ? "#2fe825"
          : "#1a90ff" /*theme.palette.mode === "light"  ? "#1a90ff" : "#2fe825",*/,
    },
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/company/${localStorage.getItem("token1")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response", response);

        if (response.ok) {
          const responseData = await response.json();
          console.log("responseData", responseData);
          setcompanyData(responseData.data);
        } else {
          console.error("Did not receive the  company info ");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
      setUploadProgress(0);
      setIsUPloaded(true);

      try {
        const formdata = new FormData();
        formdata.append("file", selectedFile);
        formdata.append("adminId", localStorage.getItem("token"));
        formdata.append("companyId", localStorage.getItem("token1"));

        const res = await fetch(`${backendUrl}/company/companyDocs`, {
          method: "POST",
          body: formdata,
        });
        console.log("response", res);
        const customers = await res.json();
        if (customers) {
          setcheckUpload(true);
          setUploadProgress(80);
        }
        console.log(customers);
      } catch (error) {
        console.log(error);
      }

      const interval = setInterval(() => {
        setUploadProgress((prevProgress) =>
          prevProgress < 80 ? prevProgress + 10 : prevProgress
        );
      }, 500);

      setTimeout(() => {
        clearInterval(interval);
        setUploadProgress(100);
        setUploadComplete(true);
        console.log("hi", uploadProgress);
        // apiCalls();
      }, 2000);

    }
  };

  const handlePreviewClick = () => {
    setShowPriview(true);
    console.log(file);
  };
  const handleNextClick=async()=>{
    
    try {
      let newSteps=2
      let passData={
        steps:newSteps
      }

      const response = await fetch(
        `${backendUrl}/admin/update/${localStorage.getItem("token")}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body:JSON.stringify(passData)
        }
      );
      console.log("response", response);

      if (response.ok) {
      localStorage.setItem("steps",newSteps)
       navigate("/companyprofile")
      } else {
        console.error("Did not receive the  company info ");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }


  }

  return (
    <>
      <Drawer />
      <div className="profileOuter" style={{ height: "10vh" }}>
        <div className="detOuter">
          <div className="agent-link-bar">
            <NavLink to="/companyprofile" style={{ textDecoration: "none" }}>
              <span>Agent Details</span>
            </NavLink>
            <NavLink to="/document" style={{ textDecoration: "none" }}>
              <span>Agent Training</span>
            </NavLink>
            <NavLink to="/aiagentdetails" style={{ textDecoration: "none" }}>
              <span>FAQs & Services</span>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="boxOuter">
        <div className="box-container">
          <h1 className="upload">Upload Your Company Documents</h1>
          <p className="lorem">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores
            iure culpa reiciendis, totam <br />
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.Maiores
            iure culpa reiciendis, totam
          </p>
          <div className="drop-box">
            <MdOutlineBackup className="drop-logo" />
            <p className="drag">Drag & drop your file here</p>
            <h3 className="od">OR</h3>
            <Button
              component="label"
              className="Browser-btn"
              variant="contained"
              startIcon={<UploadIcon />}
            >
              Browser
              <VisuallyHiddenInput
                type="file"
                accept=".pdf, .doc, .docx"
                name="file"
                onChange={handleFileChange}
              />
            </Button>
            <p className="p1">
              Max File size:<b>50MB</b>
            </p>
            <p className="p2">
              Supported file formats:<b>PDF, DOC</b>
            </p>
          </div>
          {isUploaded && (
            <div>
              <div className="pdf-box">
                <GrDocumentPdf className="pdf-icons" />
                <Box /* className={`loder ${uploadProgress === 100 && uploadComplete ? "green-progress" : ""}`}*/
                >
                  <BorderLinearProgress
                    variant="determinate"
                    value={uploadProgress}
                    style={{
                      backgroundColor:
                        uploadProgress === 100 ? "#2fe825" : undefined,
                      width: "20rem",
                    }}
                  />

                  {uploadProgress === 100 && (
                    <div onClick={handlePreviewClick}>Preview</div>
                  )}
                </Box>
                {/*uploadProgress > 0 && (
                <div>
                  <progress max="100" value={uploadProgress}></progress>
                </div>
            )*/}
              </div>
            </div>
          )}
          {companyData?.companyDocs && (
            <div className="pbottom">
              <div className="pbotheading">Document Uploaded</div>
              <div className="pfiles">
                {companyData?.companyDocs?.map((item) => (
                  <div className="psinglefile">
                    <img src={pdf} alt="img" />
                    {item.fileName}
                  </div>
                ))}
                {/*<div className={style.psinglefile}><img src={pdf} alt='img' />File1</div>
          <div className={style.psinglefile}>File2</div>
          <div className={style.psinglefile}>File3</div>*/}
              </div>
            </div>
          )}
          {/*<Stack spacing={2} direction="row">
            <Button
              variant="contained"
              style={{
                borderRadius: "20px",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              Next
            </Button>
          </Stack>*/}
          {showPriview && (
            <Pdfpriview file={file} setShowPriview={setShowPriview} />
          )}
          {localStorage.getItem("isFirstTimeUser") === "true" && checkUpload && (
            <div
              style={{
                width: "100%",
                // height: "100px",
                textAlign: "right",
                margin: "20px",
                marginRight: "30px",
              }}
            >
              <div>
                <button
                  className="btn btn-primary"
                  style={{ width: "60px" }}
                  onClick={() =>handleNextClick()}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dropbox;
