import React, { useEffect, useState } from "react";
import Drawer from "../../Drawer/Drawer";
import { NavLink } from "react-router-dom";
import "./RequiredDocuments.css";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import backendUrl from "../../../config";
import toast from "react-hot-toast";

const RequiredDocuments = () => {
  const [inputValue, setInputValue] = useState("");
  const [tasks, setTasks] = useState();
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    // Fetch tasks when the component mounts
    fetchTasks();
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddTask = async () => {
    if (inputValue.trim() !== "") {
      if (editingIndex !== null) {
        // If editing, update the existing task
        await updateTask();
      } else {
        // If not editing, add a new task
        await addTask();
      }
      setInputValue("");
      setEditingIndex(null);
      fetchTasks(); // Fetch updated tasks after adding/updating
    }
  };

  const handleEditTask = (index) => {
    setInputValue(tasks[index]);
    setEditingIndex(index);
  };

  const handleDeleteTask = async (index) => {
    await deleteTask(tasks[index]);
    fetchTasks(); // Fetch updated tasks after deleting
  };

  const fetchTasks = async () => {
    try {
      const response = await fetch(
        `${backendUrl}/company/${localStorage.getItem("token1")}`
      );
      const { data } = await response.json();
      setTasks(data.userDocs);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const addTask = async () => {
    console.log("inside add");
    try {
      const response = await fetch(
        `${backendUrl}/company/addcompanydoc/${localStorage.getItem("token1")}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ docName: inputValue }),
        }
      );
      console.log(response);
    } catch (error) {
      console.error("Error adding task:", error);
    }
  };

  const updateTask = async () => {
    try {
      const response = await fetch(
        `${backendUrl}/company/updatecompanydoc/${localStorage.getItem(
          "token1"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ docName: inputValue }),
        }
      );
      console.log(response);
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  const deleteTask = async (docName) => {
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      try {
        await fetch(
          `${backendUrl}/company/deletecompanydoc/${localStorage.getItem(
            "token1"
          )}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ docName: docName }),
          }
        );
      } catch (error) {
        console.error("Error deleting task:", error);
      }
    } else {
      toast.error("deletion cancelled");
    }
  };

  return (
    <>
      <Drawer />
      <div className="profileOuter">
        <div className="lead-container">
          {localStorage.getItem("isFirstTimeUser") === "false" && (
            <div className="lead-link-bar">
              <NavLink to="/leadConversion" style={{ textDecoration: "none" }}>
                <span>Lead Conversion</span>
              </NavLink>
              <NavLink
                to="/required-document"
                style={{ textDecoration: "none" }}
              >
                <span>Required Documents</span>
              </NavLink>
              <NavLink to="/banks" style={{ textDecoration: "none" }}>
                <span>Add Banks</span>
              </NavLink>
            </div>
          )}

          <h1>Required Documents</h1>

          <div className="RequiredDocumentContainer">
            {tasks?.map((task, index) => (
              <div key={index}>
                {task}

                <div>
                  <MdEdit
                    style={{
                      fontSize: "30px",
                      color: "green",
                      cursor: "pointer",
                      margin: "10px",
                    }}
                    onClick={() => handleEditTask(index)}
                  />
                  <MdDelete
                    style={{
                      cursor: "pointer",
                      margin: "10px",
                      fontSize: "30px",
                      color: "red",
                    }}
                    onClick={() => handleDeleteTask(index)}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="lead-btn-container">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Name of the document"
            />
            <button onClick={handleAddTask}>
              {editingIndex !== null ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

{
  /* 
const RequiredDocuments = () => {
  const [inputValue, setInputValue] = useState("");
  const [tasks, setTasks] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddTask = () => {
    if (inputValue.trim() !== "") {
      if (editingIndex !== null) {
        const updatedTasks = [...tasks];
        updatedTasks[editingIndex] = inputValue;
        setTasks(updatedTasks);
        setEditingIndex(null);
      } else {
        // If not editing, add a new task
        setTasks([...tasks, inputValue]);
      }
      setInputValue("");
    }
  };

  const handleEditTask = (index) => {
    setInputValue(tasks[index]);
    setEditingIndex(index);
  };

  const handleDeleteTask = (index) => {
    const updatedTasks = [...tasks];
    updatedTasks.splice(index, 1);
    setTasks(updatedTasks);
    setEditingIndex(null);
  };
  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Enter a task"
      />
      <button onClick={handleAddTask}>
        {editingIndex !== null ? "Update" : "Add"}
      </button>

      <ul>
        {tasks.map((task, index) => (
          <li key={index}>
            {task}
            <button onClick={() => handleEditTask(index)}>Edit</button>
            <button onClick={() => handleDeleteTask(index)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
}; */
}

export default RequiredDocuments;
