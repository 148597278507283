import React, { useEffect } from "react";
import Drawer from "../Drawer/Drawer";
import { Link, useNavigate } from "react-router-dom";
import Img from "./../../Assets/startOnboarding.png";
import "./StartOnboarding.css";
import { InputGroup } from "react-bootstrap";
const StartOnboarding = () => {
  const navigate=useNavigate()
  // 1->company document
  // 2-> bot info
  // 3->customer info
  // 4-> leads 
  // 5-> add agent 
  // 6-> template


  
    const onboardingCall=()=>{
      let newsteps=localStorage.getItem("steps")
      console.log(newsteps)
      if(newsteps==="1"){
        navigate('/document')
        }
      
      else if(newsteps=="2"){
        
        navigate("/companyprofile")
      }
      else if(newsteps==="3"){
        
        navigate("/customer/bulk")
        
      }
      else if(newsteps==="4"){
        
        navigate("/leadConversion")
      }
      else if(newsteps==="5"){
        
        navigate("/agent/add")
      }
      else if(newsteps==="6"){
        
        
        navigate("/manualtemplate")
      }
      else{
        navigate("/dashboard")

      }
      
      
     
    }
    
  return (
    <div>
      <div>
        <Drawer />
      </div>

      <div className="primary-container">
        <div className="onboarding-container-border">
          <div className="start-onboarding-container">
            <img
              src={Img}
              alt="Heeeeeeeeeeeello"
              width={"400px"}
              height={"400px"}
            />
            <p>
              <b>
                Fill up the following details to start the onboarding process
                for your first WhatsApp Campaign.
              </b>
            </p>
            <div
            
              
              className="btn btn-primary StartOnboarding-btn"
            >
              <span onClick={()=>onboardingCall()} >Start Onboarding</span>
            </div>
          </div>
          <Link to={"/dashboard"} className="onboarding-skip-button" onClick={()=>localStorage.setItem("isFirstTimeUser",false)}>
            <span >Skip</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StartOnboarding;
