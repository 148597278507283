import React, { useState, useEffect } from "react";
import "./index.css";
import backendUrl from "../../../config";
import Drawer from "../../Drawer/Drawer";
import toast from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import delete1 from '../../../Assets/delete1.png'
import edit1 from '../../../Assets/edit1.png'

const CaptureLeadComponent = () => {
  const navigate = useNavigate();
  const [tableEntries, setTableEntries] = useState([]);
  const [check, setCheck] = useState(false)



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/company/${localStorage.getItem("token1")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response", response);

        if (response.ok) {
          const responseData = await response.json();
          console.log("responseData", responseData);
          setTableEntries(responseData.data.leadConversion);
        } else {
          console.error("Did not receive the  company info ");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);




  const [formData, setFormData] = useState({
    fieldName: "",
    fieldDatatype: "",
    required: "",
  });
  const dataTypes = [
    "String",
    "Number",
    "Boolean",
    "Object",
    "Array",
    // Add more data types as needed
  ];

  const [checkUpload, setcheckUpload] = useState(false)
  const requiredTypes = ["True", "false"]

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let obj = {
      fieldName: "name",
      fieldDatatype: "String",
      required: "True",
    }
    console.log("formData", formData);
    const passData = {
      companyId: localStorage.getItem("token1"),
      adminId: localStorage.getItem("token"),
      leadConversion:formData,
    };
    console.log(passData);
    try {
      const response = await fetch(`${backendUrl}/company/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passData),
      });
      console.log("response", response);
      const responseData = await response.json();
      console.log("responseData", responseData);

      if (response.ok) {
        setTableEntries((prevEntries) => [...prevEntries, formData]);
        toast.success(responseData.message);
        setcheckUpload(true)
        //navigate('/companyprofile')
      } else {
        toast.error(responseData.message);
        console.error("Did not receive the  profile info ");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }
    setFormData({ fieldName: "", fieldDatatype: "", required: "" });
  };

  const handleEdit=(item)=>{
    setFormData({
      fieldName: item.fieldName,
      fieldDatatype: item.fieldDatatype,
      required: item.required,
    })
  }
  console.log("check",check)
  const handleDelete=async(item)=>{

    let parArr=tableEntries.filter(item1=>item1!==item)
    const passData = {
      companyId: localStorage.getItem("token1"),
      adminId: localStorage.getItem("token"),
      leadConversion:parArr
    };
    console.log(passData);
    try {
      const response = await fetch(`${backendUrl}/company/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passData),
      });
      console.log("response", response);
      const responseData = await response.json();
      console.log("responseData", responseData);

      if (response.ok) {
        setTableEntries(parArr)

    
        toast.success(responseData.message);
        setcheckUpload(true)
        //navigate('/companyprofile')
      } else {
        toast.error(responseData.message);
        console.error("Did not receive the  profile info ");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }

  }

  const handleNextClick=async()=>{
    
    try {
      let newSteps=5;

      const response = await fetch(
        `${backendUrl}/admin/update/${localStorage.getItem("token")}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body:JSON.stringify({steps:newSteps})
        }
      );
      console.log("response", response);

      if (response.ok) {
      localStorage.setItem("steps",newSteps)
       navigate("/agent/add")
      } else {
        console.error("Did not receive the  company info ");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }


  }

  return (
    <>
      <Drawer />
      <div className="profileOuter">
        <div className="lead-container">
        
            <div className="lead-link-bar">
              <NavLink to="/leadConversion" style={{ textDecoration: "none" }}>
                <span>Lead Conversion</span>
              </NavLink>
              <NavLink
                to="/required-document"
                style={{ textDecoration: "none" }}
              >
                <span>Required Documents</span>
              </NavLink>
              <NavLink to="/banks" style={{ textDecoration: "none" }}>
                <span>Add Banks</span>
              </NavLink>
            </div>
          <h1>Lead Conversion</h1>
          <p>Efficiently Manage and Convert your Leads into Customers</p>

          <div className="lead-content">
            <form onSubmit={handleSubmit}>
              <div style={{display:"flex"}}>
                <div class="lead-form-group">
                  <label className="lead-label">Field Name</label>
                  <input
                    type="text"
                    className="lead-input"
                    name="fieldName"
                    value={formData.fieldName}
                    onChange={handleInputChange}
                  />
                </div>
                <div class="lead-form-group">
                  <label className="lead-label">Field Datatype</label>
                  <select
                  style={{width:"200px"}}
                    id="dataType"
                    name="fieldDatatype"
                    onChange={handleInputChange}
                    value={formData.fieldDatatype}
                  >
                    <option value="" disabled>
                      Select a data type
                    </option>
                    {dataTypes.map((dataType) => (
                      <option key={dataType} value={dataType}>
                        {dataType}
                      </option>
                    ))}
                  </select>
                </div>
                <div class="lead-form-group">
                  <label className="lead-label">Required</label>
                  <select
                  style={{width:"200px"}}
                    name="required"
                    value={formData.required}
                    onChange={handleInputChange}
                  >
                    <option >
                      Select
                    </option>
                    {requiredTypes.map((requiredType) => (
                      <option key={requiredType} value={requiredType}>
                        {requiredType}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <button className="lead-btn" type="submit">
                Add Field
              </button>
              {/* <button
                className="lead-btn"
                onClick={() => navigate("/leadsdisplay")}
              >
                Display
              </button> */}
            </form>
            <table>
              <thead>
                <tr>
                  <td>Field Name</td>
                  <td>Field Datatype</td>
                  <td>Required</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {tableEntries.map((row, index) => (
                  <tr key={index}>
                    <td>{row.fieldName}</td>
                    <td>{row.fieldDatatype}</td>
                    <td>{row.required}</td>
                    <td>{(row.fieldName.toLowerCase() !== "name"&& row.fieldName.toLowerCase() !== "phone")  && <img src={edit1} alt="img" onClick={()=>handleEdit(row)}  />}
                    {(row.fieldName.toLowerCase() !== "name"&& row.fieldName.toLowerCase() !== "phone")  &&   <img src={delete1} alt="img" onClick={()=>handleDelete(row)}  />}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {localStorage.getItem("isFirstTimeUser") === "true" && localStorage.getItem("steps")==="4" && checkUpload && (
            <div style={{ width: "100%", textAlign: "right" }}>
              <div>
                <button
                  className="btn btn-primary"
                  style={{ width: "60px" }}
                  onClick={() =>handleNextClick()}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CaptureLeadComponent;
