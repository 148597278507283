import React, { useEffect, useState } from "react"
import style from '../CustomerManagement/CustomerDashboard/CustomerDashboard.module.css'
import delete1 from '../../Assets/delete1.png'
import edit1 from '../../Assets/edit1.png'
import whatsapp1 from '../../Assets/whatsapp1png.png'
import headingImage from '../../Assets/headingimage.png'
import Drawer from '../Drawer/Drawer'
import { useNavigate, useParams } from "react-router-dom"
import oopsimage from '../../Assets/oops.png'
import backendUrl from "../../config"




const DeleteModal = ({ closePopup, data, customers, name }) => {

    const { id } = useParams()
    console.log(" customers", customers)
    console.log("data", data)
    const handleDeleteCustomer = async () => {
        const newCustomers = customers.filter((item) => item !== data.comid)
        console.log("newCustomers", newCustomers)
        const passData = {
            groupName: name,
            customers: newCustomers
        }
        console.log(passData)
        try {
            const response = await fetch(`${backendUrl}/group/${id}`, {
                method: "PUT",
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.ok) {
                window.location.reload()
            } else {
                console.log("Delete Failed")
            }

        }
        catch (error) {
            console.log("Error during deleting Customer ", error)
        }
    }



    return (
        <div className={style.modalOuter}>
            <div className={style.modalname}>{data.name}</div>
            <div className={style.modaltext}>Are you sure you want to delete this customer?</div>
            <div className={style.modalbuttons}>
                <div className={style.modalcancel} onClick={closePopup}>Cancel</div>
                <div className={style.modaldelete} onClick={handleDeleteCustomer}>Delete</div>
            </div>
        </div>
    )
}





const SingleGroup = () => {
    const { id } = useParams()

    const [datalist, setDatalist] = useState({})
    const [name, setName] = useState('')
    const [allCustomers, setAllCustomers] = useState()
    const [selecteditem, setSelecteditem] = useState()
    const [filteredUsers,setFilteredUsers]=useState()
    const navigate = useNavigate()
    const closePopup = () => {
        setSelecteditem()
    }


    const fetchData1 = async () => {
        try {
            const response = await fetch(
                `${backendUrl}/customer/all/${localStorage.getItem(
                    "token1"
                )}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            const responseData = await response.json();
            console.log("customer response data", responseData);


            if (response.ok) {
                const cust=responseData.customers
                setAllCustomers(cust)
                return cust
                

            } else {
                console.error('Fetching failed');
            }
        } catch (error) {
            console.error('Error during Fetching:', error);
        }

    }


    const fetchData2 = async () => {
        try {
            const response = await fetch(`${backendUrl}/group/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const responseData = await response.json();
            console.log("singlegroupdata", responseData);

            if (response.ok) {
                setFields(responseData)
                return responseData.customers
                



            } else {
                console.error('Fetching failed');
            }
        } catch (error) {
            console.error('Error during Fetching:', error);
        }

    }






    useEffect(() => {
    const fetchData = async () => {
        try {
            // Fetch data from both sources sequentially
            let temp1=await fetchData1()
            let temp2=await fetchData2()

            // After both fetches complete, set the dat
            setFilter(temp1,temp2);
        } catch (error) {
            console.error('Error during Fetching:', error);
            console.error('Error fetching data:', error);
            // Handle errors here if needed
        }
    };

    fetchData();
}, []);






    
    const setFields = (data) => {
        setName(data.groupName)
        setDatalist(data.customers)
    }

    const handleDelete = (item) => {
        setSelecteditem(item)
    }

    console.log("datalist", datalist)
    const setFilter = (arr1,arr2) => {


        let filteredUsers1 = arr1?.filter(user => arr2?.includes(user.comid));
        setFilteredUsers(filteredUsers1)

        }




    return (
        <>
            {selecteditem && (
                <div className={style.popup}>
                    <div className={style.popup_inner}>
                        <DeleteModal closePopup={closePopup} data={selecteditem} customers={datalist} name={name} />
                    </div>
                </div>
            )}
            <Drawer />

            <div className={style.cdMainOuter}>
                <div className={style.cdOuter}>
                    <div className={style.cdupper}>
                        <div className={style.cdHeading}>
                            <div className={style.cdheadingwrapper}>
                                <div className={style.cdheading1}>
                                    <img className={style.cdimg} src={headingImage} alt="img" />
                                    {name}</div>

                            </div>

                        </div>

                    </div>
                    <div className={style.cdbottom}>

                        { filteredUsers?.map((item) => (
                            <div className={style.cdone} key={item.id} >
                                <div className={style.cdname} onClick={() => navigate(`/customerdetails/${item.phone}`)}>{item.name}</div>
                                <div className={style.cdcontent} >
                                    <div className={style.cdinfoOuter} onClick={() => navigate(`/customerdetails/${item.phone}`)}>
                                        <div className={style.cdinfo}>
                                            <div className={style.cdsmallheading}>Email</div>
                                            <div className={style.cdsmalldata}>{item.email}</div>
                                        </div>
                                        <div className={style.cdinfo}>
                                            <div className={style.cdsmallheading}>Phone Number :</div>
                                            <div className={style.cdsmalldata}>{item.phone}</div>
                                        </div>
                                    </div>
                                    <div className={style.cdicons} style={{ cursor: "pointer" }}>
                                        {/* <img src={whatsapp1} alt="img" /> */}
                                        <img src={edit1} alt="img" onClick={() => navigate(`/customer/update/${item.phone}`)} />
                                        <img src={delete1} alt="img" onClick={() => handleDelete(item)} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </>
    )

}

export default SingleGroup;