import React, { useState, useEffect } from "react";
import "./AiBot.css";
import SendIcon from "@mui/icons-material/Send";

import backendUrl from "../../../config";
import toast from "react-hot-toast";

const AiBot = () => {
  const [inputValue, setInputValue] = useState("");
  const [botResponse, setBotResponse] = useState("");
  const [additionalResponse, setAdditionalResponse] = useState("");
  const [showTemplate, setShowTemplate] = useState(false);
  const [currentbotresponse, setcurrentbotresponse] = useState({});

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    const id = localStorage.getItem("token");
    // message,action,adminId
    const passData = {
      message: inputValue,
      action: "",
      adminId: id,
    };
    console.log(passData);
    try {
      const response = await fetch(`${backendUrl}/template/chatbot`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors", // Add this line
        body: JSON.stringify(passData),
      });
      if (response.ok) {
        setShowTemplate(true);
        response.json().then((res) => {
          setcurrentbotresponse(res);
          console.log(res);
        });
      } else {
        console.log("Error response false");
      }
    } catch (error) {
      console.log("Error creating template", error);
    }

    // You can perform further actions or API calls here based on the input

    // Clear the input field
    setInputValue("");
  };

  const handleSave = async () => {
    const passData = {
      name: currentbotresponse.template_name,
      description: currentbotresponse.description,
      content: currentbotresponse.content,
      adminId: localStorage.getItem("token"),
      aiResponse: true,
    };
    console.log(passData);
    try {
      const response = await fetch("http://localhost:4000/template", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors", // Add this line
        body: JSON.stringify(passData),
      });
      console.log("response", response);
      if (response.ok) {
        const responseData = await response.json();
        console.log("responseData", responseData);
        toast.success(responseData.message);
        window.location.reload();
      } else {
        console.log("Error response false");
      }
    } catch (error) {
      console.log("Error creating template", error);
    }
  };

  const handleUpdate = () => {
    // You can add logic here to update additionalResponse based on user input or other conditions
    setAdditionalResponse("Updated response: " + botResponse);
  };
  const handleCancel = () => {
    setcurrentbotresponse();
  };

  /* useEffect(() => {
    // Set a timeout to show the template after 1 minute when additionalResponse is updated
    const templateTimeout = setTimeout(() => {
      setShowTemplate(true);
    }, 17000); // 17 second in milliseconds

    return () => clearTimeout(templateTimeout); // Clear the timeout on component unmount
  }, [additionalResponse]);*/

  return (
    <div className="AiBot-container">
      <div className="top-text">
        <p>Describe your template idea.</p>
      </div>
      {botResponse && !showTemplate && (
        <div>
          <div className="bot-response">
            <p className="response">Hello mohan, {botResponse}</p>
            <button className="save-btn" onClick={handleSave}>
              Save
            </button>
            <button className="save-btn" onClick={handleUpdate}>
              Update
            </button>
          </div>
          <div className="additional-response">
            <p>Generating template... please wait...! </p>
          </div>
        </div>
      )}
      {showTemplate && currentbotresponse && (
        <div className="template-main">
          <div className="template1">
            {/* Your template content here */}
            <div className="templateinner-cont">
              <div className="template-messageOuter">
                <div className="message-cont">
                  {currentbotresponse.description},<br></br>
                  {currentbotresponse?.content && currentbotresponse?.content}
                </div>
                <div className="template-buttons">
                  <div className="contactuse">Contact Us</div>
                  <div className="track">Track Package</div>
                </div>
              </div>
            </div>
            <div className="template-text">
              {currentbotresponse.template_name}
            </div>
          </div>
          <button className="save-btn" onClick={handleSave}>
            Save
          </button>
          <button className="save-btn" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      )}
      <div className="footer">
        <input
          className="bot-input1"
          type="text"
          placeholder="Send a message...!"
          value={inputValue}
          onChange={handleInputChange}
        />
        <span onClick={handleSendMessage}>
          <SendIcon />
        </span>
      </div>
    </div>
  );
};

export default AiBot;
