import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Drawer from '../Drawer/Drawer';

import  backendUrl  from "../../config";

const AgentLogin = () => {
    const navigate=useNavigate()

    const handleclick = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("token1")
        navigate('/')
    }

    return (
        <>
        <Drawer/>
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"15px",flexDirection:"column",margin:"auto"}}>
        <div>hello</div>
        <div onClick={()=>handleclick()}>logout</div>
        <div onClick={()=>navigate(`/leadconversion`)}>Lead Conversion</div>
        
        </div>
        </>
    )


}

export default AgentLogin;