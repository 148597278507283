import { useState, useEffect } from "react";
import style from "../../CustomerManagement/EditGroup.module.css";
import { useNavigate } from "react-router-dom";
import backendUrl from "../../../config";

const SelectUsers = () => {
  const [datalist, setDatalist] = useState({});
  const navigate = useNavigate();

  console.log("Datalist--------------", datalist);
  const [newCustomers, setNewCustomer] = useState([]);
  console.log("newCustomers", newCustomers);

  useEffect(() => {
    if (localStorage.getItem("campaigncustomers")) {
      console.log(localStorage.getItem("campaigncustomers"));
      const storedJsonString = localStorage.getItem("campaigncustomers");
      const storedArray = JSON.parse(storedJsonString);
      console.log("local storage customers", storedArray);
      setNewCustomer(storedArray);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/customer/all/${localStorage.getItem("token1")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log("customer response data", responseData.customers);

        if (response.ok) {
          setDatalist(responseData.customers);
        } else {
          console.error("Fetching failed");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);

  const handleAddMore = async () => {
    const jsonString = JSON.stringify(newCustomers);

    localStorage.setItem("campaigncustomers", jsonString);
    navigate("/manualtemplate");
  };
  const handleCheckbox = (value) => {
    if (newCustomers.includes(value)) {
      // If the customer is already selected, remove it from the list
      setNewCustomer(newCustomers.filter((item) => item !== value));
    } else {
      // If the customer is not selected, add it to the list
      setNewCustomer([...newCustomers, value]);
    }
  };

  // ...

  return (
    <div className={style.popup}>
      <div className={style.popup_inner}>
        <div className={style.modalOuter}>
          {Object.values(datalist) .map((item) => (
            <div className={style.egone} key={item.id}>
              <input
                type="checkbox"
                checked={newCustomers.includes(item.phone)} // Use 'checked' instead of 'value'
                onChange={() => handleCheckbox(item.phone)} // Use 'onChange' instead of 'onClick'
              />
              <div className={style.egcontent}>
                <div className={style.egname}>{item.name}</div>
                <div className={style.eginfo}>
                  <div className={style.egsmallheading}>Phone Number :</div>
                  <div className={style.egsmalldata}>{item.phone}</div>
                </div>
              </div>
            </div>
          ))}
          <div
            className={style.modalcancel}
            onClick={() => navigate("/createcampaign")}
          >
            Cancel
          </div>
          <div className={style.modaldelete} onClick={handleAddMore}>
            Add
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelectUsers;
