import React, { useEffect, useState } from "react";
import style from "../../CustomerManagement/AddCustomer/AddCustomer.module.css";
import arrow from "../../../Assets/arrow.png";
import Drawer from "../../Drawer/Drawer";
import { useNavigate, useParams } from "react-router-dom";
import pdf from "../../../Assets/pdfpng.png";
import editpng from "../../../Assets/editpng.png";
import backendUrl from "../../../config";
console.log(backendUrl)

const Profile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [num, setNum] = useState("");
  const [companyName, setcompanyName] = useState();
  const [webhook, setWebhook] = useState();
  const [bussinessId,setBussinessId]=useState()
  const navigate = useNavigate();
  const id = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendUrl}/admin/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("response", response);
        const responseData = await response.json();
        console.log("responseData", responseData);

        if (response.ok) {
          setFields(responseData);
        } else {
          window.alert(responseData.message);
          console.error("Did not receive the  profile info ");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);
  const setFields = (data) => {
    setName(data.name);
    setEmail(data.email);
    setNum(data.whatsappNumber);
    setWebhook(data.webhookSecret);
    setcompanyName(data.companyName);
    setBussinessId(data.company);
  };

  return (
    <>
      <Drawer />
      <div className={style.profileOuter}>
        <div className={style.addOuter}>
          <div
            className={style.addHeading}
            style={{ width: "68rem", justifyContent: "space-between" }}
          >
            <div
              className={style.profilewrapper}
              onClick={() => navigate("/dashboard")}
            >
              <img style={{ width: "28.5%" }} src={arrow} alt="img" />
              <div className={style.addHeadingtext}>Profile</div>
            </div>
            <div className={style.pbuttonwrapper}>
              <div
                className={style.addaddbtn}
                style={{ gap: "6px" }}
                onClick={() => navigate("/editprofile")}
              >
                <img
                  style={{ width: "9.5%", filter: "invert(100%)" }}
                  src={editpng}
                  alt="img"
                />
                Edit Details
              </div>
              <div
                className={style.addaddbtn}
                onClick={() => navigate("/profile/updatepassword")}
              >
                Update Password
              </div>
            </div>
          </div>
          <div className={style.addFields}>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Admin Name</label>
              <input
                className={style.addinput}
                name="name"
                value={name}
                type="text"
                disabled
              />
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Admin's email address</label>
              <input
                className={style.addinput}
                name="email"
                value={email}
                type="text"
                disabled
              />
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Phone Number</label>
              <div className={style.addphoneinput}>
                <div className={style.addnum}>+91</div>
                <input
                  className={style.addinput}
                  name="num"
                  value={num}
                  style={{ width: "55%" }}
                  type="text"
                  disabled
                />
              </div>
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Webhooksecret</label>
              <input
                className={style.addinput}
                name="webhook"
                value={webhook}
                type="text"
                disabled
              />
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Company Name</label>
              <input
                className={style.addinput}
                name="companyname"
                value={companyName}
                type="text"
                disabled
              />
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Businness Id</label>
              <input
                className={style.addinput}
                name="bussinessId"
                value={bussinessId||" "}
                type="text"
                disabled
              />
            </div>
          </div>
          {/*<div className={style.pbottom}>
                    <div className={style.pbotheading}>Document Uploaded</div>
                    <div className={style.pfiles}>
                        <div className={style.psinglefile}><img src={pdf} alt='img'/>File1</div>
                        <div className={style.psinglefile}>File2</div>
                        <div className={style.psinglefile}>File3</div>
                    </div>
                </div>*/}
        </div>
      </div>
    </>
  );
};

export default Profile;