import "./ChatBox.css";
import { IoPencilSharp } from "react-icons/io5";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { IoSend } from "react-icons/io5";
import { useRef } from "react";
import Drawer from "../Drawer/Drawer";
import ToggleImg from "./../../Assets/Toggle.png";
import GirlImg from "./../../Assets/Vector.png";
import Chatleft from "./Chatleft";
import style from "./Chatleft.module.css";
import search from "../../Assets/search.png";
import filter from "../../Assets/filter.png";
import settings from "../../Assets/settings.png";
import downarrow from "../../Assets/downarrow.png";
import React, { useState, useEffect } from "react";
import backendUrl from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import Calendar from "react-calendar";
import toast from "react-hot-toast";
import deleteimage from "../../Assets/delete1.png";
import editimage from "../../Assets/edit1.png";
import {
  collection,
  onSnapshot,
  query,
  where,
  getFirestore,
  getDocs,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { MdOutlineFileUpload } from "react-icons/md";
const label = { inputProps: { "aria-label": "Switch demo" } };

const firebaseConfig = {
  apiKey: "AIzaSyDESCOInR4uOQJxMVnqDvX19SVOI7SBI78",
  authDomain: "whatsapp-46bee.firebaseapp.com",
  projectId: "whatsapp-46bee",
  storageBucket: "whatsapp-46bee.appspot.com",
  messagingSenderId: "890469867275",
  appId: "1:890469867275:web:d9123e1f429a56c2e00c0e",
  measurementId: "G-M7QKZ8Z9FV",
};

initializeApp(firebaseConfig);
const db = getFirestore();

const SelectAgent = ({ closeModal, customer }) => {
  const [datalist, setDatalist] = useState({});
  const [selectedItem, setSelectedItem] = useState();
  const [flag, setFlag] = useState(true);
  const [preSelected, setPreselected] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const adminid = localStorage.getItem("token");
      try {
        const response = await fetch(`${backendUrl}/agent/all/${adminid}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const responseData = await response.json();
        console.log("agents list", responseData.agents);

        if (response.ok) {
          setDatalist(responseData.agents);
          Object.values(responseData.agents).forEach(ele => {
            console.log("ele", ele);
            if (ele.assignedCustomers?.includes(customer.comid)) {
              setSelectedItem(ele);
              setPreselected(ele);
              console.log("hi");
            }
          });
        } else {
          console.error("Fetching failed");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);
  const handleCheckbox = item => {
    // Update the selectedPhone state
    setSelectedItem(item);
  };
  console.log("selected agent", selectedItem);
  console.log("preselected agent", preSelected);
  const handleAdd = async () => {
    console.log("selected agent", selectedItem);

    const passData = {
      name: selectedItem.name,
      email: selectedItem.email,
      phoneNumber: selectedItem.phoneNumber,
      customer: customer.comid,
    };
    console.log("passData", passData);
    try {
      const response = await fetch(
        `${backendUrl}/agent/update/${selectedItem.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(passData),
        }
      );
      console.log("response", response);

      if (response.ok) {
        preSelected !== undefined && (await handleDeletePre());
        toast.success("Agent asigned");
        closeModal();
      } else {
        console.error("Agent not updated ");
      }
    } catch (error) {
      console.error("Error during updation:", error);
    }
  };
  console.log("customer", customer);

  const handleDeletePre = async () => {
    const arr1 = preSelected?.assignedCustomers?.filter(
      item => item !== customer.comid
    );

    const passData = {
      name: preSelected.name,
      email: preSelected.email,
      phoneNumber: preSelected.phoneNumber,
      assignedArr: arr1,
    };
    console.log("passData", passData);
    try {
      const response = await fetch(
        `${backendUrl}/agent/update/${preSelected.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(passData),
        }
      );
      console.log("response", response);

      if (response.ok) {
      } else {
        console.error("Agent not updated ");
      }
    } catch (error) {
      console.error("Error during updation:", error);
    }
  };

  return (
    <div className={style.popup}>
      <div className={style.popup_inner}>
        <div className={style.modalOuter}>
          {Object.values(datalist).map(item => (
            <div className={style.egone} key={item.id}>
              <input
                type="checkbox"
                checked={
                  selectedItem
                    ? selectedItem.phoneNumber === item.phoneNumber
                    : false
                }
                onChange={() => handleCheckbox(item)}
              />

              <div className={style.egcontent}>
                <div className={style.egname}>{item.name}</div>
                <div className={style.eginfo}>
                  <div className={style.egsmallheading}>Phone Number :</div>
                  <div className={style.egsmalldata}>{item.phoneNumber}</div>
                </div>
              </div>
            </div>
          ))}
          <div className={style.modalcancel} onClick={closeModal}>
            Cancel
          </div>
          <div className={style.modaldelete} onClick={handleAdd}>
            Add
          </div>
        </div>
      </div>
    </div>
  );
};

const ChatBox = () => {
  const { id } = useParams();
  const [category, setCategory] = useState("Individuals");
  const [datalist, setDatalist] = useState({});
  const [grouplist, setGrouplist] = useState({});
  const [selecteditem, setSelecteditem] = useState();
  const [agentClicked, setAgentclicked] = useState(false);
  const [textMessage, setTextmessage] = useState("");
  const [selectedData, setSelectedData] = useState();
  const [currMessages, setCurrMessages] = useState();
  const [summaryData, setsummaryData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState();
  const [tags, setTags] = useState({
    human: false,
    interested: false,
    notInterested: false,
  });
  const [showNotInterested, setShowNotInterested] = useState(false);
  const [showHuman, setShowHuman] = useState(false);
  const [showInterested, setShowInterested] = useState(false);

  const handleSearch = word => {
    const filteredResults = Object.values(datalist).filter(
      contact =>
        contact.name?.toLowerCase().includes(word.toLowerCase()) ||
        contact.phone?.includes(word)
    );
    setSearchResults(filteredResults);
  };
  const [txtf, settxtf] = useState(false);
  const navigate = useNavigate();
  const closeModal = () => {
    setAgentclicked(false);
  };
  console.log("selectedItem", selecteditem);

  useEffect(() => {
    const fetchdata = async () => {
      const customerRef = doc(collection(db, "Customers"), id);
      const customer = await getDoc(customerRef).then(doc => doc.data());
      console.log("dataa", customer);
      setSelecteditem(customer);
      setTags({
        human: tags?.human || customer?.human,
        interested: tags?.interested || customer?.interested,
        notInterested: tags?.notInterested || customer?.notInterested,
      });
    };
    id && fetchdata();
  }, []);

  console.log("messages", currMessages);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/whatsapp/getSummary/${selecteditem?.comid}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log("Summary response data", responseData);

        if (response.ok) {
          console.log("response is ok");
          setsummaryData(responseData.summaryData);
        } else {
          console.error("Fetching failed");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchdata();
  }, [selecteditem]);

  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem("token")) {
        try {
          const response = await fetch(
            `${backendUrl}/customer/all/${localStorage.getItem("token1")}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const responseData = await response.json();
          console.log("customer response data", responseData);

          if (response.ok) {
            const allCustomers = responseData.customers;

            var matchingCustomers;
            const querySnapshot = await getDocs(
              collection(db, "WhatsappMessages")
            );
            const documents = querySnapshot.docs.map(doc => doc.data());
            matchingCustomers = allCustomers
              .filter(customer => {
                return documents.some(
                  document => document.comid === customer.comid
                );
              })
              .map(customer => {
                const matchingDocument = documents.find(
                  document => document.comid === customer.comid
                );
                console.log("matching document", matchingDocument);

                if (matchingDocument) {
                  const { name, phone, comid } = customer;
                  const {
                    human,
                    interested,
                    reply,
                    messages,
                  } = matchingDocument;

                  return {
                    name,
                    phone,
                    reply,
                    messages,
                    comid,
                    human,
                    interested,
                  };
                }

                return null;
              })
              .filter(Boolean);
            console.log("if matching", matchingCustomers);

            setDatalist(matchingCustomers.filter(item => item.reply));
          } else {
            console.error("Fetching failed");
          }
        } catch (error) {
          console.error("Error during Fetching:", error);
        }
      } else {
        const querySnapshot = await getDocs(collection(db, "Customers"));
        const documents = querySnapshot.docs.map(doc => doc.data());
        console.log("documents", documents);
        const agentRef = doc(db, "Agents", localStorage.getItem("agentid"));
        const agentSnapshot = await getDoc(agentRef);
        const agentData = agentSnapshot.data();
        console.log("agentData", agentData);
        const querySnapshot1 = await getDocs(
          collection(db, "WhatsappMessages")
        );
        const WDocuments = querySnapshot1.docs.map(doc => doc.data());

        matchingCustomers = documents
          .filter(customer => {
            return agentData?.assignedCustomers?.some(
              document => document === customer.comid
            );
          })
          .map(customer => {
            const matchingDocument = WDocuments.find(
              document => document.comid === customer.comid
            );
            console.log("matching document", matchingDocument);

            if (matchingDocument) {
              const { name, phone, comid } = customer;
              const { human, interested, reply, messages } = matchingDocument;

              return {
                name,
                phone,
                comid,
                reply,
                messages,
                human,
                interested,
              };
            }

            return null;
          })
          .filter(Boolean);
        console.log("else atching", matchingCustomers);
        setDatalist(matchingCustomers.filter(item => item.reply));
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/group/all/${localStorage.getItem("token1")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log("group response data", responseData);

        if (response.ok) {
          setGrouplist(responseData);
        } else {
          console.error("Fetching failed");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchdata();
  }, []);

  useEffect(() => {
    const MessagesRef = collection(db, "WhatsappMessages");
    console.log("messageref", MessagesRef);
    const messageQuery = query(
      MessagesRef,
      where("comid", "==", `${selecteditem?.comid}`)
    );

    const unsubscribe = onSnapshot(messageQuery, snapshot => {
      var msgs = [];
      snapshot.forEach(doc => {
        console.log(doc.data());
        msgs = doc.data().messages;

        setSelectedData({ ...doc.data(), id: doc.id });
      });

      setCurrMessages(msgs);
    });

    return () => unsubscribe();
  }, [selecteditem]);

  const handleSendClick = async () => {
    console.log("textMessage", textMessage);
    try {
      const passData = {
        number: selecteditem.phone,
        text: textMessage,
        companyId: localStorage.getItem("token1"),
        /*countryCode: "91",*/
      };
      console.log("passdata", passData);
      const response = await fetch(`${backendUrl}/whatsapp/custom`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passData),
      });
      console.log("response", response);

      if (response.ok) {
        setTextmessage("");
      } else {
        console.error("Whatsapp failed");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }
  };

  const handleTextChange = e => {
    setTextmessage(e.target.value);
  };

  const handleBotClick = async () => {
    try {
      const passData = {
        phoneNumber: selecteditem.phone,
        companyId: localStorage.getItem("token1"),
      };
      const response = await fetch(`${backendUrl}/whatsapp/bot`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passData),
      });
      console.log("response", response);

      if (response.ok) {
        settxtf(true);
      } else {
        console.error("Whatsapp failed");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }
  };

  const handleGenerateSummary = async () => {
    try {
      const passData = {
        phoneNumber: selecteditem.phone,
        companyId: localStorage.getItem("token1"),
      };
      console.log("passData", passData);
      const response = await fetch(`${backendUrl}/whatsapp/summary`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passData),
      });
      console.log("response", response);

      if (response.ok) {
        window.location.reload();
      } else {
        console.error("Whatsapp failed");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }
  };
  const [date, setDate] = useState(new Date());
  const [datelist, setDatelist] = useState([]);
  const [openCalender, setopenCalender] = useState(false);
  const [currentDay, setCurrentDay] = useState("");
  const [prevDate, setPrevDate] = useState("");
  const [botCheck, setBotCheck] = useState(false);
  const [unsavedTags, setUnsavedTags] = useState(false);

  if (botCheck) {
    handleBotClick();
  }

  const handleDateChange = newDate => {
    setDate(newDate);
  };

  const handleSubmit = async () => {
    const selectedDate = new Date(date);
    selectedDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    const formattedDate = selectedDate.toISOString().split("T")[0];

    console.log("Selected Date:", formattedDate);

    setDatelist([...datelist, formattedDate]);

    await handleFollowUp(formattedDate);
  };

  useEffect(() => {
    setDatelist(selectedData?.followup || []);
  }, [selectedData]);
  console.log("selectedData", selectedData);
  console.log("datelist", datelist);

  const handleFollowUp = async datelist => {
    try {
      const passData = {
        datelist: datelist,
        comid: selectedData?.comid,
      };
      console.log("datelist", passData);
      const response = await fetch(`${backendUrl}/whatsapp/followup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passData),
      });
      console.log("response", response);

      if (response.ok) {
        console.log("success");
        //window.location.reload()
      } else {
        console.error("Whatsapp failed");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }
  };

  const handleDeleteQues = async (item, edit) => {
    try {
      const passData = {
        id: item.id,
        comid: selectedData?.comid,
      };
      console.log("question list", passData);
      const response = await fetch(`${backendUrl}/company/removeQuestion`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passData),
      });
      console.log("response", response);

      if (response.ok) {
        console.log("success");
        if (edit) {
          localStorage.setItem("ques", item.question);
          navigate("/aiagentdetails");
        }
      } else {
        console.error("Whatsapp failed");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }
  };
  const [currentDate, setCurrentDate] = useState();
  const [prevDatee, setPrevDatee] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const uniqueDates = [
    ...new Set(
      currMessages?.map(item => {
        const messageTimestamp =
          item.date.seconds * 1000 + item.date.nanoseconds / 1000000;
        const dateObject = new Date(messageTimestamp);
        const options = { timeZone: "Asia/Kolkata" };
        return dateObject.toLocaleDateString("en-US", options);
      })
    ),
  ];

  return (
    <>
      {agentClicked && (
        <SelectAgent closeModal={closeModal} customer={selecteditem} />
      )}
      <Drawer />

      <div className="chat-container">
        <div className={style.clmainOuter}>
          <div className={style.cltop}>
            <div className={style.clnumberheading}>{selecteditem?.phone}</div>
            <div className={style.clbuttoncontent}>
              <div>
                <input
                  type="text"
                  className={style.climgouter}
                  placeholder="Search by name or phone number"
                  value={searchTerm}
                  onChange={text => {
                    setSearchTerm(text.target.value);
                    handleSearch(text.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={style.clbottom}>
            <div className={style.clbuttons}>
              <div
                className={
                  category === "Individuals" ? style.clselect : style.cldeselect
                }
                onClick={() => setCategory("Individuals")}
              >
                Individuals
              </div>
            </div>
            <div className={style.filter} ref={dropdownRef}>
      <button
        className={style.dropdownButton}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        Filter Options
      </button>
      {dropdownOpen && (
        <div className={style.dropdownMenu}>
          <label>
            <input
              type="checkbox"
              checked={showNotInterested}
              onChange={() => setShowNotInterested(!showNotInterested)}
            />
            Show Not Interested
          </label>
          <label>
            <input
              type="checkbox"
              checked={showHuman}
              onChange={() => setShowHuman(!showHuman)}
            />
            Show Human
          </label>
          <label>
            <input
              type="checkbox"
              checked={showInterested}
              onChange={() => setShowInterested(!showInterested)}
            />
            Show Interested
          </label>
        </div>
      )}
    </div>
            <div className={style.cllist}>
              {category === "Individuals" ? (
                <>
                  {Object.values(
                    searchTerm.length > 0 ? searchResults : datalist
                  ).map(item => {
                    let latestDate = item.messages.reduce((latest, message) => {
                      const messageTimestamp =
                        message.date.seconds * 1000 +
                        message.date.nanoseconds / 1000000;
                      return latest < messageTimestamp
                        ? messageTimestamp
                        : latest;
                    }, 0);
                    const latestDateObject = new Date(latestDate);
                    const formattedDate = latestDateObject.toLocaleDateString();

                    return (!showNotInterested || item.notInterested) &&
                      (!showHuman || item.human) &&
                      (!showInterested || item.interested) ? (
                      <div
                        className={style.clone}
                        onClick={() => {
                          setSelecteditem(item);
                          setTags({
                            human: tags?.human || item?.human,
                            interested: tags?.interested || item?.interested,
                            notInterested:
                              tags?.notInterested || item?.notInterested,
                          });
                        }}
                      >
                        <div className={style.clnameOuter}>
                          <div className={style.clname}>{item.name}</div>
                          {item.human && (
                            <div className={style.clwrapper}>
                              <img src={settings} alt="img" />
                              requires human intervention
                            </div>
                          )}
                          {item.interested && (
                            <div className={style.clwrapper}>
                              <img src={settings} alt="img" />
                              Interested
                            </div>
                          )}
                        </div>
                        <div className={style.cldate}>{formattedDate}</div>
                      </div>
                    ) : null;
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        {selecteditem ? (
          <>
            <div className="chatbox">
              <div className="name-conatainer">
                <div className="name">
                  <span>{selecteditem?.name} </span>
                  <HiOutlineDotsCircleHorizontal
                    style={{ fontSize: "35px" }}
                    onClick={() =>
                      navigate(
                        `/customerdetails/${`${localStorage.getItem(
                          "token1"
                        )}-${selecteditem.phone}`}`
                      )
                    }
                  />
                </div>
                <div className="name">
                  <span>Bot</span>
                  <Switch
                    {...label}
                    checked={selectedData?.bot}
                    onClick={() => handleBotClick()}
                  />
                </div>
              </div>
              <div className="chatMsg-container">
                {uniqueDates.map((date, index) => (
                  <React.Fragment key={index}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "11px",
                      }}
                    >
                      {date}
                    </div>{" "}
                    {/* Display currentDate */}
                    {currMessages
                      .filter(item => {
                        const messageTimestamp =
                          item.date.seconds * 1000 +
                          item.date.nanoseconds / 1000000;
                        const dateObject = new Date(messageTimestamp);
                        const options = {
                          timeZone: "Asia/Kolkata",
                        };
                        return (
                          dateObject.toLocaleDateString("en-US", options) ===
                          date
                        );
                      })
                      .map((item, index) => {
                        const messageTimestamp =
                          item.date.seconds * 1000 +
                          item.date.nanoseconds / 1000000;
                        const dateObject = new Date(messageTimestamp);
                        const options = {
                          timeZone: "Asia/Kolkata",
                          hour12: false,
                          hour: "2-digit",
                          minute: "2-digit",
                        };
                        let formattedTime = dateObject.toLocaleTimeString(
                          "en-US",
                          options
                        );
                        const [hours, minutes] = formattedTime.split(":");
                        formattedTime = `${hours}:${minutes}`;

                        console.log("formattedTime", `${hours}:${minutes}`);

                        return (
                          <React.Fragment key={item.messageId}>
                            {item.usermessage && (
                              <div
                                className="chat-msg-receive"
                                key={item.usermessage}
                              >
                                <div className="msg-receive">
                                  {item.usermessage}
                                </div>
                                <div className="rec-time">{formattedTime}</div>
                              </div>
                            )}

                            {!item.message?.template &&
                              item.message?.text.body && (
                                <div
                                  className="chat-msg-send"
                                  key={item.messageId}
                                >
                                  <div className="msg-send">
                                    {item.message?.text.body}
                                  </div>
                                  <div className="send-time">
                                    {formattedTime}
                                  </div>
                                </div>
                              )}
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                ))}
              </div>
              {!selectedData?.bot && (
                <div className="type-msg">
                  <input
                    type="text"
                    value={textMessage}
                    onChange={handleTextChange}
                    placeholder="Send a message..."
                  />
                  <span onClick={handleSendClick}>
                    <IoSend
                      style={{
                        fontSize: "2rem",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </div>
              )}
            </div>
            <div className="chat-sidebar">
              {!localStorage.getItem("agentid") && (
                <div
                  className="assign-agent-btn"
                  onClick={() => setAgentclicked(true)}
                >
                  <img src={GirlImg} alt="vector" /> <span>Assign Agent</span>
                </div>
              )}
              <div className="chat-notes">
                <div className="chat-notes-heading">
                  <span>Notes</span>
                  {/* <IoPencilSharp style={{ fontSize: "20px" }} /> */}
                </div>
                {summaryData ? (
                  <div className="scroll-container">
                    {summaryData.map(entry => {
                      // Extract dateKey and summaryValue from each entry
                      const dateKey = Object.keys(entry)[0];
                      const summaryValue = entry[dateKey];

                      // Return a div with dateKey and summaryValue
                      return (
                        <div key={dateKey} className="notes">
                          <p id="pclass">{dateKey}</p>
                          <p id="pclass">{summaryValue}</p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div></div>
                )}

                <div
                  className="notes-generatesummary-btn"
                  onClick={handleGenerateSummary}
                >
                  Generate Summary
                </div>
              </div>

              <div className="followup">
                <div className="followup-heading">
                  <span>Follow-Ups</span>{" "}
                  <IoIosArrowDropdown
                    style={{ fontSize: "25px" }}
                    onClick={() => setopenCalender(!openCalender)}
                  />
                </div>
                {selectedData && (
                  <div className="followup-calender">
                    <div>
                      {datelist?.map((item, index) => (
                        <li>{index + 1}st Follow-Up</li>
                      ))}
                    </div>
                    <div>
                      {datelist?.map(item => (
                        <div>{item}</div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {openCalender && selecteditem && (
                <div
                  style={{
                    margin: "20px",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                >
                  <Calendar
                    onChange={handleDateChange}
                    value={date}
                    calendarType="US"
                    tileClassName={() => "custom-tile"}
                  />
                  <button
                    onClick={handleSubmit}
                    style={{
                      marginTop: "20px",
                      padding: "10px 15px",
                      borderRadius: "5px",
                    }}
                  >
                    Submit
                  </button>
                </div>
              )}

              <div className="addtag">
                <div className="addtag-heading">
                  <span>Tags</span>
                  {unsavedTags && (
                    <button
                      style={{
                        background: "transparent",
                        border: "none",
                      }}
                      onClick={async () => {
                        console.log("selectedItem: ", selecteditem);
                        console.log(`
                        id: ${selecteditem?.comid}
                        updates: ${JSON.stringify(
                          {
                            human: tags?.human,
                            interested: tags?.interested,
                            notInterested: tags?.notInterested,
                          },
                          null,
                          2
                        )}
                        `);
                        await setDoc(
                          doc(
                            collection(db, "WhatsappMessages"),
                            selecteditem?.comid
                          ),
                          {
                            human: tags?.human,
                            interested: tags?.interested,
                            notInterested: tags?.notInterested,
                          },
                          { merge: true }
                        );
                        await setDoc(
                          doc(
                            collection(db, "Customers"),
                            selecteditem?.comid
                          ),
                          {
                            human: tags?.human,
                            interested: tags?.interested,
                            notInterested: tags?.notInterested,
                          },
                          { merge: true }
                        );
                        setUnsavedTags(false);
                      }}
                    >
                      Save
                    </button>
                  )}
                </div>
                <div className="tags">
                  <button
                    className={tags?.human ? "selected" : "unselected"}
                    onClick={() => {
                      setUnsavedTags(true);
                      setTags({
                        ...tags,
                        human: !tags?.human,
                        notInterested: false,
                      });
                    }}
                  >
                    Requires Human Intervention
                  </button>
                  <button
                    className={tags?.interested ? "selected" : "unselected"}
                    onClick={() => {
                      setUnsavedTags(true);
                      setTags({
                        ...tags,
                        interested: !tags?.interested,
                        notInterested: false,
                      });
                    }}
                  >
                    Interested
                  </button>

                  <button
                    className={tags?.notInterested ? "selected" : "unselected"}
                    onClick={() => {
                      setUnsavedTags(true);
                      setTags({
                        ...tags,
                        notInterested: !tags?.notInterested,
                        human: false,
                        interested: false,
                      });
                    }}
                  >
                    Not Interested
                  </button>
                </div>
              </div>

              <div className="addtag">
                <div className="addtag-heading"> Questions</div>
                <div className="tags">
                  {selectedData?.questions &&
                    selectedData?.questions?.map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "55%" }}>{item.question}</div>
                        <div
                          style={{
                            color: "Blue",
                            width: "20%",
                          }}
                          onClick={() => handleDeleteQues(item, true)}
                        >
                          Approve
                        </div>
                        <div
                          style={{
                            color: "Red",
                            width: "20%",
                          }}
                          onClick={() => handleDeleteQues(item, false)}
                        >
                          Ignore
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="defaultChatBg" />
        )}
      </div>
    </>
  );
};

export default ChatBox;
