import React, { useEffect, useState } from "react";
import style from "../../AgentManagement/AgentDetails/AgentDetails.module.css";
import whatsapp from "../../../Assets/whatsapp.png";
import deleteimage from "../../../Assets/delete.png";
import editimage from "../../../Assets/editpng.png";
import Drawer from "../../Drawer/Drawer";
import arrow from "../../../Assets/arrow.png";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import backendUrl from "../../../config";
import pdf from "../../../Assets/pdfpng.png";
// import Pdfpriview from "../../Dropbox/Pdfpriview";

const CustomerDetails = () => {
	const [mydata, setMydata] = useState({});
	const [customerDetails, setCustomerdetails] = useState({});
	const { id } = useParams();
	const [tags, setTags] = useState([]);
	const [summaryData, setsummaryData] = useState();
	const navigate = useNavigate();
	const {
		documents: ignoredDocuments,
		comid: ignoredComid,
		companyId,
		...newData
	} = mydata;

	const newDataEntries = Object.entries(newData);
	console.log(newDataEntries);
	// const [showPriview, setShowPriview] = useState(false);
	// const [file,setFile]=useState()

	useEffect(() => {
		const fetchdata = async () => {
			try {
				const response = await fetch(
					`${backendUrl}/whatsapp/getSummary/${id}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				const responseData = await response.json();
				console.log("Summary response data", responseData);

				if (response.ok) {
					console.log("response is ok");
					setsummaryData(responseData.summaryData);
				} else {
					console.error("Fetching failed");
				}
			} catch (error) {
				console.error("Error during Fetching:", error);
			}
		};
		fetchdata();
	}, [id]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${backendUrl}/customer/${id}`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				});
				console.log("response", response);

				if (response.ok) {
					const responseData = await response.json();
					console.log("responseData", responseData);
					setMydata(responseData.data);
				} else {
					console.error("Did not receive the  agent ");
				}

				const res = await fetch(
					`${backendUrl}/customer/customerdetails/${id}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				console.log("res", res);
				console.log("response data", res);

				if (res) {
					const resData = await res.json();
					console.log("newresData", resData);
					console.log("tags", resData.tags);

					setTags(resData.tags);

					setCustomerdetails(resData.details);
				} else {
					console.error("Did not receive the  customer details ");
				}
			} catch (error) {
				console.error("Error during Fetching:", error);
			}
		};
		fetchData();
	}, [id]);

	const handleDeleteAgent = async () => {
		const passData = {
			phone: mydata.phone,
			companyId: localStorage.getItem("token1"),
		};
		try {
			const response = await fetch(`${backendUrl}/customer`, {
				method: "DELETE",
				headers: {
					"Content-type": "application/json",
				},
				body: JSON.stringify(passData),
			});

			if (response.ok) {
				navigate(`/customer`);
			} else {
				console.log("Delete Failed");
			}
		} catch (error) {
			console.log("Error during deleting agent ", error);
		}
	};

	const handlePreviewClick = (item) => {
		window.open(item.downloadURL, "_blank");
	};

	return (
		<>
			<Drawer />
			<div className={style.profileOuter}>
				<div className={style.detOuter}>
					<div className={style.detHeading}>
						<div
							className={style.addHeading}
							onClick={() => navigate("/customer")}
						>
							<img src={arrow} alt="img" />
							<div className={style.addHeadingtext}>
								Customer Details
							</div>
						</div>
						<div className={style.detHeading2}>
							<div
								className={style.deteditbtn}
								style={{
									backgroundColor: "green",
									border: "none",
									color: "white",
								}}
								onClick={() => navigate(`/chat/${id}`)}
							>
								<img src={whatsapp} alt="" />
								Chat
							</div>
							<div
								className={style.deteditbtn}
								onClick={() =>
									navigate(`/customer/update/${id}`)
								}
							>
								<img src={editimage} alt="img" />
								Edit
							</div>
							<div
								className={style.detdeletebtn}
								onClick={() => handleDeleteAgent()}
							>
								<img src={deleteimage} alt="img" />
								Delete
							</div>
						</div>
					</div>
					<div className={style.detMiddle}>
						{newDataEntries.map(([key, value]) => (
							<div className={style.detinfo}>
								<div className={style.detsmallheading}>
									{key === "interestedBanks"
										? "Interested Banks"
										: key}{" "}
									:
								</div>
								<div className={style.detsmallinfo}>
									{" "}
									{Array.isArray(value)
										? [
												...new Set(
													value.map((item) => item)
												),
										  ].map((name, key) => (
												<span key={key}>{name}, </span>
										  ))
										: value}
								</div>
							</div>
						))}

						<DetailItem
							title="Net Income"
							data={customerDetails?.net_salary}
						/>
						<DetailItem
							title="Tenure"
							data={customerDetails?.tenure}
						/>
						<DetailItem
							title="Loan Asked"
							data={customerDetails?.loan_amount}
						/>
						<DetailItem
							title="Existing Obligations"
							data={customerDetails?.emi}
						/>
						<DetailItem
							title="Tags"
							data={tags?.map((entry) => {
								console.log("entry", entry);
								return (
									<div
										key={entry}
										className="tags"
										style={{
											border: "solid 1px black",
											borderRadius: "30px",
											padding: "5px",
											height: "30px",
											width: "fit-content",
											margin: "5px",
											backgroundColor: "#000000",
											color: "#ffffff",
										}}
									>
										<div
											id="pclass"
											style={{
												color: "white",
												width: "fit-content",
											}}
										>
											{entry}
										</div>
									</div>
								);
							})}
						/>
						<DetailItem
							title="Notes"
							data={summaryData?.map((entry) => {
								// Extract dateKey and summaryValue from each entry
								const dateKey = Object.keys(entry)[0];
								const summaryValue = entry[dateKey];

								return (
									<div key={dateKey} className="notes">
										<div id="pclass">{dateKey}</div>
										<div id="pclass">{summaryValue}</div>
									</div>
								);
							})}
						/>
					</div>
					{mydata?.documents && (
						<div className="pbottom">
							<div className="pbotheading">Document Uploaded</div>
							<div className="pfiles">
								{mydata?.documents?.map((item) => (
									<div
										style={{
											display: "flex",
											gap: "10px",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<div className="psinglefile">
											{/* {userdoc[key]} */}
											<img src={pdf} alt="img" />
											{item.fileName}
										</div>
										<div
											style={{ fontSize: "12px" }}
											onClick={() =>
												handlePreviewClick(item)
											}
										>
											Preview
										</div>
									</div>
								))}
								{/*<div className={style.psinglefile}><img src={pdf} alt='img' />File1</div>
          <div className={style.psinglefile}>File2</div>
          <div className={style.psinglefile}>File3</div>*/}
							</div>
						</div>
					)}
					{/* {showPriview && <Pdfpriview file={file} setShowPriview={setShowPriview} />} */}
				</div>
			</div>
		</>
	);
};

const DetailItem = ({ title, data }) => {
	return data && (typeof data !== "object" || data?.length) ? (
		<div className={style.detinfo}>
			<div className={style.detsmallheading}>{title} :</div>
			<div className={style.detsmallinfo}>{data}</div>
		</div>
	) : null;
};

export default CustomerDetails;
