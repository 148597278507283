import React, { useState } from "react";
import "./CreateCampaign.module.css";
import arrow from "../../../Assets/arrow.png";
import campaignOne from "../../../Assets/campaignOne.png";
import style from "./CreateCampaign.module.css";
import { useNavigate } from "react-router-dom";
import Drawer from "../../Drawer/Drawer";

const CreateCampaign = () => {
  const [name, setName] = useState(localStorage.getItem("campaignname") || "");
  const navigate = useNavigate();
  const handlename = (e) => {
    setName(e.target.value);
  };
  const handleNext = () => {
    if (validateForm()) {
      localStorage.setItem("campaignname", name);
      navigate("/customer/bulk");
    }
  };
  const [errors, setErrors] = useState("");

  const validateForm = () => {
    let valid = true;

    // Name validation
    if (name.trim() === "") {
      setErrors("Campaign name is required");
      valid = false;
    } else {
      setErrors("");
    }
    return valid;
  };
  const handlebackclick = () => {
    localStorage.removeItem("campaignname");
    localStorage.removeItem("campaigncustomers");
    localStorage.removeItem("campaigntemplate");
    localStorage.removeItem("campaignfrom");
    localStorage.removeItem("campaignto");
    localStorage.removeItem("campaignId");
    navigate("/campaign");
  };
  return (
    <>
      <Drawer />
      <div className={style.ccmainOuter}>
        <div className={style.ccOuter}>
          <div className={style.ccheading} onClick={() => handlebackclick()}>
            <img className={style.ccarrowimg} src={arrow} alt="img" /> Campaign
            Creation
          </div>

          <div className={style.ccMiddle}>
            <img className={style.campaignoneimg} src={campaignOne} alt="img" />
            <div className={style.cctext}>
              Give your Campaign a name to get started.
            </div>
            <input
              className={style.ccinput}
              onChange={handlename}
              value={name}
              placeholder="Campaign Name"
            />
            {errors ? <span style={{ color: "red" }}>{errors}</span> : <></>}
            <div className={style.ccnext}>
              <div className={style.ccnextbtn} onClick={handleNext}>
                Next
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateCampaign;
