import React, { Component, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginSignup from "./Pages/LoginSignup/LoginSignup";
import SignIn from "./Pages/SignInPage/SignIn";
import Dashbord from "./Pages/Dashbord";
import AgentDashbord from "./Pages/AgentPages/AgentDashboard";
import AgentSideBar from "./Components/AgentSideBar/SideBar";
import Protectected from "./Logic/Protectected";
import Home from "./Pages/Home";
import Token from "./Components/Tokengenerate/Token";
import CreateAi from "./Pages/TemplateCreation/CreateAi/CreateAi";
import ScreenOne from "./Pages/TemplateCreation/Manual/ScreenOne";
import Dropbox from "./Components/OnboardingScreens/First/Dropbox";
import CustomerInfo from "./Pages/AdmainPage/CustomerInfo";
import MainDashboard from "./Components/MainDashboard/MainDashboard";
import AddAgent from "./Components/AgentManagement/AddAgent/AddAgent";
import AgentDashboard from "./Components/AgentManagement/AgentDashboard/AgentDashboard";
import AddCustomer from "./Components/CustomerManagement/AddCustomer/AddCustomer";
import EditAgent from "./Components/AgentManagement/EditAgent/EditAgent";
import CustomerDashboard from "./Components/CustomerManagement/CustomerDashboard/CustomerDashboard";
import EditCustomer from "./Components/CustomerManagement/EditCustomer/EditCustomer";
import AgentDetails from "./Components/AgentManagement/AgentDetails/AgentDetails";
import Profile from "./Components/ProfileManagement/Profile/Profile";
import EditProfile from "./Components/ProfileManagement/Profile/EditProfile";
import UpdatePass from "./Components/ProfileManagement/UpdatePass";
import CustomerDetails from "./Components/CustomerManagement/CustomerDetails/CustomerDetails";
import CompanyProfile from "./Pages/AdmainPage/CompanyProfile";
import BotDescription from "./Components/OnboardingScreens/Second/BotDescriptionComponent";
import EditGroup from "./Components/CustomerManagement/EditGroup";
import SingleGroup from "./Components/CustomerManagement/SingleGroup";
import CampaignDash from "./Components/Compaign/CampaignDash/CampaignDash";
import CreateCampaign from "./Components/Compaign/CreateCampaign/CreateCampaign";
import CampaignSchedule from "./Components/Compaign/CampaignSchedule/CampaignSchedule";
import SelectUsers from "./Components/Compaign/SelectUsers/SelectUsers";
import CaptureLeadComponent from "./Components/OnboardingScreens/Fourth/CaptureLeadComponent";
import LeadsDisplay from "./Components/OnboardingScreens/Fourth/LeadsDisplay";
import AgentLogin from "./Components/AgentLogin/AgentLogin";
import Dashboard from "./Pages/AdmainPage/Dashboard";
import { Toaster } from "react-hot-toast";
import CamSuccessful from "./Components/Compaign/CamSuccessful";
import StartOnboarding from "./Components/OnboardingScreens/StartOnboarding";
import Chatleft from "./Components/Chat/Chatleft";
import ChatBox from "./Components/Chat/ChatBox";
import BankDash from "./Components/Banks/BankDash";
import AiAgentDetails from "./Components/AgentManagement/AiAgentsDetails/AiAgentDetails";
import RequiredDocuments from "./Components/OnboardingScreens/Fourth/RequiredDocuments";
import OnboardingSuccessful from "./Components/OnboardingScreens/OnboardingSuccessful";
// import Notification from "./Components/Notification";
import Notifications from "./Components/Notifications/Notifications";

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [id, setid] = useState();

  useEffect(() => {
    console.log(localStorage.getItem("token1") === undefined);
    if (localStorage.getItem("token")) {
      setid(localStorage.getItem("token"));

      setLoggedIn(true);
      console.log("local storage", localStorage.getItem("token"));
      console.log("token1", localStorage.getItem("token1"));
      // window.location.href = "/dashboard";
    }
  }, [localStorage.getItem("token")]);

  // {/* {
  //   loggedIn ? (
  //     <Dashbord />
  //   ) : (
  //     <LoginSignup />
  //   )
  // } */}
  return (<>
    <Routes>
      <Route path="/" element={<LoginSignup />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/startonboarding" element={<StartOnboarding />} />
      <Route path="/finishonboarding" element={<OnboardingSuccessful />} />
      <Route path="/auth/signup" element={<LoginSignup />} />
      <Route path="/auth/signin" element={<SignIn />} />
      {/*<Route path="/dashboard" element={<MainDashboard />} />*/}
      <Route path="/agent/add" element={<AddAgent />} />
      <Route path="/agent/update/:id" element={<EditAgent />} />
      <Route path="/agentdetails/:id" element={<AgentDetails />} />
      <Route path="/aiagentdetails" element={<AiAgentDetails />} />
      <Route path="/agent/:id" element={<AgentDashboard />} />
      <Route path="/addcustomer" element={<AddCustomer />} />
      <Route path="/customer" element={<CustomerDashboard />} />
      <Route path="/customerdetails/:id" element={<CustomerDetails />} />
      <Route path="/customer/update/:id" element={<EditCustomer />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/editprofile" element={<EditProfile />} />
      <Route path="/profile/updatepassword" element={<UpdatePass />} />
      <Route path="/manualtemplate" element={<ScreenOne />} />
      <Route path="/aitemplate" element={<CreateAi />} />
      <Route path="/customer/bulk" element={<CustomerInfo />} />
      <Route path="/companyprofile" element={<BotDescription />} />
      <Route path="/document" element={<Dropbox />} />
      <Route path="/group/update/:id" element={<EditGroup />} />
      <Route path="/single-group/:id" element={<SingleGroup />} />
      <Route path="/campaign" element={<CampaignDash />} />
      <Route path="/createcampaign" element={<CreateCampaign />} />
      <Route path="/campaignschedule" element={<CampaignSchedule />} />
      <Route path="/selectcampaignusers" element={<SelectUsers />} />
      <Route path="/schedulecamp" element={<CamSuccessful />} />
      <Route path="/leadConversion" element={<CaptureLeadComponent />} />
      <Route path="/required-document" element={<RequiredDocuments />} />
      <Route path="/leadsdisplay" element={<LeadsDisplay />} />
      <Route path="/agentlogin" element={<AgentLogin />} />
      {/*<Route path="/bot" element={<BotDescription/>}/>*/}
      <Route path="/chat" element={<ChatBox />} />
      <Route path="/chat/:id" element={<ChatBox />} />
      <Route path="/banks" element={<BankDash />} />
    </Routes>
    <Toaster />
  </>
  );
};

// <Notification />
export default App;
/*
{/* <BrowserRouter> *
      {/* <>
         {loggedIn ? (
          <Dashbord />
        ) : loginForm ? (
          <LoginSignup setLoginForm={setLoginForm} />
        ) : (
          <SignIn setLoginForm={setLoginForm} />
        )}
      </> *
      {/* <AgentDashbord/> *
      {/* <Routes>
        <Route path="/" element={<LoginSignup/>}/>
        <Route path="/SignIn" element={<SignIn/>}/>
        <Route path="/home" element ={<Protectected Component={Component} />}/>
      </Routes>
    </BrowserRouter> *
    {/* <CompanyProfile/> *
    {/* <CustomerInfo/> *
    {/* <Token/> *
  */
