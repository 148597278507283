import React, { useState, useEffect } from "react";
import Drawer from "../../../Components/Drawer/Drawer";
import Navbar from "../../../Components/Navbar/Navbar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import "./CreateAi.css";
import AiBot from "./AiBot";
import { useNavigate } from "react-router-dom";
import backendUrl from "../../../config";

const CreateAi = () => {
  const [loading, setLoading] = React.useState(true);
  const [templatelist, setTemplatelist] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/template/aitemplate/${localStorage.getItem(
            "token"
          )}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response", response);

        if (response.ok) {
          const responseData = await response.json();
          let filteredArray = responseData.aiTemplates.filter(
            (element) => element !== null
          );
          console.log("responseData", filteredArray);
          setTemplatelist(filteredArray);
        } else {
          console.error("Did not receive the  agent ");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);

  function handleClick() {
    setLoading(!loading);
  }
  return (
    <div>
      <Drawer />
      <div className="manul01">
        <div className="manul-heading">
          <h2 className="h2">AI-Based Templates</h2>
          <p className="p1">
            Choose any formate from the given below,and edit them manually
            according to your prefrence.
          </p>
        </div>
        <div className="manual-buttons">
          <div
            className="deselected"
            onClick={() => navigate("/manualtemplate")}
          >
            Manual
          </div>
          <div className="manualbtn">AI</div>
        </div>
      </div>
      <div className="lower-container">
        <div className="side-bar">
          <Button className="colourbtn" variant="contained" disableElevation>
            + New Template
          </Button>
          <p className="p4">RECENT TEMPLATES</p>
          {templatelist && (
            <div>
              {templatelist.map((item) => (
                <div>{item.name}</div>
              ))}
            </div>
          )}
        </div>
        <AiBot />
      </div>
    </div>
  );
};

export default CreateAi;
