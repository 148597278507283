import React, { useEffect, useState } from "react";
import style from "../../CustomerManagement/AddCustomer/AddCustomer.module.css";
import arrow from "../../../Assets/arrow.png";
import Drawer from "../../Drawer/Drawer";
import { Link, useNavigate } from "react-router-dom";
import eye from "../../../Assets/eye.png";
import backendUrl from "../../../config";
import toast from "react-hot-toast";
// import { requestToken } from "../../../firebase";

const AddAgent = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [num, setNum] = useState(0);
  const [userid, setUserid] = useState();
  const [password, setPassword] = useState("");
  const id = localStorage.getItem("token");
  const [errormsg, setErrormsg] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [checkUpload, setcheckUpload] = useState(false)

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    num: "",
    password: "",
  });

  const validateForm = () => {
    let valid = true;

    // Name validation
    if (name.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "",
      }));
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    // Mobile number validation
    const numRegex = /^\d{10}$/;
    if (!numRegex.test(num)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        num: "Invalid mobile number",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        num: "",
      }));
    }

    // Password validation
    if (password.length < 8) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 8 characters long",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }

    return valid;
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handlename = (e) => {
    setName(e.target.value);
  };
  const handleemail = (e) => {
    setEmail(e.target.value);
  };
  const handlephone = (e) => {
    setNum(e.target.value);
  };
  const handleuserid = (e) => {
    setUserid(e.target.value);
  };
  const handlepassword = (e) => {
    setPassword(e.target.value);
  };
  const handleaddclick = async (e) => {
    e.preventDefault();
    const passdata = {
      name: name,
      email: email,
      adminId: id,
      phoneNumber: '91' + num,
      companyId: localStorage.getItem("token1"),
      password: password,
      assignedCustomers: [],
    };
    console.log(passdata);
    if (validateForm()) {
      try {
        const response = await fetch(`${backendUrl}/agent/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(passdata),
        });
        const responseData = await response.json();
        console.log(responseData);
        console.log(response);

        if (response.ok) {
          //navigate(`/agent/${localStorage.getItem("token")}`);
          // console.log(responseData)
          setcheckUpload(true);
            toast.success("Agent Created");

          // const tkn = await requestToken(responseData.id)
          // const subscribeRes = await fetch(`${backendUrl}/subscribe`, {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          //   body: JSON.stringify({ id: responseData.id,topic:localStorage.getItem('token1'),token:tkn }),
          // });

          // if(subscribeRes.ok){
          //   setcheckUpload(true);
          //   toast.success("Agent Created");
          // }
          // else{
          //   console.log("Add agent Failed");
          // }  

          
          
        } else {
          setErrormsg(responseData.message);
          resetfields();
          console.error("Add failed");
          toast.error(responseData.message);
        }
      } catch (error) {
        console.error("Error during Adding:", error);
      }
    }
  };
  const resetfields = () => {
    setName("");
    setEmail("");
    setNum("");
    setPassword("");
  };

  const handleNextClick=async()=>{
    
    try {
      let newSteps=6;

      const response = await fetch(
        `${backendUrl}/admin/update/${localStorage.getItem("token")}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body:JSON.stringify({steps:newSteps})
        }
      );
      console.log("response", response);

      if (response.ok) {
      localStorage.setItem("steps",newSteps)
       navigate("/manualtemplate")
      } else {
        console.error("Did not receive the  company info ");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }


  }

  return (
    <>
      <Drawer />
      <div className={style.profileOuter}>
        <div className={style.addOuter}>
          <div
            className={style.addHeading}
            onClick={() => navigate(`/agent/${localStorage.getItem("token")}`)}
          >
            <img src={arrow} alt="img" />
            <div className={style.addHeadingtext}>Add Agent</div>
          </div>
          <div className={style.addFields}>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Agent Name</label>
              <input
                className={style.addinput}
                name="name"
                value={name}
                type="text"
                onChange={handlename}
                required
              />
              {errors?.name ? (
                <span className="error" style={{ color: "red" }}>
                  {errors.name}
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Agent's email address</label>
              <input
                className={style.addinput}
                name="email"
                type="text"
                onChange={handleemail}
                required
              />
              {errors?.email ? (
                <span className="error" style={{ color: "red" }}>
                  {errors.email}
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Phone Number</label>
              <div className={style.addphoneinput}>
                <div className={style.addnum}>+91</div>
                <input
                  className={style.addinput}
                  name="num"
                  style={{ width: "55%" }}
                  type="number"
                  onChange={handlephone}
                />
                <br />
              </div>
              {errors?.num ? (
                <span className="error" style={{ color: "red" }}>
                  {errors.num}
                </span>
              ) : (
                <></>
              )}
            </div>
            {/*<div className={style.addonefield}>
                    <label className={style.addlabel}>User ID</label>
                    <input className={style.addinput} name='name'  type='text'  onChange={handleuserid} />
    </div>*/}
            <div className={style.addonefield}>
              <label className={style.addlabel}>Password</label>
              <div className="WrapperIB" style={{ width: "60%" }}>
                <input
                  className={style.addinput}
                  name="name"
                  type={showPassword ? "text" : "password"}
                  onChange={handlepassword}
                />
                <button type="button" onClick={handleTogglePassword}>
                  <img src={eye} alt="img" />
                </button>
              </div>
              {errors?.password ? (
                <span className="error" style={{ color: "red" }}>
                  {errors.password}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
          <p>{errormsg}</p>
          <div className={style.addButtons}>
            <div
              className={style.addcancelbtn}
              onClick={() => {

                if (localStorage.getItem("isFirstTimeUser") === "true") {
                  handleNextClick()

                }
                else {

                  navigate(`/agent/${localStorage.getItem("token")}`)
                }
              }
            }
            >
              Cancel
            </div>
            <div className={style.addaddbtn} onClick={handleaddclick}>
              Add
            </div>
            {localStorage.getItem("isFirstTimeUser") === "true" && localStorage.getItem("steps")==="5" && checkUpload && (
              <div style={{ width: "100%", textAlign: "right" }}>
                <div>
                <button
                  className="btn btn-primary"
                  style={{ width: "60px" }}
                  onClick={() =>handleNextClick()}
                >
                  Next
                </button>
              </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAgent;
