import React, { useEffect, useState } from "react";
import style from "./CampaignSchedule.module.css";
import calendar from "../../../Assets/calendar.png";
import clock from "../../../Assets/clock.png";
import arrow from "../../../Assets/arrow.png";
import { useNavigate } from "react-router-dom";
import Drawer from "../../Drawer/Drawer";
import backendUrl from "../../../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CampaignSchedule = () => {
  const [showtimeInput, setShowTimeInput] = useState(false);
  const [from, setFrom] = useState("");
  const [edit, setEdit] = useState(false);
  const [to, setTo] = useState("");
  const navigate = useNavigate();
  const [timeArray, setTimearray] = useState([]);
  const [hours, setHours] = useState('12');
  const [minutes, setMinutes] = useState('00');
  const [period, setPeriod] = useState('AM');

  const handleHourChange = (e) => {
    setHours(e.target.value);
  };

  const handleMinuteChange = (e) => {
    setMinutes(e.target.value);
  };

  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
  };

  useEffect(() => {
    if (localStorage.getItem("campaignId")) {
      setEdit(true);
      const timingsString = localStorage.getItem("campaigntimings");
      const timingsArray = timingsString.split(",");
      setTimearray(timingsArray);
    }
  }, []);
  console.log("from", from);
  console.log("edit", edit);
  const convertTimeTo12hr = (time24hrStr) => {
    let hours = time24hrStr.substring(0, 2);
    let minutes = time24hrStr.substring(2);

    // Determine AM or PM
    const modifier = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    // Return the time in 12-hour format with AM/PM
    return `${hours}:${minutes} ${modifier}`;
  };

  const handleSave = async () => {
    try {
      const newCustomers = JSON.parse(
        localStorage.getItem("campaigncustomers")
      );
      const passData = {
        name: localStorage.getItem("campaignname"),
        template: localStorage.getItem("campaigntemplatename"),
        customers: newCustomers,
        description: " ",
        groups: [],
        companyId: localStorage.getItem("token1"),
        timings: timeArray,
      };
      console.log(passData);
      const response = await fetch(`${backendUrl}/campaign/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passData),
      });
      console.log("response", response);

      if (response.ok) {
        const responseData = await response.json();
        console.log("responseDataaaa", responseData);
        await multipleTemplate(responseData.id);
        localStorage.removeItem("campaigncustomers");
        localStorage.removeItem("campaigntemplate");
        localStorage.removeItem("campaignname");
        navigate("/schedulecamp");
      } else {
        console.error("Did not receive the  agent ");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }
  };
  // const convertTime = timeStr => {
  //     const [time, modifier] = timeStr.split(' ');
  //     let [hours, minutes] = time.split(':');
  //     if (hours === '12') {
  //         hours = '00';
  //     }

  //     if (modifier === 'PM') {
  //         hours = parseInt(hours, 10) + 12;
  //     }
  //     return `${hours}${minutes}`;
  // };

  const handleEdit = async () => {
    try {
      const newCustomers = JSON.parse(
        localStorage.getItem("campaigncustomers")
      );
      const passData = {
        name: localStorage.getItem("campaignname"),
        template: localStorage.getItem("campaigntemplatename"),

        customers: newCustomers,
        description: " ",
        groups: [],
        timings: timeArray,
      };
      console.log(passData);
      const response = await fetch(
        `${backendUrl}/campaign/${localStorage.getItem("campaignId")}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(passData),
        }
      );
      console.log("response", response);

      if (response.ok) {
        await multipleTemplate(localStorage.getItem("campaignId"));
        localStorage.removeItem("campaigncustomers");
        localStorage.removeItem("campaigntemplate");
        localStorage.removeItem("campaignname");
        localStorage.removeItem("campaignId");
        localStorage.removeItem("campaignfrom");
        localStorage.removeItem("campaignto");
        navigate("/schedulecamp");
      } else {
        console.error("Did not receive the  agent ");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }
  };

  const handleAddSchedule = () => {
    const combinedString = `${from} ${hours}:${minutes}${period}`;
    console.log("combinedString", combinedString);
    setTimearray([...timeArray, combinedString]);
    setShowTimeInput(false);
  };
  const handleScheduleDelete = (item) => {
    setTimearray(timeArray.filter((ele) => ele !== item));
  };
  console.log("time array", timeArray);

  const multipleTemplate = async (camid) => {
    const newArr = [];
    console.log("timearry length", timeArray.length);

    if (timeArray.length === 0) {
      const combinedString = `${from} ${to}`;
      newArr.push(combinedString);
      console.log("newArr", newArr);
    }
    const passData = {
      templateName: localStorage.getItem("campaigntemplatename"),
      countryCode: "91",
      templateId: localStorage.getItem("campaigntemplateid"),
      campaignId: camid,
      companyId: localStorage.getItem("token1"),
      time: timeArray.length === 0 ? newArr : timeArray,
    };
    console.log(" multiple", passData);
    try {
      const response = await fetch(`${backendUrl}/whatsapp/multipletemplate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passData),
      });
      console.log("response", response);

      if (response.ok) {
      } else {
        console.error("multiple template failed ");
      }
    } catch (error) {
      console.error("Error during multiple template:", error);
    }
  };

  return (
    <>
      <Drawer />
      <div className={style.csmainOuter}>
        <div className={style.csOuter}>
          <div
            className={style.csheading}
            onClick={() => navigate("/manualtemplate")}
          >
            <img className={style.csarrow} src={arrow} alt="img" />
          </div>
          <div className={style.csMiddle}>
            <div className={style.csmidtop}>Campaign Schedule</div>
            <div className={style.csborderline}></div>
            <div className={style.csmidbottom}>
              <div className={style.csmidfirst}>
                <div className={style.csmyschedule}>
                  <img className={style.cscalendar} src={calendar} alt="img" />
                  My Schedule 1
                </div>
                <div className={style.csESouter}>
                  {timeArray &&
                    timeArray.map((item) => {
                      return (
                        <div className={style.csES}>
                          <label className={style.cslabel}>
                            <img
                              style={{ width: "20%" }}
                              className={style.cscalendar}
                              src={calendar}
                              alt="img"
                            />
                            Start |
                          </label>
                          <div className={style.csstarttime}>{item}</div>
                          <div onClick={() => handleScheduleDelete(item)}>
                            Delete
                          </div>
                        </div>
                      );
                      // <div className={style.csES}>
                      //     <label className={style.cslabel}><img style={{ width: "20%" }} className={style.cscalendar} src={calendar} alt='img' />End  |</label>
                      //     <div className={style.csstarttime}>No end date</div>
                      // </div>
                    })}
                </div>
                <div
                  className={style.csaddschedule}
                  onClick={() => {
                    setShowTimeInput(true);
                    setFrom();
                    setTo();
                  }}
                >
                  + Add Schedule
                </div>
              </div>
              <div className={style.csmidsecond}>
                <div className={style.csMSnameheading}>
                  <div className={style.cslabeldiv}>Schedule Name</div>
                  <div className={style.csMSname}>My Schedule 1</div>
                </div>
                {showtimeInput && (
                  <div className={style.cstimingmainOuter}>
                    <div className={style.cslabeldiv}>Timing</div>
                    <div className={style.cstimingOuter}>
                      <div className={style.csbox}>
                        <div className={style.csfadetext}>Date</div>
                        <label htmlFor="calendarInput">Select Date:</label>
                        <DatePicker
                          id="calendarInput"
                          value={from}
                          minDate={new Date()}
                          required
                          onChange={(date) => {
                            console.log(date);
                            const formattedDate = date.toLocaleDateString(
                              "en-US",
                              {
                                year: "2-digit",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            );

                            console.log("formatted", formattedDate);
                            setFrom(formattedDate);
                          }}
                          dateFormat="MM/dd/yy"
                          placeholderText="mm/dd/yy"
                          showTimeInput={false}
                        />
                      </div>
                      <div className={style.csbox}>
                        <div className={style.csfadetext}>Time</div>
                        <div className={style.cstimetext}>
                          <img className={style.csclock} src={clock} alt="img" />
                          <div style={{width:"50px"}}>
                            <select style={{width:"100%"}} value={hours} onChange={handleHourChange}>
                              {/* Assuming 12-hour format, adjust the loop if you want 24-hour format */}
                              {[...Array(12).keys()].map((hour) => (
                                <option key={hour + 1} value={String(hour + 1).padStart(2, '0')}>
                                  {String(hour + 1).padStart(2, '0')}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div style={{width:"50px"}}>
                            <select style={{width:"100%"}} value={minutes} onChange={handleMinuteChange}>
                              {[...Array(60).keys()].map((minute) => (
                                <option key={minute} value={String(minute).padStart(2, '0')}>
                                  {String(minute).padStart(2, '0')}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div style={{width:"50px"}}>
                            <select style={{width:"100%"}} value={period} onChange={handlePeriodChange}>
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div
                        className={style.csnextbtn}
                        onClick={() => handleAddSchedule()}
                      >
                        Save
                      </div>

                      {/* <div className={style.csbox}>
                                            <div className={style.csfadetext}>Time Zone</div>
                                            <div className={style.cstimetext}>IST (Indian Standard Time)(GMT+5:30)</div>
                                        </div> */}
                    </div>
                  </div>
                )}
                {/*<div className={style.csdaysouter}>
                                    <div className={style.csdaysheading}>Days</div>
                                    <div className={style.csdays}>

                                        <div className={style.csday}>
                                            <input className={style.csdayinput} type='checkbox' />
                                            <div className={style.csdayname}>Monday</div>
                                        </div>
                                        <div className={style.csday}>
                                            <input className={style.csdayinput} type='checkbox' />
                                            <div className={style.csdayname}>Tuesday</div>
                                        </div>
                                        <div className={style.csday}>
                                            <input className={style.csdayinput} type='checkbox' />
                                            <div className={style.csdayname}>Wednesday</div>
                                        </div>
                                        <div className={style.csday}>
                                            <input className={style.csdayinput} type='checkbox' />
                                            <div className={style.csdayname}>Thursday</div>
                                        </div>
                                        <div className={style.csday}>
                                            <input className={style.csdayinput} type='checkbox' />
                                            <div className={style.csdayname}>Friday</div>
                                        </div>
                                        <div className={style.csday}>
                                            <input className={style.csdayinput} type='checkbox' />
                                            <div className={style.csdayname}>Saturday</div>
                                        </div>
                                        <div className={style.csday}>
                                            <input className={style.csdayinput} type='checkbox' />
                                            <div className={style.csdayname}>Sunday</div>
                                        </div>
                                    </div>
    </div>*/}
                <div className={style.csnext}>
                  <button
                    type="button"
                    className={style.csnextbtn}
                    onClick={edit ? handleEdit : handleSave}
                  // onClick={() => console.log("clicked")}
                  //disabled={!activeSaveButton}
                  >
                    Save & Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CampaignSchedule;
