import React, { useEffect, useState } from "react";
import style from "../../CustomerManagement/AddCustomer/AddCustomer.module.css";
import arrow from "../../../Assets/arrow.png";
import Drawer from "../../Drawer/Drawer";
import { useNavigate, useParams } from "react-router-dom";
import backendUrl from "../../../config";
import toast from "react-hot-toast";

const EditAgent = () => {
  const [mylist, setMylist] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [num, setNum] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  const [errormsg, setErrormsg] = useState("");

  useEffect(() => {
    const id1 = localStorage.getItem("token");
    console.log("id", id1);
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendUrl}/agent/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("response", response);
        const responseData = await response.json();
        console.log("responseData", responseData);

        if (response.ok) {
          setFields(responseData);
        } else {
          setErrormsg(responseData.message);
          console.error("Did not receive the  agent ");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);

  const handlename = (e) => {
    setName(e.target.value);
  };
  const handleemail = (e) => {
    setEmail(e.target.value);
  };
  const handlephone = (e) => {
    setNum(e.target.value);
  };

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    num: "",
  });

  const validateForm = () => {
    let valid = true;

    // Name validation
    if (name.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "",
      }));
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    // Mobile number validation
    const numRegex = /^\d{10}$/;
    if (!numRegex.test(num)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        num: "Invalid mobile number",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        num: "",
      }));
    }
    return valid;
  };

  const handleaddclick = async (e) => {
    e.preventDefault();
    const passData = {
      name: name,
      email: email,
      phoneNumber: "91" + num,
    };
    console.log("passData", passData);
    if (validateForm()) {
      try {
        const response = await fetch(`${backendUrl}/agent/update/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(passData),
        });
        console.log("response", response);
        /*const responseData = await response.json();
            console.log(" true responseData",responseData);*/
        if (response.ok) {
          const responseData = await response.json();
          console.log(" true responseData", responseData);
          navigate(`/agent/${localStorage.getItem("token")}`);
          toast.success("Agent detail updated");
        } else {
          console.error("Agent not updated ");
          toast.error("Agent not updated ");
        }
      } catch (error) {
        console.error("Error during updation:", error);
      }
    }
  };
  const setFields = (data) => {
    setName(data.name);
    setEmail(data.email);
    setNum(data.phoneNumber.slice(2));
  };

  return (
    <>
      <Drawer />
      <div className={style.profileOuter}>
        <div className={style.addOuter}>
          <p>{errormsg}</p>
          <div
            className={style.addHeading}
            onClick={() => navigate(`/agent/${localStorage.getItem("token")}`)}
          >
            <img src={arrow} alt="img" />
            <div className={style.addHeadingtext}>Edit Agent</div>
          </div>
          <div className={style.addFields}>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Agent Name</label>
              <input
                className={style.addinput}
                name="name"
                value={name}
                type="text"
                onChange={handlename}
              />
              {errors?.name ? (
                <span className="error" style={{ color: "red" }}>
                  {errors.name}
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Agent's email address</label>
              <input
                className={style.addinput}
                name="email"
                value={email}
                type="text"
                onChange={handleemail}
              />
              {errors?.email ? (
                <span className="error" style={{ color: "red" }}>
                  {errors.email}
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Phone Number</label>
              <div className={style.addphoneinput}>
                <div className={style.addnum}>+91</div>
                <input
                  className={style.addinput}
                  name="num"
                  value={num}
                  style={{ width: "55%" }}
                  type="text"
                  onChange={handlephone}
                />
              </div>
              {errors?.num ? (
                <span className="error" style={{ color: "red" }}>
                  {errors.num}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
          <p>{errormsg}</p>

          <div className={style.addButtons}>
            <div
              className={style.addcancelbtn}
              onClick={() =>
                navigate(`/agent/${localStorage.getItem("token")}`)
              }
            >
              Cancel
            </div>
            <div className={style.addaddbtn} onClick={handleaddclick}>
              Edit
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAgent;
