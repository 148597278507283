import React, { useEffect, useState } from "react";
import "./AiAgentsDetails.css";
import Drawer from "../../Drawer/Drawer";
import { FaRobot } from "react-icons/fa";
import { IoPencil } from "react-icons/io5";
import Accordion from "./Accordion/Accordion";
import backendUrl from "../../../config";
import { NavLink } from "react-router-dom";
import editimage from "../../../Assets/edit1.png";
import deleteimage from "../../../Assets/delete1.png";
import toast from "react-hot-toast";


const AiAgentDetails = () => {
  const [summary, setSummary] = useState();
  const [services, setServices] = useState();
  const [faq, setFaq] = useState([]);
  const [custfaq, setCustfaq] = useState([]);
  const [ques, setQues] = useState("");
  const [ans, setAns] = useState("");
  const [addfaq, setaddfaq] = useState(false);
  const [editfaq, seteditfaq] = useState(false);
  const [faqid, setfaqid] = useState(false);
  const [loading,setLoading]=useState(false)
  console.log(services);

  
useEffect(() => {
  if(localStorage.getItem('ques')){
    setaddfaq(true)
    setQues(localStorage.getItem('ques'))
  }
},[]);

const apiCalls = async () => {
  try {
    console.log('apiCalls')
    setLoading(true)
    const response = await fetch(
      `${backendUrl}/company/storeData/${localStorage.getItem("token1")}`
    );
    const data  = await response.json();

    if (response.ok) {
      if(data.code === 'wait'){
        toast.error('Please wait for the data to be processed')
        console.log('wait')
      }
      else{
        window.location.reload();
        toast.success('Data has been processed')
        console.log('data',data)
      }
      
    } else {
      console.error("Fetching failed");
    }
    setLoading(false)
  } catch (error) {
    console.error("Error during Fetching:", error);
  }
};

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${backendUrl}/company/${localStorage.getItem("token1")}`
      );
      const { data } = await response.json();

      if (response) {
        console.log("data", data);
        setSummary(data.botInfo.summary);
        setServices(data.botInfo.services);
        setFaq(data.botInfo.faq);
        let customFaq = data.botInfo.customFaq;
        console.log("custom", customFaq);
        setCustfaq(customFaq);
        // console.log(data);
        // console.log(data.botInfo.services);
        // console.log(faq);
        console.log(services);
      } else {
        console.error("Fetching failed");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // summary apis

  const handleAddFaq = async () => {
    try {
      const passData = {
        question: ques,
        answer: ans,
        companyId: localStorage.getItem("token1"),
      };
      console.log("pass", passData);
      const response = await fetch(`${backendUrl}/faq`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passData),
      });
      console.log("responseData", await response.json());
      if (response.ok) {
        setaddfaq(false);
        localStorage.removeItem('ques')
        window.location.reload();
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleEditFaq = async () => {
    try {
      const passData = {
        question: ques,
        answer: ans,
        companyId: localStorage.getItem("token1"),
      };
      console.log("pass", passData);
      const response = await fetch(`${backendUrl}/faq/${faqid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passData),
      });
      console.log("responseData", await response.json());
      if (response.ok) {
        seteditfaq(false);
        
        window.location.reload();
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleDeleteFaq = async (item) => {
    try {
      const passData = {
        companyId: localStorage.getItem("token1"),
      };
      console.log("pass", passData);
      const response = await fetch(`${backendUrl}/faq/${item.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passData),
      });
      console.log("responseData", await response.json());
      if (response.ok) {
        window.location.reload();
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  return (
    <>
      <Drawer />
      <div className="profileOuter">
        <div className="detOuter">
          <div className="agent-head-container">
            <h1>
              <FaRobot
                style={{
                  color: "#34b7f1",
                  marginTop: "5px",
                  marginRight: "7px",
                }}
              />
              AI Agents Delails
            </h1>
            <button onClick={apiCalls} disabled={loading}>
              <IoPencil
                style={{
                  marginTop: "5px",
                  marginRight: "7px",
                }}
              />
              {loading?'fetching details':'fetch details'}
            </button>
          </div>

          <div className="agent-link-bar">
            <NavLink to="/companyprofile" style={{ textDecoration: "none" }}>
              <span>Agent Details</span>
            </NavLink>
            <NavLink to="/document" style={{ textDecoration: "none" }}>
              <span>Agent Training</span>
            </NavLink>
            <NavLink to="/aiagentdetails" style={{ textDecoration: "none" }}>
              <span>FAQs & Services</span>
            </NavLink>
          </div>

          <div className="DocumentAnalysis-container">
            <div className="head-container">
              <h2>Document Analysis</h2>
              <p>
                We have analyzed your documents and generated it’s summary, FAQs
                and services offered.
              </p>
            </div>

            <div className="summary-container">
              <h3>Summary</h3>

              <p>{summary ? summary : "No summary"}</p>
            </div>

            <div className="services-container">
              <h3>Products & Services offered:</h3>

              {<BulletPointList text={services} />}
            </div>

            <div className="faqs-container">
              <div className="inner1-faq">
                <h3>FAQs</h3>

                {faq
                  ? faq.map((element, index) => (
                      <Accordion
                        key={index}
                        title={element.Question}
                        content={element.Ans}
                      />
                    ))
                  : "No Facts"}
              </div>
              <div className="inner2-faq">
                <h3>Custom FAQs</h3>
              <p onClick={() => {setaddfaq(!addfaq);setAns('');setQues('')}}>Add Faq</p>
                {(addfaq || editfaq) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <label>Question</label>
                      <input
                        style={{ borderRadius: "15px" }}
                        type="text"
                        value={ques}
                        onChange={(text) => setQues(text.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <label> Answer</label>
                      <input
                        style={{ borderRadius: "15px" }}
                        type="text"
                        value={ans}
                        onChange={(text) => setAns(text.target.value)}
                      />
                    </div>
                    {addfaq && (
                      <button onClick={() => handleAddFaq()}>Add</button>
                    )}
                    {editfaq && (
                      <button onClick={() => handleEditFaq()}>Edit</button>
                    )}
                  </div>
                )}
                {custfaq?.map((element, index) => (
                  <div
                    style={{
                      display: "Flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Accordion
                      key={index}
                      title={element.Question}
                      content={element.Ans}
                      style={{ width: "80%" }}
                    />
                    <img
                      src={editimage}
                      alt="img"
                      onClick={() => {
                        setAns(element.Ans);
                        setQues(element.Question);
                        seteditfaq(true);
                        setfaqid(element.id);
                      }}
                    />
                    <img
                      onClick={() => handleDeleteFaq(element)}
                      src={deleteimage}
                      alt="img"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BulletPointList = ({ text }) => {
  if (!text) {
    return "NoServices";
  }
  const points = text.split("-").slice(1);
  

  return (
    <ul>
      {points.map((point, index) => (
        <li key={index}>{point.replace("-", "")}</li>
      ))}
    </ul>
  );
};

export default AiAgentDetails;
