import React, { useState } from "react";
import "./index.css";
import { MdOutlineBackup } from "react-icons/md";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import UploadIcon from "@mui/icons-material/Upload";
import { GrDocumentPdf } from "react-icons/gr";
import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Pdfpriview from "./Pdfpriview";
import Drawer from "../Drawer/Drawer";
import backendUrl from "../../config";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Dropbox = () => {
  const [file, setFile] = useState();
  const [isUploaded, setIsUPloaded] = useState(false);
  const [isViewer, setIsViewer] = useState(false);
  const [showPriview, setShowPriview] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [summaryUploaded, setSummaryUploaded] = useState(false);
  const [servicesUploaded, setServicesUploaded] = useState(false);
  const [faqUploaded, setFaqUploaded] = useState(false);
  

  const handleFileChange = async(event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
      setUploadProgress(0);
      setIsUPloaded(true);
// Simulate upload progress (for demonstration purposes)
const interval = setInterval(() => {
        setUploadProgress((prevProgress) =>
          prevProgress < 100 ? prevProgress + 10 : 100
        );
      }, 500);

      // Simulate file upload completion
      setTimeout(() => {
        clearInterval(interval);
        setUploadProgress(100);
        console.log(file);
      }, 5000);

      
   }
  }; 

  function handleUpload() {
    setIsUPloaded(true);
    setIsViewer(true);
    setShowPriview(true);
  }

  const handlePreviewClick = () => {
    setShowPriview(true);
    console.log(file);
  };



  return (
    <>
    <Drawer/>
    <div className="box-container">
      <h1 className="upload">Upload Your Company Documents</h1>
      <p className="lorem">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores iure
        culpa reiciendis, totam <br />
        Lorem ipsum dolor sit amet consectetur, adipisicing elit.Maiores iure
        culpa reiciendis, totam
      </p>
      <div className="drop-box">
        <MdOutlineBackup className="drop-logo" />
        <p className="drag">Drag & deop your file here</p>
        <h3 className="od">OR</h3>
        <Button
          component="label"
          className="Browser-btn"
          variant="contained"
          startIcon={<UploadIcon />}
        >
          Browser
          <VisuallyHiddenInput
            type="file"
            accept=".pdf, .doc, .docx"
            name="file"
            onChange={handleFileChange}
          />
        </Button>
        <p className="p1">
          Max File size:<b>50MB</b>
        </p>
        <p className="p2">
          Supported file formats:<b>PDF,DOC</b>
        </p>
      </div>
      {isUploaded && (
        <div>
          <div className="pdf-box">
            <GrDocumentPdf className="pdf-icons" />
            <Box className="loder">
              <BorderLinearProgress
                variant="determinate"
                value={uploadProgress}
              />
              <div onClick={handlePreviewClick}>Preview</div>
            </Box>
          </div>
          <div className="pdf-box">
            <GrDocumentPdf className="pdf-icons" />
            <Box className="loder">
              <BorderLinearProgress variant="determinate" value={50} />
            </Box>
          </div>
        </div>
      )}
      {/* Conditionally render PDF preview */}
      {showPriview && (
        <Pdfpriview
          file={file} // Pass the file to the PDF preview component
          setShowPriview={setShowPriview} // Handle closing the preview
        />
      )}
    </div>
    </>
  );
};

export default Dropbox;
