import React, { useState } from "react";
import style from "./AddCustomer.module.css";
import arrow from "../../../Assets/arrow.png";
import Drawer from "../../Drawer/Drawer";
import { useNavigate } from "react-router-dom";
import backendUrl from "../../../config";
import toast from "react-hot-toast";

const AddCustomer = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [num, setNum] = useState("");
	const navigate = useNavigate();
	const [errors, setErrors] = useState({
		name: "",
		num: "",
	});

	const validateForm = () => {
		let valid = true;

		// Name validation
		if (name.trim() === "") {
			setErrors((prevErrors) => ({
				...prevErrors,
				name: "Name is required",
			}));
			valid = false;
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				name: "",
			}));
		}

		// Email validation
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(email)) {
			setErrors((prevErrors) => ({
				...prevErrors,
				email: "Invalid email address",
			}));
			valid = false;
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				email: "",
			}));
		}

		// Mobile number validation
		const numRegex = /^\d{10}$/;
		if (!numRegex.test(num)) {
			setErrors((prevErrors) => ({
				...prevErrors,
				num: "Invalid mobile number",
			}));
			valid = false;
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				num: "",
			}));
		}

		return valid;
	};

	const handleAdd = async (e) => {
		e.preventDefault();
		const passdata = {
			name: name,
			email: email,
			companyId: localStorage.getItem("token1"),
			phone: "91" + String(num),
		};
		if (validateForm()) {
			try {
				const response = await fetch(`${backendUrl}/customer`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(passdata),
				});
				console.log("response", response);

				if (response.ok) {
					navigate("/customer");
					toast.success("Customer Added");
				} else {
					console.error("Add Customer failed");
					toast.error("Add Customer failed");
				}
			} catch (error) {
				console.error("Error during Add customer:", error);
			}
		}
	};
	const resetfields = () => {
		setName("");
		setEmail("");
		setNum("");
	};

	return (
		<>
			<Drawer />
			<div className={style.profileOuter}>
				<div className={style.addOuter}>
					<div
						className={style.addHeading}
						onClick={() => navigate("/customer")}
					>
						<img src={arrow} alt="img" />
						<div className={style.addHeadingtext}>
							Add Customer{" "}
						</div>
					</div>
					<div className={style.addFields}>
						<div className={style.addonefield}>
							<label className={style.addlabel}>
								Customer Name
							</label>
							<input
								className={style.addinput}
								name="name"
								value={name}
								type="text"
								onChange={(e) => setName(e.target.value)}
							/>
							{errors?.name ? (
								<span style={{ color: "red" }}>
									{errors.name}
								</span>
							) : (
								<></>
							)}
						</div>
						<div className={style.addonefield}>
							<label className={style.addlabel}>
								Customer's email address
							</label>
							<input
								className={style.addinput}
								name="email"
								type="text"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className={style.addonefield}>
							<label className={style.addlabel}>
								Phone Number
							</label>
							<div className={style.addphoneinput}>
								<div className={style.addnum}>+91</div>
								<input
									className={style.addinput}
									name="num"
									style={{ width: "55%" }}
									type="text"
									onChange={(e) => setNum(e.target.value)}
								/>
							</div>
							{errors?.num ? (
								<span style={{ color: "red" }}>
									{errors.num}
								</span>
							) : (
								<></>
							)}
						</div>
					</div>
					<div className={style.addButtons}>
						<div
							className={style.addcancelbtn}
							onClick={() => navigate("/customer")}
						>
							Cancel
						</div>
						<div className={style.addaddbtn} onClick={handleAdd}>
							Add
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddCustomer;
