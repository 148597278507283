import React, { useEffect, useState } from "react";
import style from "./EditGroup.module.css";
import arrow from "../../Assets/arrow.png";
import { useNavigate, useParams } from "react-router-dom";
import Drawer from "../Drawer/Drawer";
import backendUrl from "../../config";
import toast from "react-hot-toast";

const AddmoreModal = ({ closePopup, customers, setCustomers }) => {
  const [datalist, setDatalist] = useState({});
  const [newCustomers, setNewCustomer] = useState(customers);

  const [error, setError] = useState("");

  const validateForm = () => {
    let valid = true;

    // Name validation
    if (newCustomers.length > 1) {
      setError("Minimum 2 customer is required");
      valid = false;
    }

    return valid;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/customer/all/${localStorage.getItem("token1")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log("customer response data", responseData);

        if (response.ok) {
          setDatalist(responseData.customers);
        } else {
          console.error("Fetching failed");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);

  const handleAddMore = async () => {
    setCustomers(newCustomers);
    closePopup();
  };
  // if (validateForm()) {
  const handleCheckbox = (value) => {
    if (newCustomers.includes(value)) {
      // If the customer is already selected, remove it from the list
      setNewCustomer(newCustomers.filter((item) => item !== value));
    } else {
      // If the customer is not selected, add it to the list
      setNewCustomer([...newCustomers, value]);
      // }
    }
  };
  console.log("newCustomer", newCustomers);

  // ...

  return (
    <div className={style.modalOuter}>
      {error ? <span style={{ color: "red" }}>{error}</span> : <></>}
      {Object.values(datalist).map((item) => (
        <div className={style.egone} key={item.id}>
          <input
            type="checkbox"
            checked={newCustomers.includes(item.comid)} // Use 'checked' instead of 'value'
            onChange={() => handleCheckbox(item.comid)} // Use 'onChange' instead of 'onClick'
          />
          <div className={style.egcontent}>
            <div className={style.egname}>{item.name}</div>
            <div className={style.eginfo}>
              <div className={style.egsmalldata}>{item.phone}</div>
            </div>
          </div>
        </div>
      ))}
      <div className={style.groupButtonOuter}>
        <div className={style.modalcancel} onClick={closePopup}>
          Cancel
        </div>
        <div className={style.modaldelete} onClick={handleAddMore}>
          Add
        </div>
      </div>
    </div>
  );
};

const EditGroup = () => {
  const [mylist, setMylist] = useState({});
  const [name, setName] = useState("");
  const [customers, setCustomers] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  console.log("id", id);
  const [errormsg, setErrormsg] = useState("");
  const [addmore, setAddmore] = useState(false);
  const [error, setError] = useState("");

  const validateForm = () => {
    let valid = true;

    // Name validation
    if (name.trim() === "") {
      setError("Name is required");
      valid = false;
    } else {
      setError("");
    }

    return valid;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendUrl}/group/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("response", response);

        if (response.ok) {
          const responseData = await response.json();
          console.log("responseData", responseData);

          setFields(responseData);
        } else {
          console.error("Did not receive the  agent ");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);

  const handlename = (e) => {
    setName(e.target.value);
  };
  const handlecustomers = (e) => {
    setCustomers(e.target.value);
  };

  const handleaddclick = async (e) => {
    e.preventDefault();
    const passData = {
      groupName: name,
      customers: customers,
    };
    console.log("passData", passData);
    if (validateForm()) {
      try {
        const response = await fetch(`${backendUrl}/group/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(passData),
        });
        console.log("response", response);
        /*const responseData = await response.json();
            console.log(" true responseData",responseData);*/
        if (response.ok) {
          const responseData = await response.json();
          console.log(" true responseData", responseData);
          navigate(`/customer`);
          toast.success("Updated");
        } else {
          console.error("Agent not updated ");
          toast.error("Not updated ");
        }
      } catch (error) {
        console.error("Error during updation:", error);
      }
    }
  };
  const setFields = (data) => {
    setName(data.groupName);
    setCustomers(data.customers);
  };
  const closePopup = () => {
    setAddmore(false);
  };

  return (
    <>
      <Drawer />
      {addmore && (
        <div className={style.popup}>
          <div className={style.popup_inner}>
            <AddmoreModal
              closePopup={closePopup}
              customers={customers}
              setCustomers={setCustomers}
            />
          </div>
        </div>
      )}
      <div className={style.profileOuter}>
        <div className={style.addOuter}>
          <p>{errormsg}</p>
          <div className={style.addHeading} onClick={()=>navigate("/customer")}>
            <img src={arrow} alt="img" />
            <div className={style.addHeadingtext}>Edit Group</div>
          </div>
          <div className={style.addFields}>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Group Name</label>
              <input
                className={style.addinput}
                name="name"
                value={name}
                type="text"
                onChange={handlename}
              />
              {error ? <span style={{ color: "red" }}>{error}</span> : <></>}
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Customers</label>
              <input className={style.addinput} value={customers} disbaled />
              <label
                style={{ fontSize: "12px", cursor: "pointer" }}
                onClick={() => setAddmore(true)}
              >
                Add More
              </label>
            </div>
          </div>

          <div className={style.addButtons}>
            <div
              className={style.addcancelbtn}
              onClick={() => navigate("/customer")}
            >
              Cancel
            </div>
            <div className={style.addaddbtn} onClick={handleaddclick}>
              Edit
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditGroup;
