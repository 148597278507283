import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PropTypes from "prop-types";
import "./pdfpriview.css"

const Pdfpriview = ({file,setShowPriview}) => {
  const [numPages, setNumPages] = useState(null);
  

  useEffect(() => {
    // Set the worker source when the component mounts
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

    // Cleanup the worker source when the component unmounts
    return () => {
      pdfjs.GlobalWorkerOptions.workerSrc = null;
    };
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (<>
    <Document
    className="documentpdf"
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
    <button onClick={()=>setShowPriview(false)}>Close</button>
    </>
  );
};

Pdfpriview.propTypes = {
  file: PropTypes.string.isRequired,
  setShowPriview: PropTypes.func.isRequired,
};

export default Pdfpriview;
