import React, { useEffect, useState } from 'react'
import backendUrl from '../../config'
import Drawer from '../Drawer/Drawer'
import style from "../CustomerManagement/CustomerDashboard/CustomerDashboard.module.css";
import bellimage from "../../Assets/bell-regular.svg"

const Notifications = () => {
    const [notifications,setNotifications] = useState([])

    const flipNotifications = (notifications) => {
        return notifications.reverse()
    }

    useEffect(()=>{
        try {
            fetch(`${backendUrl}/company/${localStorage.getItem('token1')}`).then(res=>res.json()).then(data=>setNotifications(data.data.notifications?flipNotifications(data.data.notifications):[]))
            
        } catch (error) {
            console.log("Error in fetching company data",error)
        }
    },[])

    const deleteNotification = async () => {
        try {
            const response = await fetch(`${backendUrl}/company/update`,{
                method:"PUT",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({notifications:[],adminId:localStorage.getItem('token'),companyId:localStorage.getItem('token1')})
            })
            setNotifications([])
        } catch (error) {
            console.log("Error in deleting notification",error)
        }
    }

    return (
        <>
        <Drawer />
        <div className={style.noti_outer}>
            <div className={style.cdheading1}>
                <img src={bellimage} alt="bell" />
                Notifications
            </div>
            <div className={style.cdbottom}>
            {notifications.map((notification,index)=>{
                return(
                    <div key={index} className={style.cdone}>
                        <div className={style.cdname}>{notification.title}</div>
                        <div className={style.cdsmalldata}>{notification.body}</div>
                    </div>
                )
            })}
            <button className={style.noti_btn} onClick={()=>deleteNotification()}>Clear</button>
            </div>
        </div>
        </>
    )
}

export default Notifications