import * as React from 'react';
import Button from '@mui/material/Button';
import "./Token.css"
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Drawer  from '../Drawer/Drawer';
// import "./pdfpriview.css"
// import Dropbox from './Dropbox';
import { PiFilePdf } from 'react-icons/pi';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

 function Token({props}) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const myFunction =()=>{
    // setPdfprivew(false)
  }
  return (
    <div className='box'>
      <div>
      <Drawer/>
      </div>
    <React.Fragment>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className='heading'>
        <h3>Link Generated !</h3> 
        </div>
          <Typography gutterBottom>
          <div className='yourlink'><h3 >Your Link</h3></div>
            <div className='c2'>
            <input
            className='input'
              type='password'
              name='password'
              // value={formData.password}
              // onChange={handleInputChange}
              placeholder='  57456789tb=is&@abc'           
            />
            <ContentCopyIcon></ContentCopyIcon>
            </div>
             
          </Typography>
        <DialogActions>
        <Button variant="contained"
        className='btn01' 
         style={{borderRadius:"20px",  marginRight:"200px", paddingLeft:"30px",paddingRight:"30px"}}>Copy Link</Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
    </div>
  );
}

export default Token;
