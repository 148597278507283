import React, { useEffect, useState } from "react";
import style from "../CustomerManagement/AddCustomer/AddCustomer.module.css";
import arrow from "../../Assets/arrow.png";
import { useNavigate, useParams } from "react-router-dom";
import eye from "../../Assets/eye.png";
import Drawer from "../Drawer/Drawer";
import backendUrl from "../../config";
import toast from "react-hot-toast";

const UpdatePass = () => {
  const [old, setold] = useState("");
  const [newpass, setNewpass] = useState("");
  const [cpass, setCpass] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [mydata, setMydata] = useState();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handleTogglePassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/admin/${localStorage.getItem("token")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response", response);

        if (response.ok) {
          const responseData = await response.json();
          console.log("responseData", responseData);
          setMydata(responseData);
        } else {
          console.error("Did not receive the  profile info ");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);

  const handleold = (e) => {
    setold(e.target.value);
  };
  const handlenewpass = (e) => {
    setNewpass(e.target.value);
  };
  const handlecpass = (e) => {
    setCpass(e.target.value);
  };

  const handleUpdateClick = async (e) => {
    e.preventDefault();
    console.log(mydata);
    //name, email, password, phoneNumber, whatsappNumber, webhookSecret, companyName
    const passData = {
      name: mydata.name,
      email: mydata.email,
      phoneNumber: mydata.phoneNumber,
      password: newpass,
      whatsappNumber: mydata.whatsappNumber,
      webhookSecret: mydata.webhookSecret,
      companyName: mydata.companyName,
    };
    console.log("passData", passData);
    console.log(errors);
    if (validateForm()) {
      try {
        const response = await fetch(
          `${backendUrl}/admin/update/${localStorage.getItem("token")}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(passData),
          }
        );
        console.log("response", response);
        /*const responseData = await response.json();
            console.log(" true responseData",responseData);*/
        if (response.ok) {
          navigate(`/profile`);
          toast.success("Password is updated");
        } else {
          console.error("Admin info not updated ");
          toast.error("Not updated");
        }
      } catch (error) {
        console.error("Error during updation:", error);
      }
    }
  };

  const [errors, setErrors] = useState({
    old: "",
    newpass: "",
    cpass: "",
  });

  const validateForm = () => {
    let valid = true;

    // Old Password validation
    if (old.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        old: "Old Password is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        old: "",
      }));
    }

    // New Password validation
    if (newpass.length < 8) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newpass: "New Password must be at least 8 characters long",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newpass: "",
      }));
    }

    // Confirm Password validation
    if (cpass !== newpass) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        cpass: "Passwords do not match",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        cpass: "",
      }));
    }

    return valid;
  };

  return (
    <>
      <Drawer />
      <div className={style.profileOuter}>
        <div className={style.addOuter}>
          <p>{errormsg}</p>
          <div className={style.addHeading} onClick={()=>navigate("/profile")}>
            <img src={arrow} alt="img" />
            <div className={style.addHeadingtext}>Update Password</div>
          </div>
          <div className={style.addFields}>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Old Password</label>
              <div className="WrapperIB">
                <input
                  className={style.addinput}
                  name="old"
                  value={old}
                  type={showPassword ? "text" : "password"}
                  onChange={handleold}
                />
                <button type="button" onClick={handleTogglePassword}>
                  <img src={eye} alt="img" />
                </button>
              </div>
              {errors?.old ? (
                <span style={{ color: "red" }}>{errors.old}</span>
              ) : (
                <></>
              )}
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>New Password</label>
              <div className="WrapperIB">
                <input
                  className={style.addinput}
                  name="newpass"
                  value={newpass}
                  type={showPassword1 ? "text" : "password"}
                  onChange={handlenewpass}
                />
                <button type="button" onClick={handleTogglePassword1}>
                  <img src={eye} alt="img" />
                </button>
              </div>
              {errors?.newpass ? (
                <span style={{ color: "red" }}>{errors.newpass}</span>
              ) : (
                <></>
              )}
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Confirm Pasword</label>
              <div className="WrapperIB">
                <input
                  className={style.addinput}
                  name="Cpass"
                  type={showPassword2 ? "text" : "password"}
                  onChange={handlecpass}
                />
                <button type="button" onClick={handleTogglePassword2}>
                  <img src={eye} alt="img" />
                </button>
              </div>
              {errors?.cpass ? (
                <span style={{ color: "red" }}>{errors.cpass}</span>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className={style.addButtons}>
            <div
              className={style.addcancelbtn}
              onClick={() => navigate("/profile")}
            >
              Cancel
            </div>
            <div className={style.addaddbtn} onClick={handleUpdateClick}>
              Update
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePass;
