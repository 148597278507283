import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./index.css";
import img from "../../Assets/img.jpg";
import { FaGoogle } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import eye from "../../Assets/eye.png";
import backendUrl from "../../config";
import toast from "react-hot-toast";

const LoginSignup = ({ setLoginForm }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    whatsappNumber: "",
    password: "",
    bussinessId:"",
    webhookSecret: "", // default value
    companyName: "",
    phoneNumberId:"",
  });
  const [errors, setErrors] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    whatsappNumber: "",
    password: "",
    bussinessId:"",
    webhookSecret: "",
    companyName: "",
    phoneNumberId:"",
  });

  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem("token") !== null;

    if (isUserLoggedIn) {
      navigate("/dashboard");
    }
  }, [navigate]);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  // validations
  const validateForm = () => {
    let valid = true;

    // Name validation
    /*if (formData.name.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "",
      }));
    }*/

    if(formData.phoneNumberId.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumberId: "phoneNumberId is required",
      }));
      valid = false;
    }
    else{
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumberId: "",
      }));
    }

    if (formData.companyName.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyName: "Name is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyName: "",
      }));
    }
    if (formData.bussinessId.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bussinessId: "BussinessId is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bussinessId: "",
      }));
    }

    // webhookSecret validation
    if (formData.webhookSecret.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        webhookSecret: "WebhookSecret is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        webhookSecret: "",
      }));
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    // Mobile number validation
    /* const mobileRegex = /^\d{10}$/;
    if (!mobileRegex.test(formData.phoneNumber)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Invalid mobile number",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "",
      }));
    }*/

    // Whatsapp number validation
    const whatsapp = /^\d{10}$/;
    if (!whatsapp.test(formData.whatsappNumber)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        whatsappNumber: "Invalid mobile number",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        whatsappNumber: "",
      }));
    }

    // Password validation
    if (formData.password.length < 8) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 8 characters long",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }

    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setFormData({ ...formData, [name]: value });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    // console.log((formData.phoneNumber = formData.whatsappNumber));
    // setFormData((formData.phoneNumber = formData.whatsappNumber));

    console.log(validateForm());
    if (validateForm()) {
      try {
        const passData = {
          name: formData.name,
          phoneNumber: formData.whatsappNumber,
          email: formData.email,
          whatsappNumber: formData.whatsappNumber,
          password: formData.password,
          webhookSecret: formData.webhookSecret,
          companyName: formData.companyName,
          bussinessId:formData.bussinessId,
          phoneNumberId:formData.phoneNumberId,
        };
        console.log(passData);
        const response = await fetch(`${backendUrl}/auth/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(passData),
        });
        const responseData = await response.json();
        console.log(responseData);

        if (response.ok) {
          console.log(response);
          // Redirect to login page upon successful signup
          
          navigate("/auth/signin");
          toast.success("Successfully signup");
        } else {
          window.alert(responseData.message);
          // Handle signup error (show error message to user, etc.)
          console.error("Signup failed");
          toast.error("Signup failed");
        }
      } catch (error) {
        console.error("Error during signup:", error);
      }
    }
  };

  return (
    <div className="full-container">
      <div className="loginimgOuter">
        <img className="loginimg" src={img} alt="img" />
      </div>
      <div>
        <form className="container" onSubmit={handleSignup}>
          {/* Company Name Input */}
          <div className="header">
            <div className="text1">Sign in to your Account</div>
          </div>
          <div className="header1">
            <div>
              <div className="flex1">
                <div className="h1">Company Name</div>
                <input
                  className="c1"
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  placeholder="   Enter Your Name"
                  required
                />
              </div>
              {errors?.companyName ? (
                <span style={{ color: "red" }}>{errors.companyName}</span>
              ) : (
                <></>
              )}
            </div>

            {/*Phone Number Input */}
            <div className='phone'>
              <div className='h1'>BussinessId</div>
              <input
                className='c1'
                type='text'
                name='bussinessId'
                value={formData.bussinessId}
                onChange={handleInputChange}
                placeholder='Enter'
              />
            </div>

            <div className='phone'>
              <div className='h1'>Phone Number Id</div>
              <input
                className='c1'
                type='text'
                name='phoneNumberId'
                value={formData.phoneNumberId}
                onChange={handleInputChange}
                placeholder='Enter'
              />
            </div>

            <div>
              <div className="email">
                <div className="h1">Email Id</div>
                <input
                  className="c1"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="   xyz@gmail.com"
                />
              </div>
              {errors?.email ? (
                <span style={{ color: "red" }}>{errors.email}</span>
              ) : (
                <></>
              )}
            </div>

            <div>
              <div className="whatsapp">
                <div className="h1">WhatsApp Number</div>
                <input
                  className="c1"
                  type="number"
                  name="whatsappNumber"
                  value={formData.whatsappNumber}
                  onChange={handleInputChange}
                  placeholder="   +91 xxxxxxx"
                  required
                />
              </div>
              {errors?.whatsappNumber ? (
                <span style={{ color: "red" }}>{errors.whatsappNumber}</span>
              ) : (
                <></>
              )}
            </div>

            <div>
              <div className="webhook">
                <div className="h1">Webhook secret</div>
                <input
                  className="c1"
                  type="text"
                  name="webhookSecret"
                  value={formData.webhookSecret}
                  onChange={handleInputChange}
                  placeholder="meta acess token "
                  required
                />
              </div>
              {errors?.webhookSecret ? (
                <span style={{ color: "red" }}>{errors.webhookSecret}</span>
              ) : (
                <></>
              )}
            </div>

            <div className="password">
              <div className="h1">Password</div>
              <div className="WrapperIB1">
                <input
                  //className='c2'
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="    xxxxxxx"
                />
                <button type="button" onClick={handleTogglePassword}>
                  <img src={eye} alt="img" />
                </button>
              </div>
              {errors?.password ? (
                <span style={{ color: "red" }}>{errors.password}</span>
              ) : (
                <></>
              )}
            </div>
          </div>

          {/* Password Input */}

          {/* Webhook Secret Input 
          <div className='Whatsapp'>
            <div className='h1'>Webhook secret</div>
            <Form.Select
              className='c3'
              aria-label="Default select example"
              name='webhookSecret'
              value={formData.webhookSecret}
              onChange={handleInputChange}
            >
              <option value='Lorem ipsum'>Lorem ipsum</option>
              <option value='1'>One</option>
              <option value='2'>Two</option>
              <option value='3'>Three</option>
            </Form.Select>
          </div>*/}

          {/* Signup Button */}
          <div className="d-grid gap-2">
            <Button
              type="submit"
              variant="primary"
              className="btn"
              onClick={handleSignup}
            >
              Sign Up
            </Button>
          </div>

          {/* OR Separator */}
          {/* <div className="underline">
            <div>OR</div>
          </div> */}

          {/* Sign up with Google Button */}
          <div className="d-grid gap-2">
            {/* <Button variant="primary" className="btn2">
              <FaGoogle style={{ color: "#4285F4" }} /> &nbsp; Sign up with
              Google
            </Button> */}
            <div>
              <p>
                Already have an account?
                <button
                  className="signup-button"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/auth/signin");
                  }}
                >
                  Sign in
                </button>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginSignup;
