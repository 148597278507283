import React, { useEffect, useState } from "react";
import Drawer from "../../../Components/Drawer/Drawer";
import Navbar from "../../../Components/Navbar/Navbar";
import "./ScreenOne.css";
import { useNavigate, } from "react-router-dom";

import backendUrl from "../../../config";
import toast from "react-hot-toast";
import { db, uploadMedia } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";

const TemplateModal = ({ closePopup }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [errors, setErrors] = useState({
    description: "",
    name: "",
  });
  const validateForm = () => {
    let valid = true;

    // Name validation
    if (name.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "",
      }));
    }

    // Description validation
    if (description.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: "Description is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: "",
      }));
    }
    if (type.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        type: "type is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        type: "",
      }));
    }
    return valid;
  };

  const handleCreateTemplate = async () => {
    const id = localStorage.getItem("token");
    const passData = {
      name: name,
      description: name,
      content: description,
      type: type,
      adminId: id,
    };
    console.log(passData);
    console.log(errors);
    if (validateForm()) {
      try {
        const response = await fetch(`${backendUrl}/template`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors", // Add this line
          body: JSON.stringify(passData),
        });
        console.log("response", response);
        if (response.ok) {
          const responseData = await response.json();
          console.log("responseData", responseData);
          closePopup();
          window.location.reload();
          toast.success("Template created");
        } else {
          console.log("Error response false");
          toast.error("Not created");
        }
      } catch (error) {
        console.log("Error creating template", error);
      }
    }
  };
  const handleTypeofTemplate = (e) => {
    setType(e.target.value);
  };
  const handledescription = (e) => {
    setDescription(e.target.value);
  };
  const handlename = (e) => {
    setName(e.target.value);
  };

  return (
    <>
      <div className="modal-outer">
        <div className="modal-inner">
          <label>Name your Template</label>
          <input type="text" placeholder="Enter name" onChange={handlename} />
          {errors?.name ? (
            <span style={{ color: "red" }}>{errors.name}</span>
          ) : (
            <></>
          )}
        </div>
        <div className="modal-inner">
          <label>Type in your Template text</label>
          <input
            type="text"
            placeholder="Enter description"
            onChange={handledescription}
          />
          {errors?.description ? (
            <span style={{ color: "red" }}>{errors.description}</span>
          ) : (
            <></>
          )}
        </div>
        <div className="modal-inner">
          <label>Type of Template</label>
          <select
            value={type}
            onChange={handleTypeofTemplate}
          >
            <option value="">Select Type</option>
            <option value="textTemplate">Text Template</option>
            <option value="imageTemplate">Image Template</option>
          </select>
          {errors?.type ? (
            <span style={{ color: "red" }}>{errors.type}</span>
          ) : (
            <></>
          )}
        </div>
        <div className="createTempbtn" onClick={() => handleCreateTemplate()}>
          +Create Template
        </div>
        <div onClick={closePopup} style={{ cursor: "pointer" }}>
          Close
        </div>
      </div>
    </>
  );
};

const UploadImageModal = ({ closePopupImage, selectedTemplateImageid }) => {
  const [filename, setFilename] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageLink, setImageLink] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const handleFileChange = async (e) => {
    if (e.target.files) {
      const fileURL = e.target.files[0];
      setFilename(fileURL.name);
      const checkFileType = fileURL.type;
      if (checkFileType.startsWith("image/")) {
        setImageLink(fileURL)
        setImagePreview(URL.createObjectURL(fileURL))
      } else {
        console.error("Upload file type is incorrect");
      }
    }
  };
  const removePreviewIMage = e => {
    e.preventDefault();
    setImagePreview(null);
    setFilename(null);
  }
  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const link = await uploadMedia(imageLink, "TemplateImage");
      const updatedTemplate = { image: link };
      await updateDoc(doc(db, "Templates", selectedTemplateImageid), updatedTemplate);
      closePopupImage()
      console.log("Upload finished");
    } catch (error) {
      console.error("Error uploading media or updating document:", error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="modal-outer">
        <div>Image Upload</div>
        <div className="modal-inner">
          {imagePreview ? (
            <>
              <img src={imagePreview} alt={'/'} style={{
                width: '100%',
                height: 'auto'
              }} />
              <button onClick={removePreviewIMage}> Remove Image</button>
            </>
          ) : (
            <>
              <label htmlFor="templateSingleUser" className={"fileUpload"}>
                <div className={"fileuploadContent"}>
                  <p> Upload file {filename && `: ${filename.substring(0, 20)}`}</p>
                </div>
              </label>
              <input
                type="file"
                onChange={handleFileChange}
                id="templateSingleUser"
                style={{ display: "none" }}
              />
            </>
          )}
        </div>
        <div className="createTempbtn" disabled={loading} onClick={submit}>
          Upload Image
        </div>
        <div onClick={closePopupImage} style={{ cursor: "pointer" }}>
          Close
        </div>
      </div>
    </>
  )
}


const ScreenOne = () => {
  const [createtemp, setCreatetemp] = useState(false);
  const [uploadImageInTemplate, setUploadImageInTemplate] = useState(false)
  const [templatelist, setTemplatelist] = useState();
  const [selectedTemplate, setSelectedtemplate] = useState(
    localStorage.getItem("campaigntemplates") || ""
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [selectedTemplateImage, setSelectedTemplateImage] = useState(null)
  const [flow, setFlow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedArray = JSON.parse(localStorage.getItem("campaigncustomers"));
    console.log("local storage customers", storedArray);
    if (storedArray) {
      setFlow(true);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/template/all/${localStorage.getItem("token")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response", response);

        if (response.ok) {
          const responseData = await response.json();
          console.log("responseData", responseData);
          setTemplatelist(
            responseData.templates.filter((element) => element !== null)
          );
        } else {
          console.error("Did not receive the  agent ");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);
  const closePopup = () => {
    setCreatetemp(false);
  };
  const closePopupImage = () => {
    setUploadImageInTemplate(false);
  };

  const handleCreateTemplate = async () => { };
  const handleTemplateClick = (item) => {
    console.log("item", item);
    if (flow) {
      setSelectedtemplate(item.name);
      setSelectedTemplateId(item.id);
      console.log("Template selected", item.name);
      if (item.type === "imageTemplate") {
        setSelectedTemplateImage(item.id);
        setSelectedtemplate(item.name);
        setSelectedTemplateId(item.id);
        console.log("imageTemplate _____", item.name);
        setUploadImageInTemplate(true);
      }
    }
    return;
  };
  const handleNextClick = () => {
    /*console.log("next click selected templates",selectedTemplate)
      const jsonString = JSON.stringify( selectedTemplate);*/
    if (selectedTemplate.length > 0) {
      localStorage.setItem("campaigntemplatename", selectedTemplate);
      localStorage.setItem("campaigntemplateid", selectedTemplateId);
      navigate("/campaignschedule");
    } else {
      toast.error("Select a template");
    }
  };

  const handleNextClick1 = async () => {
    try {
      let newSteps = 7;

      const response = await fetch(
        `${backendUrl}/admin/update/${localStorage.getItem("token")}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ steps: newSteps }),
        }
      );
      console.log("response", response);

      if (response.ok) {
        localStorage.setItem("steps", newSteps);
        localStorage.setItem("isFirstTimeUser", false);
        navigate("/dashboard");
      } else {
        console.error("Did not receive the  company info ");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }
  };

  return (
    <>
      <Drawer />
      <div className="profileOuter">
        {createtemp && (
          <div className="popup">
            <div className="popup_inner">
              <TemplateModal closePopup={closePopup} />
            </div>
          </div>
        )}
        {uploadImageInTemplate && (
          <div className="popup">
            <div className="popup_inner">
              <UploadImageModal closePopupImage={closePopupImage} setSelectedtemplate={setSelectedtemplate} selectedTemplate={selectedTemplate} selectedTemplateImageid={selectedTemplateImage} />
            </div>
          </div>
        )}

        <div className="manul">
          <div className="manual-top">
            <div className="manual-texts">
              <h3>Manual Templates (optional)</h3>
              <p>
                Choose any format from the templates given below, and edit them
                manually according to your preference.{" "}
              </p>
            </div>

            <div className="manual-buttons">
              <div className="manualbtn">Manual</div>
              <div
                className="deselected"
                onClick={() => navigate("/aitemplate")}
              >
                AI
              </div>
            </div>
          </div>

          {!templatelist ? (
            <div className="manual-bottom1">
              <div className="bottom-text">
                Create a Template to get started
              </div>
              <div
                className="createTempbtn"
                onClick={() => setCreatetemp(true)}
              >
                + Create New
              </div>
            </div>
          ) : (
            <div className="manual-bottom2">
              <div
                className="template1"
                style={{
                  background: "none",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => setCreatetemp(true)}
              >
                <div className="createnewtemp">+ Create New</div>
              </div>

              {templatelist.map((item, index) => {
                return (
                  <div
                    className={
                      selectedTemplate === item.name
                        ? "template1 templateSelected"
                        : "template1"
                    }
                    onClick={() => handleTemplateClick(item)}
                  >
                    <div className="templateinner-cont">
                      <div className="template-messageOuter">
                        <div className="message-cont">
                          {item.description},<br></br>
                          {item.content}
                        </div>
                        <div className="template-buttons">
                          <div className="contactuse">Contact Us</div>
                          <div className="track">Track Package</div>
                        </div>
                      </div>
                    </div>
                    <div className="template-text">{item.name}</div>
                  </div>
                );
              })}
            </div>
          )}

          {/* add new page url here */}
          {localStorage.getItem("isFirstTimeUser") === "true" &&
            localStorage.getItem("steps") === "6" && (
              <div style={{ width: "100%", textAlign: "right" }}>
                <div>
                  <button
                    className="btn btn-primary"
                    style={{ width: "60px" }}
                    onClick={() => handleNextClick1()}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}

          {flow && (
            <div className="">
              {/* <div className="selecttemplatebtn">
                Click on template to select
              </div> */}
              <div
                className="createTempbtn"
                style={{ padding: "5px 10px" }}
                onClick={handleNextClick}
              >
                Next
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ScreenOne;
