import React, { useState, useEffect } from 'react';
import backendUrl from '../../config'
// import { FiHome } from 'react-icons/fi';
// import { BsBorderAll } from 'react-icons/bs';
// import { LuMail } from 'react-icons/lu';
// import { BsBell } from 'react-icons/bs';
// import { FiUsers } from 'react-icons/fi';
// import { BsGrid } from 'react-icons/bs';
// import { AiOutlineShop } from 'react-icons/ai';
// import { GiCircuitry } from 'react-icons/gi';
// import { PiPuzzlePieceBold } from 'react-icons/pi';
// import { BsGrid1X2 } from 'react-icons/bs';
import botimage from "../../Assets/bot.png"
import campaignimage from "../../Assets/campaign.png"
import agentimage from "../../Assets/agent.png"
import leadimage from "../../Assets/lead.png"
import profileimage from "../../Assets/profile.svg"
import templateimage from "../../Assets/templates.png"
import logoutimage from "../../Assets/power-off.png"
import customerimage from "../../Assets/customer.png"
import homeimage from "../../Assets/home/Vector.png"
import chatimage from "../../Assets/chat.png"
import bellimage from "../../Assets/bell-regular.svg"
import './index.css'
import { useNavigate } from 'react-router-dom';
const Drawer = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate()
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    try {
      fetch(`${backendUrl}/company/${localStorage.getItem('token1')}`).then(res => res.json()).then(data => setNotifications(data.data.notifications ? data.data.notifications : []))

    } catch (error) {
      console.log("Error in fetching company data", error)
    }
  }, [])

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const handleclick = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("isFirstTimeUser")
    if (localStorage.getItem("token1")) {
      localStorage.removeItem("token1")

    }
    if (localStorage.getItem("agentid")) {
      localStorage.removeItem("agentid")

    }
    localStorage.removeItem("steps")



    navigate('/')
  }

  return (
    <div style={{ width: isOpen ? '90px' : '0', transition: 'width 0.3s' }} className='drawerOuter'>
      {/* <button onClick={toggleDrawer}>&#9776; Open Drawer</button> */}
      {localStorage.getItem("agentid") ?
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 0', gap: "30px" }}>
          <div className='icon' style={{ marginBottom: '20px' }} onClick={() => navigate('/dashboard')} >
            <img src={homeimage} alt='img' />
          </div>
          <div className='icon' style={{ marginBottom: '20px' }} onClick={() => navigate('/leadconversion')}>
            <img src={leadimage} alt='img' />
          </div>
          <div className='icon' style={{ marginBottom: '20px' }} onClick={() => navigate('/customer')} >
            <img src={customerimage} alt='img' />
          </div>
          <div className='icon' style={{ marginBottom: '20px' }} onClick={() => navigate('/chat')}>
            <img src={chatimage} alt='img' />
          </div>
          <div className='icon' style={{ marginBottom: '20px' }} onClick={() => navigate('/notifications')}>
            <img className='bellIcon' src={bellimage} alt='notifications' />
          </div>

          <div className='icon' style={{ marginBottom: '20px' }} onClick={() => handleclick()}>
            <img src={logoutimage} alt='img' />
          </div>


        </div>
        :
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 0', gap: "30px" }}>
          <div className='icon' style={{ marginBottom: '15px' }} onClick={() => navigate('/dashboard')} >
            <img src={homeimage} alt='img' />
          </div>
          <div className='icon' style={{ marginBottom: '15px' }} onClick={() => navigate('/leadconversion')}>
            <img src={leadimage} alt='img' />
          </div>
          <div className='icon' style={{ marginBottom: '15px' }} onClick={() => navigate('/manualtemplate')}>
            <img src={templateimage} alt='img' />
          </div>
          <div className='icon' style={{ marginBottom: '15px' }} onClick={() => navigate('/chat')}>
            <img src={chatimage} alt='img' />
          </div>
          <div className='icon' style={{ marginBottom: '15px' }} onClick={() => navigate('/customer')} >
            <img src={customerimage} alt='img' />
          </div>
          <div className='icon' style={{ marginBottom: '15px' }} onClick={() => navigate(`/agent/${localStorage.getItem("token")}`)}>
            <img src={agentimage} alt='img' />
          </div>
          <div className='icon' style={{ marginBottom: '15px' }} onClick={() => navigate('/profile')}>
            <img src={profileimage} alt='img' />
          </div>
          <div className='icon' style={{ marginBottom: '15px' }} onClick={() => navigate('/companyprofile')}>
            <img src={botimage} alt='img' />
          </div>
          <div className='icon' style={{ marginBottom: '15px' }} onClick={() => navigate('/campaign')}>
            <img src={campaignimage} alt='img' />
          </div>
          <div className='icon' style={{ marginBottom: '20px'}} onClick={() => navigate('/notifications')}>
            <img src={bellimage} alt='notifications' />
            {notifications?.length > 0 && <div className="dot"></div>}
          </div>
          <div className='icon' style={{ marginBottom: '5px' }} onClick={() => handleclick()}>
            <img src={logoutimage} alt='img' />
          </div>
        </div>
      }
    </div>
  );
};

export default Drawer;
