
import React, { useState, useEffect } from "react"
import backendUrl from "../../../config"
import Drawer from "../../Drawer/Drawer"
import "./index.css";


const LeadsDisplay = () => {
    const [mydata, setMydata] = useState([])
    console.log(mydata)


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                  `${backendUrl}/company/${localStorage.getItem("token1")}`,
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );
                console.log("response", response)

                if (response.ok) {
                    const responseData = await response.json();
                    const leads = responseData.data.leadConversion.map((item) => {
                        return JSON.parse(item)
                    })
                    console.log("responseData", leads);
                    setMydata(leads)


                } else {
                    console.error('Did not receive the  company info ');
                }
            } catch (error) {
                console.error('Error during Fetching:', error);
            }
        }
        fetchData()
    }, [])
    return (<> 
    <Drawer/>
           <div  className='profileOuter'>
            <table>
                <thead>
                    <tr >
                    {mydata.map((item) =>
                            <td>{item.fieldName}</td>

                        )}
                        
                    </tr>

                </thead>
                <tbody>

                    <tr >
                    {mydata.map((item) =>
                            <td>{item.fieldDatatype}</td>

                        )}

                    </tr>
                    <tr >
                    {mydata.map((item) =>
                            <td>{item.required?'true':'false'}</td>

                        )}

                    </tr>

                </tbody>
            </table>
        </div>
        </>


    )

}
export default LeadsDisplay;