import Drawer from "../../Components/Drawer/Drawer";
import Dropbox from "../../Components/Dropbox/Dropbox";
import Navbar from "../../Components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import { MdOutlineBackup } from "react-icons/md";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import UploadIcon from "@mui/icons-material/Upload";
import { GrDocumentPdf } from "react-icons/gr";
import Box from "@mui/material/Box";
import Token from "../../Components/Tokengenerate/Token";
import LinearProgress, {
	linearProgressClasses,
} from "@mui/material/LinearProgress";
import "./Customerinfo.css";
import backendUrl from "../../config";
import toast from "react-hot-toast";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor:
			theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
	},
}));

const GroupCreateModal = ({ file, closeModal, setcheckUpload }) => {
	const [overwrite, setOverwrite] = useState(false);
	const [groupName, setGroupName] = useState("");
	const [uploadProgress, setUploadProgress] = useState(0);
	const navigate = useNavigate();
	const [isUploaded, setIsUPloaded] = useState(false);

	async function handleClick(event) {
		console.log("file", file);
		setIsUPloaded(true);
		try {
			const formdata = new FormData();
			formdata.append("file", file);
			formdata.append("adminId", localStorage.getItem("token"));
			formdata.append("companyId", localStorage.getItem("token1"));
			formdata.append("overwrite", overwrite);
			formdata.append("groupName", groupName);

			console.log(formdata);

			const res = await fetch(
				`${backendUrl}/customer/multipleCustomers`,
				{
					method: "POST",
					body: formdata,
				}
			);
			console.log("uploadprogress", uploadProgress);
			const customers = await res.json();
			console.log(customers);
			if (customers) {
				setcheckUpload(true);
				toast.success("Customers uploaded successfully");
				setUploadProgress(80);
			}
			if (localStorage.getItem("campaignname")) {
				const newarray = [];
				if (
					customers.customers.length > 0 &&
					customers.existing.length === 0
				) {
					newarray.push(customers.customers);
					console.log("customer", customers.customers);
					console.log("newarray 1", newarray);
				}
				if (
					customers.customers.length === 0 &&
					customers.existing.length > 0
				) {
					newarray.push(customers.existing);
					console.log("existing", customers.existing);
					console.log("newarray 2", newarray);
				}
				if (
					customers.customers.length > 0 &&
					customers.existing.length > 0
				) {
					newarray.push(customers.customers);
					newarray.push(customers.existing);
					localStorage.setItem("campaigncustomers", newarray);
					console.log("newarray 3", newarray);
				}
				if (
					customers.customers.length === 0 &&
					customers.existing.length === 0
				) {
					localStorage.setItem("campaigncustomers", newarray);
					console.log("newarray 4", newarray);
				}
				const jsonString = JSON.stringify(
					newarray[0].map((item) => item.phone)
				);
				localStorage.setItem("campaigncustomers", jsonString);
			}

			if (localStorage.getItem("campaigncustomers")) {
				navigate("/selectcampaignusers");
			} else if (
				!localStorage.getItem("isFirstTimeUser") ||
				localStorage.getItem("isFirstTimeUser") === "false"
			) {
				navigate("/customer");
			}
			closeModal();
		} catch (error) {
			console.log(error);
		}

		const interval = setInterval(() => {
			setUploadProgress((prevProgress) =>
				prevProgress < 80 ? prevProgress + 10 : prevProgress
			);
		}, 500);

		setTimeout(() => {
			clearInterval(interval);
			setUploadProgress(100);
			closeModal();
			console.log("hi");
		}, 2000);
	}

	/*if(uploadProgress===100){
    navigate('/selectcampaignusers')
  }*/

	return (
		<div className="modalForm">
			<div className="formdivouter">
				<input
					className="Cinput"
					type="checkbox"
					id="overwrite"
					name="overwrite"
					value={overwrite}
					onChange={() => setOverwrite(!overwrite)}
				/>
				<label>Overwrite existing customers</label>
			</div>
			<div className="formdivouter">
				<label>GroupName</label>
				<br></br>
				<input
					className="Cinput"
					type="text"
					id="groupName"
					name="groupName"
					value={groupName}
					onChange={(e) => setGroupName(e.target.value)}
				/>
			</div>
			<button
				className="addaddbtn"
				style={{
					border: "none",
					padding: "5px 13px",
					borderRadius: "10px",
					background: "lightblue",
				}}
				onClick={handleClick}
			>
				Create
			</button>
			{isUploaded && (
				<div style={{ width: "70%" }}>
					<div className="pdf-box">
						<GrDocumentPdf className="pdf-icons" />
						<Box className="loder">
							<BorderLinearProgress
								variant="determinate"
								value={uploadProgress}
							/>
						</Box>
						{/*uploadProgress > 0 && (
                <div>
                  <progress max="100" value={uploadProgress}></progress>
                </div>
            )*/}
					</div>
				</div>
			)}
		</div>
	);
};

const CustomerInfo = () => {
	const [file, setFile] = useState();
	const [isUploaded, setIsUPloader] = useState(false);
	const [checkUpload, setcheckUpload] = useState(false);
	const [modal, setModal] = useState(false);

	//const [isNext, setIsNext] = useState(true);

	const navigate = useNavigate();
	// const [pdfPrivew, setPdfPrivew] = useState(false);

	/*const handleNext=()=>{
    setIsNext(true);
    navigate("/Token");

  }*/

	async function handleFile(event) {
		const selectedFile = event.target.files[0];
		setFile(selectedFile);
		setModal(true);
	}
	function handleUpload() {
		setIsUPloader(true);
		// setIsViewer(true);
	}
	const closeModal = () => {
		setModal(false);
	};
	const handleNextClick = async () => {
		try {
			let newSteps = 4;

			const response = await fetch(
				`${backendUrl}/admin/update/${localStorage.getItem("token")}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ steps: newSteps }),
				}
			);
			console.log("response", response);

			if (response.ok) {
				localStorage.setItem("steps", newSteps);
				navigate("/leadConversion");
			} else {
				console.error("Did not receive the  company info ");
			}
		} catch (error) {
			console.error("Error during Fetching:", error);
		}
	};
	return (
		<div>
			<Drawer />
			{file && modal && (
				<div className="popup">
					<div className="popup_inner">
						<GroupCreateModal
							file={file}
							closeModal={closeModal}
							setcheckUpload={setcheckUpload}
						/>
					</div>
				</div>
			)}
			<div className="boxOuter">
				<div className="box-container">
					<h1 className="upload">
						Upload Your Customer Information (optional)
					</h1>
					<p className="lorem">
						Lorem ipsum dolor sit amet consectetur, adipisicing
						elit. Maiores iure culpa reiciendis, totam <br />
						Lorem ipsum dolor sit amet consectetur, adipisicing
						elit.Maiores iure culpa reiciendis, totam
					</p>

					<div className="drop-box">
						<MdOutlineBackup className="drop-logo" />
						<p className="drag">Drag & deop your file here</p>
						<h3 className="od">OR</h3>
						<Button
							component="label"
							className="Browser-btn"
							variant="contained"
							startIcon={<UploadIcon />}
							onClick={handleUpload}
						>
							Browse
							<VisuallyHiddenInput
								type="file"
								accept=".csv,.xlsx,xls"
								name="file"
								onChange={handleFile}
							/>
						</Button>
						<p className="p1">
							Max File size:<b>50MB</b>
						</p>
						<p className="p2">
							Supported file formats:<b>.CSV.Excel</b>
						</p>
					</div>
					{localStorage.getItem("campaignname") && (
						<Stack spacing={2} direction="row">
							<Button
								variant="contained"
								style={{
									borderRadius: "20px",
									paddingLeft: "30px",
									paddingRight: "30px",
								}}
								onClick={() => navigate("/selectcampaignusers")}
							>
								Skip
							</Button>
						</Stack>
					)}
					{/*<h3 style={{marginTop:"0px", marginBottom:"0px"}}>OR</h3>
       {/* link tag *}
      {isNext &&
      <p style={{marginTop:"0px", marginBottom:"0px"}}>Create a dynamic link to capture leads:
        <a href='./Token' onClick={handleNext}> Grnerate Link</a></p>}
        <div style={{marginBottom:"8px",marginLeft:"800px"}}>
        <Stack spacing={2} direction="row">
        <Button variant="outlined" style={{borderRadius:"20px", paddingLeft:"30px",paddingRight:"30px"}}>Skip</Button>
         <Button variant="contained" 
         style={{borderRadius:"20px", paddingLeft:"30px",paddingRight:"30px"}}>Next</Button>
        </Stack>
      </div>*/}
					{localStorage.getItem("isFirstTimeUser") === "true" &&
						localStorage.getItem("steps") === "3" &&
						checkUpload && (
							<div>
								<button
									className="btn btn-primary"
									style={{ width: "60px" }}
									onClick={() => handleNextClick()}
								>
									Next
								</button>
							</div>
						)}
				</div>
			</div>
		</div>
	);
};

export default CustomerInfo;
