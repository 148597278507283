import React, { useEffect, useState } from "react";
import style from "../../CustomerManagement/CustomerDashboard/CustomerDashboard.module.css";
import delete1 from "../../../Assets/delete1.png";
import edit1 from "../../../Assets/edit1.png";
import whatsapp1 from "../../../Assets/whatsapp1png.png";
import headingImage from "../../../Assets/headingimage.png";
import Drawer from "../../Drawer/Drawer";
import { useNavigate } from "react-router-dom";
import oopsimage from "../../../Assets/oops.png";
import backendUrl from "../../../config";

const DeleteModal = ({ closePopup, data }) => {
  const handleDeleteAgent = async () => {
    try {
      const response = await fetch(`${backendUrl}/agent/delete/${data.id}`, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
        },
      });

      if (response.ok) {
        closePopup();
        window.location.reload();
      } else {
        console.log("Delete Failed");
      }
    } catch (error) {
      console.log("Error during deleting agent ", error);
    }
  };

  return (
    <div className={style.modalOuter}>
      <div className={style.modalname}>{data.name}</div>
      <div className={style.modaltext}>
        Are you sure you want to delete this Agent?
      </div>
      <div className={style.modalbuttons}>
        <div className={style.modalcancel} onClick={closePopup}>
          Cancel
        </div>
        <div className={style.modaldelete} onClick={handleDeleteAgent}>
          Delete
        </div>
      </div>
    </div>
  );
};

const AgentDashboard = () => {
  const [datalist, setDatalist] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const adminid = localStorage.getItem("token");
      try {
        const response = await fetch(`${backendUrl}/agent/all/${adminid}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const responseData = await response.json();
        console.log("agents list", responseData.agents);

        if (response.ok) {
          setDatalist(responseData.agents);
          console.log();
          // Redirect to dashboard upon successful signin
        } else {
          console.error("Fetching failed");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);

  const [selecteditem, setSelecteditem] = useState();
  const closePopup = () => {
    setSelecteditem();
  };
  const handleDelete = (item) => {
    setSelecteditem(item);
  };

  return (
    <>
      <Drawer />
      {selecteditem && (
        <div className={style.popup}>
          <div className={style.popup_inner}>
            <DeleteModal closePopup={closePopup} data={selecteditem} />
          </div>
        </div>
      )}
      <div className={style.cdMainOuter}>
        <div className={style.cdOuter}>
          <div className={style.cdupper}>
            <div className={style.cdHeading}>
              <div className={style.cdheadingwrapper}>
                <div className={style.cdheading1}>
                  <img className={style.cdimg} src={headingImage} alt="img" />
                  Agent Management
                </div>
                <div
                  className={style.cdbtn}
                  onClick={() => navigate("/agent/add")}
                >
                  + Add Agent
                </div>
              </div>
              <div className={style.cdheadingtext}>
                Manage the details your added agents and add agents as you like.{" "}
              </div>
            </div>
          </div>
          <div className={style.cdbottom}>
            {Object.keys(datalist).length === 0 ? (
              <div className={style.adoopsOuter}>
                <div className={style.cdheading1}>Oops</div>
                <div className={style.adoopstext}>
                  Looks like you have not added any agent
                </div>
                <div className={style.adoopsimg}>
                  <img style={{ width: "54%" }} src={oopsimage} alt="img" />
                </div>
                <div
                  className={style.cdbtn}
                  onClick={() => navigate("/agent/add")}
                >
                  + Add Agent
                </div>
              </div>
            ) : (
              <>
                {Object.values(datalist).map((item) => (
                  <div className={style.cdone} key={item.id}>
                    <div
                      className={style.cdname}
                      onClick={() => navigate(`/agentdetails/${item.id}`)}
                    >
                      {item.name}
                    </div>
                    <div className={style.cdcontent}>
                      <div
                        className={style.cdinfoOuter}
                        onClick={() => navigate(`/agentdetails/${item.id}`)}
                      >
                        <div className={style.cdinfo}>
                          <div className={style.cdsmallheading}>Email</div>
                          <div className={style.cdsmalldata}>{item.email}</div>
                        </div>
                        <div className={style.cdinfo}>
                          <div className={style.cdsmallheading}>
                            Phone Number :
                          </div>
                          <div className={style.cdsmalldata}>
                            {item.phoneNumber}
                          </div>
                        </div>
                      </div>
                      <div
                        className={style.cdicons}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={edit1}
                          alt="img"
                          onClick={() => navigate(`/agent/update/${item.id}`)}
                        />
                        <img
                          src={delete1}
                          alt="img"
                          onClick={() => handleDelete(item)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentDashboard;
