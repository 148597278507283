import React, { useEffect, useState } from "react";
import style from "../../CustomerManagement/AddCustomer/AddCustomer.module.css";
import arrow from "../../../Assets/arrow.png";
import Drawer from "../../Drawer/Drawer";
import { useNavigate, useParams } from "react-router-dom";
import pdf from "../../../Assets/pdfpng.png";
import editpng from "../../../Assets/editpng.png";
import backendUrl from "../../../config";
import toast from "react-hot-toast";

const EditProfile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [num, setNum] = useState("");
  const [webhook, setWebhook] = useState("");
  const [companyname, setCompanyname] = useState("");
  const navigate = useNavigate();
  const id = localStorage.getItem("token");
  const [mydata, setMydata] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendUrl}/admin/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("response", response);

        if (response.ok) {
          const responseData = await response.json();
          console.log("responseData", responseData);
          setFields(responseData);
          setMydata(responseData);
        } else {
          console.error("Did not receive the  profile info ");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);
  const setFields = (data) => {
    setName(data.name);
    setEmail(data.email);
    setNum(data.whatsappNumber);
    setWebhook(data.webhookSecret);
    setCompanyname(data.companyName);
  };
  const handlename = (e) => {
    setName(e.target.value);
  };
  const handleemail = (e) => {
    setEmail(e.target.value);
  };
  const handlephone = (e) => {
    setNum(e.target.value);
  };
  const handlewebhook = (e) => {
    setWebhook(e.target.value);
  };
  const handlecompanyname = (e) => {
    setCompanyname(e.target.value);
  };

  const handleaddclick = async (e) => {
    e.preventDefault();
    // name, email, password, phoneNumber, whatsappNumber, webhookSecret, companyName
    const passData = {
      name: name,
      email: email,
      phoneNumber: num,
      //password: mydata.password,
      whatsappNumber: num,
      webhookSecret: webhook,
      companyName: companyname,
    };
    console.log("passData", passData);
    if (validateForm()) {
      try {
        const response = await fetch(
          `${backendUrl}/admin/update/${localStorage.getItem("token")}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(passData),
          }
        );
        console.log("response", response);
        /*const responseData = await response.json();
            console.log(" true responseData",responseData);*/
        if (response.ok) {
          navigate(`/profile`);
          toast.success("Profile updated");
        } else {
          console.error("Admin info not updated ");
          toast.error("Not updated");
        }
      } catch (error) {
        console.error("Error during updation:", error);
      }
    }
  };
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    num: "",
    webhook: "",
    companyName: "",
  });

  const validateForm = () => {
    let valid = true;

    // Name validation
    if (name.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "",
      }));
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    // Mobile number validation
    const mobileRegex = /^\d{10}$/;
    if (!mobileRegex.test(num)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        num: "Invalid mobile number",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        num: "",
      }));
    }

    if (companyname.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyName: "Name is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyName: "",
      }));
    }

    // webhookSecret validation
    if (webhook.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        webhook: "WebhookSecret is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        webhook: "",
      }));
    }

    return valid;
  };

  return (
    <>
      <Drawer />
      <div className={style.profileOuter}>
        <div className={style.addOuter}>
          <div
            className={style.addHeading}
            style={{ width: "68rem", justifyContent: "space-between" }}
          >
            <div className={style.profilewrapper} onClick={() => navigate("/profile")}>
              <img style={{ width: "28.5%" }} src={arrow} alt="img" />
              <div className={style.addHeadingtext}>Profile</div>
            </div>
          </div>
          <div className={style.addFields}>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Admin Name</label>
              <input
                className={style.addinput}
                name="name"
                value={name}
                type="text"
                onChange={handlename}
              />
              {errors?.name ? (
                <span style={{ color: "red" }}>{errors.name}</span>
              ) : (
                <></>
              )}
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Admin's email address</label>
              <input
                className={style.addinput}
                name="email"
                value={email}
                type="text"
                onChange={handleemail}
              />
              {errors?.email ? (
                <span style={{ color: "red" }}>{errors.email}</span>
              ) : (
                <></>
              )}
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Phone Number</label>
              <div className={style.addphoneinput}>
                <div className={style.addnum}>+91</div>
                <input
                  className={style.addinput}
                  name="num"
                  value={num}
                  onChange={handlephone}
                  style={{ width: "55%" }}
                  type="text"
                />
              </div>
              {errors?.num ? (
                <span style={{ color: "red" }}>{errors.num}</span>
              ) : (
                <></>
              )}
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Webhook Secret</label>
              <input
                className={style.addinput}
                name="webhook"
                value={webhook}
                type="text"
                onChange={handlewebhook}
              />
              {errors?.webhook ? (
                <span style={{ color: "red" }}>{errors.webhook}</span>
              ) : (
                <></>
              )}
            </div>
            <div className={style.addonefield}>
              <label className={style.addlabel}>Company Name</label>
              <input
                className={style.addinput}
                name="Company name"
                value={companyname}
                type="text"
                onChange={handlecompanyname}
              />
              {errors?.companyName ? (
                <span style={{ color: "red" }}>{errors.companyName}</span>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={style.addButtons}>
            <div
              className={style.addcancelbtn}
              onClick={() => navigate("/profile")}
            >
              Cancel
            </div>
            <div className={style.addaddbtn} onClick={handleaddclick}>
              Edit
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
