import React from "react";
import campaignOne from "../../Assets/camsuccess.png";
import style from "../Compaign/CreateCampaign/CreateCampaign.module.css";
import { useNavigate } from "react-router-dom";
import Drawer from "../Drawer/Drawer";

const OnboardingSuccessful = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    localStorage.setItem("isFirstTimeUser", false);
    navigate("/dashboard");
  };
  return (
    <>
      <Drawer />
      <div className={style.ccmainOuter}>
        <div className={style.ccOuter}>
          <div className={style.ccMiddle}>
            <div className={style.cccongo}>Congratulations! 🎉</div>

            <div className={style.cccongotext}>
              Your Onboarding has been completed successfully!
            </div>
            <img className={style.campaignoneimg} src={campaignOne} alt="img" />

            <div className={style.ccnext} style={{ justifyContent: "center" }}>
              <div
                className={style.ccnextbtn}
                onClick={handleNext}
                style={{
                  width: "170px",
                  padding: "10px",
                  fontSize: "13px",
                  color: "#ffffff",
                }}
              >
                Dashboard
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardingSuccessful;
