import React, { useState } from "react";
import arrow from "../../Assets/arrow.png";
import campaignOne from "../../Assets/camsuccess.png";
import style from "./CreateCampaign/CreateCampaign.module.css";
import { useNavigate } from "react-router-dom";
import Drawer from "../Drawer/Drawer";

const CamSuccessful = () => {
  const navigate = useNavigate();

  const handlebackclick = () => {
    navigate("/campaignschedule");
  };
  const handleNext = () => {
    navigate("/campaign");
  };
  return (
    <>
      <Drawer />
      <div className={style.ccmainOuter}>
        <div className={style.ccOuter}>
          <div className={style.ccheading} onClick={() => handlebackclick()}>
            <img className={style.ccarrowimg} src={arrow} alt="img" /> Campaign
            Creation
          </div>

          <div className={style.ccMiddle}>
            <div className={style.cccongo}>Congratulations! 🎉</div>

            <div className={style.cccongotext}>
              Your Campaign has been created successfully!
            </div>
            <img className={style.campaignoneimg} src={campaignOne} alt="img" />
            <div className={style.cctext}>
              Now can schedule your campaign and add follow-up sequences for
              your customers.
            </div>

            <div className={style.ccnext} style={{ justifyContent: "center" }}>
              <div
                className={style.ccnextbtn}
                onClick={handleNext}
                style={{ width: "170px", padding: "10px", fontSize: "13px", color:"#ffffff" }}
              >
                Campaign Dashboard
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CamSuccessful;
