import React, { useState, useEffect } from "react";
import "./index.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Drawer from "../../Drawer/Drawer";
import backendUrl from "../../../config";
import toast from "react-hot-toast";

const BotDescriptionComponent = () => {
  const navigate = useNavigate();
  const [checkUpload, setcheckUpload] = useState(false);

  const [formData, setFormData] = useState({
    botName: "",
    botDescription: "",
    botTone: "",
  });

  const [companyData, setcompanyData] = useState();

  const options = ["Male", "Female", "Others"];
  console.log("botinfo", formData);
  console.log("companyData", companyData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/company/${localStorage.getItem("token1")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response", response);

        if (response.ok) {
          const responseData = await response.json();
          console.log("responseData", responseData);
          setcompanyData(responseData.data);
          setFormData({
            botName: responseData.data.botInfo.botName,
            botDescription: responseData.data.botInfo.botDescription,
            botTone: responseData.data.botInfo.botTone,
          });
        } else {
          console.error("Did not receive the  company info ");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      botTone: selectedValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    //companyId,adminId,botInfo,leadConversion
    const passData = {
      companyId: localStorage.getItem("token1"),
      adminId: localStorage.getItem("token"),
      botInfo: formData,
    };
    console.log(passData);
    if (validateForm()) {
      try {
        const response = await fetch(`${backendUrl}/company/update`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(passData),
        });
        console.log("response", response);
        const responseData = await response.json();
        console.log("responseData", responseData);

        if (response.ok) {
          setcheckUpload(true);
          toast.success("Bot is created");
        } else {
          console.error("Did not receive the  profile info ");
          toast.error("Bot is not created");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    }
  };

  const [errors, setErrors] = useState({
    botName: "",
    botDescription: "",
    botTone: "",
  });

  const validateForm = () => {
    let valid = true;

    if (formData.botDescription.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        botDescription: "Bot description is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        botDescription: "",
      }));
    }

    // Description validation
    if (formData.botDescription.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        botDescription: "Bot description is required",
      }));
      valid = false;
    } else if (
      formData.botDescription.split(/\s+/).filter((word) => word !== "")
        .length > 100
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        botDescription: "Exceeded word limit (max 100 words)",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        botDescription: "",
      }));
    }

    // BotTone validation
    if (formData.botTone.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        botTone: "Bot tone is required",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        botTone: "",
      }));
    }

    return valid;
  };
  const handleNextClick=async()=>{
    
    try {
      let newSteps=3

      const response = await fetch(
        `${backendUrl}/admin/update/${localStorage.getItem("token")}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body:JSON.stringify({steps:newSteps})
        }
      );
      console.log("response", response);

      if (response.ok) {
      localStorage.setItem("steps",newSteps)
       navigate("/customer/bulk")
      } else {
        console.error("Did not receive the  company info ");
      }
    } catch (error) {
      console.error("Error during Fetching:", error);
    }


  }

  return (
    <>
      <Drawer />
      <div className="profileOuter" style={{ height: "10vh" }}>
        <div className="detOuter">
          <div className="agent-link-bar">
            <NavLink to="/companyprofile" style={{ textDecoration: "none" }}>
              <span>Agent Details</span>
            </NavLink>
            <NavLink to="/document" style={{ textDecoration: "none" }}>
              <span>Agent Training</span>
            </NavLink>
            <NavLink to="/aiagentdetails" style={{ textDecoration: "none" }}>
              <span>FAQs & Services</span>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="bot-container">
        {/* <div className="buttonsWrapper">
          <button className="next-btn" onClick={() => navigate("/document")}>
            Upload Documents
          </button>
        </div> */}
        <div className="bot-headings">
          <h1>Bot Description</h1>
          <p>Define the basic language & tone for your bot.</p>
        </div>
        <div className="bot-content">
          <form onSubmit={handleSubmit}>
            <div>
              <label className="bot-label">Bot Name</label>
              <input
                style={{ border: "1px solid black" }}
                type="text"
                className="bot-input"
                name="botName"
                value={formData.botName}
                onChange={handleInputChange}
              />
            </div>

            {errors?.botName ? (
              <span style={{ color: "red" }}>{errors.botName}</span>
            ) : (
              <></>
            )}
            <div>
              <label className="bot-label">Bot Description</label>
              <textarea
                type="text"
                name="botDescription"
                value={formData.botDescription}
                onChange={handleInputChange}
              />
            </div>
            {errors?.botDescription ? (
              <span style={{ color: "red" }}>{errors.botDescription}</span>
            ) : (
              <></>
            )}

            {/* <label className='bot-label'>Bot Tone</label>
          <select value={formData.botTone} onChange={handleInputChange}>
            <option name="male" value="male">Male</option>
            <option name="female" value="female">Female</option>
            <option name="other" value="other">Other</option>
          </select> */}
            <div>
              <label className="bot-label">Bot Tone</label>
              <select
                name="botTone"
                value={formData.botTone}
                onChange={handleSelectChange}
              >
                <option value="" disabled>
                  -- Select --
                </option>
                {options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            {errors?.botTone ? (
              <span style={{ color: "red" }}>{errors.botTone}</span>
            ) : (
              <></>
            )}
            <div className="bot-buttons">
              <button className="next-btn" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
        {localStorage.getItem("isFirstTimeUser") === "true" && localStorage.getItem("steps")==="2" && checkUpload && (
          <div style={{ width: "100%", textAlign: "right" }}>
            <div>
                <button
                  className="btn btn-primary"
                  style={{ width: "60px" }}
                  onClick={() =>handleNextClick()}
                >
                  Next
                </button>
              </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BotDescriptionComponent;
