import React, { useEffect, useState } from "react";
import Drawer from "../../Components/Drawer/Drawer";
import "./Dashboard.css";
import { BsWhatsapp } from "react-icons/bs";
import { IoIosAnalytics } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import "react-bootstrap";
import backendUrl from "../../config";
import { Timestamp } from "firebase/firestore";
const Dashboard = () => {
	const [name, setName] = useState("");
	const [agent, setAgent] = useState("");
	const [data, setData] = useState();
	const [activeChat, setActiveChat] = useState();
	const [failedChat, setFailedChat] = useState();

	const [webtoken, setWebtoken] = useState("");
	const navigate = useNavigate();

	const [currentStep, setCurrentStep] = useState(0);
	const totalSteps = 6;

	const updateProgress = () => {
		const progress = (currentStep / totalSteps) * 100;
		return {
			width: `${progress}%`,
		};
	};

	const getChats = async () => {
		try {
			const response = await fetch(
				`${backendUrl}/whatsapp/getDocuments`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			console.log("response", response);

			if (response.ok) {
				const responseData = await response.json();

				console.log("responseData chats", responseData);
				setActiveChat(
					responseData.filter((item) => {
						let temp = `${localStorage.getItem("token1")}-${
							item.phoneNumber
						}`;
						console.log("temp", temp);
						return item.comid === temp && item.reply === true;
					})
				);
				console.log("myid: " + `${localStorage.getItem("token1")}-`);
				console.log(responseData);
				const failedChats = responseData.filter((item) => {
					const lastMessage =
						item?.messages?.[item?.messages?.length - 1];
					if (!lastMessage) return false;
					lastMessage.date = new Date(
						lastMessage?.date?.seconds * 1000
					);
					return (
						item.comid.startsWith(localStorage.getItem("token1")) &&
						lastMessage?.message?.type === "template" &&
						new Date() - lastMessage?.date > 2 * 24 * 60 * 60 * 1000
					);
				});
				console.log("filtered items: ", failedChats);
				setFailedChat(failedChats);
			} else {
				console.error("Did not receive the info ");
			}
		} catch (error) {
			console.error("Error during Fetching:", error);
		}
	};

	useEffect(() => {
		let newSteps = localStorage.getItem("steps");
		setCurrentStep(newSteps - 1);
	}, []);

	const getAdmin = async () => {
		try {
			const adminId = localStorage.getItem("token");
			const response = await fetch(`${backendUrl}/admin/${adminId}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
			console.log("response", response);

			if (response.ok) {
				const responseData = await response.json();
				setWebtoken(responseData?.webhookSecret);
				setName(responseData?.name);
				setAgent(responseData?.agents.length || 0);
				console.log("responseData admin", responseData);
			} else {
				console.error("Did not receive the info ");
			}
		} catch (error) {
			console.error("Error during Fetching:", error);
		}
	};
	const getCompany = async () => {
		try {
			const adminId = localStorage.getItem("token");
			const response = await fetch(
				`${backendUrl}/company/${localStorage.getItem("token1")}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			console.log("response", response);

			if (response.ok) {
				const responseData = await response.json();

				console.log("responseData company", responseData);
				setData(responseData.data);
			} else {
				console.error("Did not receive the info ");
			}
		} catch (error) {
			console.error("Error during Fetching:", error);
		}
	};
	useEffect(() => {
		if (!localStorage.getItem("token")) {
			navigate("/agentlogin");
		}
		if (localStorage.getItem("isFirstTimeUser") === "true") {
			navigate("/startonboarding");
		}
		getAdmin();
		getCompany();
		getChats();
	}, []);

	return (
		<>
			<div>
				<div>
					<Drawer />
				</div>

				<div className="primary-container">
					<h2>👋 Hello, {name} Welcome</h2>

					<div className="primary-row">
						{webtoken === "Lorem ipsum" && (
							<div className="row-container-1">
								<div className="row-container-text">
									<h3 className="txt-inside-row-container">
										WhatsApp{" "}
										<BsWhatsapp
											style={{
												color: "green",
												padding: "-5px 5px -5px 10px",
											}}
										/>
									</h3>
									<span className="txt-inside-row-container">
										Explore our bot using 000000000 as your
										demo whatsapp number
									</span>
								</div>
								<button
									className="primary-row-btn"
									onClick={() => navigate("/profile")}
								>
									+ Add your webtoken
								</button>
							</div>
						)}

						{localStorage.getItem("steps") <= "6" && (
							<div className="row-container-2">
								<div className="txt-btn-block">
									<div className="row-container-text">
										<h3 className="txt-inside-row-container">
											Update your Details
										</h3>
										<span className="txt-inside-row-container">
											Fill up all your details for a
											complete experiences
										</span>
									</div>
									<button
										className="primary-row-btn"
										onClick={() => {
											localStorage.setItem(
												"isFirstTimeUser",
												true
											);
											navigate("/startonboarding");
										}}
									>
										Complete your profile
									</button>
								</div>
								<div class="progress-bar">
									<div
										class="progress-step"
										style={updateProgress()}
									></div>
								</div>
								<div className="text-steps">{`${currentStep}/${totalSteps} steps completed`}</div>
							</div>
						)}
					</div>

					<div className="secondary-row">
						<h2>
							<IoIosAnalytics style={{ color: "blue" }} /> Your
							Analytics
						</h2>
						<div>
							These stats are for all conversation which were
							intiated by customers in the selected period
						</div>
						<div className="row-stat-blocks">
							<div className="stat-block">
								<span>Number of Customers</span>
								<span className="stat-bloc-txt">
									{data?.customers?.length || 0}
								</span>
							</div>
							<div className="stat-block">
								<span>Active Campaigns</span>
								<span className="stat-bloc-txt">
									{data?.campaigns?.length || 0}
								</span>
							</div>
							<div className="stat-block">
								<span>Active Chats</span>
								<span className="stat-bloc-txt">
									{activeChat?.length || 0}
								</span>
							</div>
							<div className="stat-block">
								<span>Failed Chats</span>
								<span className="stat-bloc-txt">
									{failedChat?.length || 0}
								</span>
							</div>
						</div>

						<div className="row-stat-graphs">
							<div className="stat-graph">
								<span>Conversions</span>
								<span className="stat-bloc-txt">
									{data?.conversion || 0}
								</span>
							</div>
							<div className="stat-graph">
								<span>Lead Captured</span>
								<span className="stat-bloc-txt">
									{data?.leads || 0}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
