import React, { useEffect, useState } from "react";
import style from "./BankDash.module.css";
import backendUrl from "../../config";
import Drawer from "../Drawer/Drawer";
import { NavLink } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { FaPercent } from "react-icons/fa";
import toast from "react-hot-toast";

const EditModal = ({ selectedItem, closeModal }) => {
  console.log("selectedItem", selectedItem);
  const [bankData, setbankData] = useState(
    selectedItem || {
      name: "",
      roi: [],
      foir: "",
      uploadlink: "",
    }
  );
  const tenure = [3, 6, 9, 12, 24, 36];
  const handleinputchange = (index, value) => {
    // Assuming 'tenure' is an array
    setbankData((prevBankData) => {
      const updatedRoi = [...prevBankData.roi];
      updatedRoi[index] = value.target.value;
      return {
        ...prevBankData,
        roi: updatedRoi,
      };
    });
  };

  console.log("bankData", bankData);

  const handledetails = (e) => {
    const { name, value } = e.target;
    setbankData((prevBankData) => ({
      ...prevBankData,
      [name]: value,
    }));
  };

  const handleEditBank = async () => {
    try {
      const passData = {
        name: bankData.name,
        foir: bankData.foir,
        roi: bankData.roi,
        id: selectedItem.id,
        uploadlink: bankData.uploadlink,
      };
      const response = await fetch(`${backendUrl}/bank/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passData),
      });
      const responseData = await response.json();
      console.log("bank data", responseData);

      if (response.ok) {
        window.location.reload();
        toast.success("Bank details updated");
      } else {
        console.error("creation failed");
      }
    } catch (error) {
      console.error("Error during creation:", error);
    }
  };

  const handleAddBank = async () => {
    try {
      const response = await fetch(`${backendUrl}/bank/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...bankData,
          companyId: localStorage.getItem("token1"),
        }),
      });
      const responseData = await response.json();
      console.log("bank data", responseData);

      if (response.ok) {
        toast.success("Bank details added");
        window.location.reload();
        closeModal();
      } else {
        console.error("creation failed");
      }
    } catch (error) {
      console.error("Error during creation:", error);
    }
  };
  return (
    <div className={style.popup}>
      <div className={style.popup_inner}>
        <RxCross2
          style={{ float: "right", right: "0px", cursor: "pointer" }}
          onClick={closeModal}
        />
        <h2>Add Bank</h2>
        <div className={style.bankform}>
          <div className={style.bankfield}>
            <div>
              <label>Name of the Bank</label>
            </div>
            <input
              type='text'
              name='name'
              placeholder='Enter name'
              value={bankData.name}
              onChange={handledetails}
            />
          </div>
          <div className={style.bankfield}>
            <div>
              <label>Document upload link</label>
            </div>
            <input
              type='text'
              name='uploadlink'
              placeholder='Enter upload link'
              value={bankData.uploadlink}
              onChange={handledetails}
            />
          </div>
          <div className={style.bankfield}>
            <div>
              <div>
                <label>Foir (In Percent)</label>
              </div>
            </div>

            <input
              type='text'
              name='foir'
              placeholder='Enter FOIR value'
              value={bankData.foir}
              onChange={handledetails}
              style={{ width: "40%" }}
            />
            <FaPercent />
          </div>
          <div className={style.bankfield}>
            <label>Tenure</label>
            {tenure.map((item, index) => {
              return (
                <>
                  <div className={style.monthField}>
                    <span>{item} months</span>
                    <span>
                      <input
                        type='text'
                        value={bankData.roi[index]}
                        onChange={(value) => handleinputchange(index, value)}
                        style={{ width: "100%" }}
                        placeholder='Enter ROI value'
                      />
                    </span>
                    <FaPercent />
                  </div>
                </>
              );
            })}
          </div>

          <div
            className={style.bankaddbtn}
            onClick={selectedItem ? handleEditBank : handleAddBank}
          >
            Add
          </div>
        </div>
      </div>
    </div>
  );
};

const BankDash = () => {
  const [bankList, setbankList] = useState();
  const [openModal, setopenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [editClick, setEditClick] = useState(false);

  const closeModal = () => {
    setopenModal(false);
    setEditClick(false);
    setSelectedItem();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/bank/all/${localStorage.getItem("token1")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log("getbanks data", responseData);

        if (response.ok) {
          setbankList(responseData.banks);
        } else {
          console.error("Fetching failed");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete?");
    console.log(confirmed);
    if (confirmed) {
      try {
        const response = await fetch(`${backendUrl}/bank/delete`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
        });
        const responseData = await response.json();
        console.log("delete data", responseData);

        if (response.ok) {
          window.location.reload();
          toast.success("Bank deleted");
        } else {
          console.error("deletion failed");
        }
      } catch (error) {
        console.error("Error during deletion:", error);
      }
    } else {
      toast.error("deletion cancelled");
    }
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setopenModal(true);
  };

  return (
    <>
      <Drawer />
      <div className='profileOuter'>
        <div className='lead-container'>
          {localStorage.getItem("isFirstTimeUser") === "false" && (
            <div className='lead-link-bar'>
              <NavLink to='/leadConversion' style={{ textDecoration: "none" }}>
                <span>Lead Conversion</span>
              </NavLink>
              <NavLink
                to='/required-document'
                style={{ textDecoration: "none" }}
              >
                <span>Required Documents</span>
              </NavLink>
              <NavLink to='/banks' style={{ textDecoration: "none" }}>
                <span>Add Banks</span>
              </NavLink>
            </div>
          )}

          <div className={style.addBanksContainer}>
            {bankList?.map((item) => (
              <div
                className={style.BankDetails}
                style={{ position: "relative" }}
              >
                <MdEdit
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    fontSize: "30px",
                    color: "green",
                    cursor: "pointer",
                  }}
                  onClick={() => handleEdit(item)}
                />
                <MdDelete
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "20px",
                    right: "60px",
                    fontSize: "30px",
                    color: "red",
                  }}
                  onClick={() => handleDelete(item.id)}
                />
                <h2>{item.name}</h2>
                <div className={style.bankDetailsTable}>
                  <div className={style.column}>
                    <span className={style.tableHead}>Tenure</span>
                    <span>3 Months</span>
                    <span>6 Months</span>
                    <span>9 Months</span>
                    <span>12 Months</span>
                    <span>24 Months</span>
                    <span>36 Months</span>
                  </div>
                  <div className={style.column}>
                    <span className={style.tableHead}>ROI</span>
                    {item.roi.map((i) => (
                      <span>{i}</span>
                    ))}
                  </div>
                </div>
                <h3>FOIR : {item.foir}</h3>
              </div>
            ))}

            <div
              className={style.BankDetails}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => setopenModal(true)}
            >
              <div style={{ textAlign: "center" }}>
                <IoMdAdd style={{ fontSize: "5rem" }} />
                <p>Create New</p>
              </div>
            </div>
          </div>

          {openModal && (
            <EditModal selectedItem={selectedItem} closeModal={closeModal} />
          )}
        </div>
      </div>
    </>
  );
};

export default BankDash;
