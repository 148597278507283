import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import "./index.css";
import img from "../../Assets/img.jpg";
import { FaGoogle } from "react-icons/fa";
import { Navigate, UNSAFE_NavigationContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import eye from "../../Assets/eye.png";
import toast from "react-hot-toast";
import backendUrl from "../../config";
// import { requestToken } from "../../firebase";
console.log(backendUrl);

const SignIn = ({ setLoginForm }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    password: "",
    email: "",
  });

 const navigate = useNavigate();
 
   useEffect(() => {
     const isUserLoggedIn = localStorage.getItem("token") !== null;

     if (isUserLoggedIn) {
       navigate("/dashboard");
     }
   }, [navigate]);

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
 

  const validateForm = () => {
    let valid = true;

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    // Password validation
    if (formData.password.length < 8) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 8 characters long",
      }));
      valid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }

    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    console.log("formData", formData);
    console.log(errors);
    if (validateForm()) {
      try {
        console.log(`${backendUrl}/auth/signin`);
        const response = await fetch(`${backendUrl}/auth/signin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const res = await response.json();
        console.log("res", res);

        if (response.ok) {
          //Redirect to dashboard upon successful signin
          toast.success(res.message);
          localStorage.setItem("steps",res.data.steps)
          
          //localStorage.setItem('token1', res.data.company);
          console.log("res.data.company", res.data.company);
          console.log(localStorage.getItem("token1"));
          if (res.data.company === undefined && res.data.whatsappNumber) {
            console.log("entered");
            const fetchData = async () => {
              try {
                const passD = {
                  adminId: res.data.id,
                  bussinessId: res.data.bussinessId,
                  leadConversion: [],
                  phoneNumberId: res.data.phoneNumberId,
                };
                console.log("passD", passD)
                const response = await fetch(`${backendUrl}/company/create`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(passD),
                });
                console.log("response", response);
                if (response.ok) {
                  const responseData = await response.json();
                  console.log("responseData", responseData);
                  localStorage.setItem("token", res.data.id);
                  localStorage.setItem("token1", responseData.companyId);
                  
                  if(res.data.steps>6){
                    localStorage.setItem("isFirstTimeUser", false);
                  }else{
                    localStorage.setItem("isFirstTimeUser", true);
                  }

                  navigate("/startonboarding");
                  
                  
                  // const tkn = await requestToken(res.data.id)
                  // const subscribeRes = await fetch(`${backendUrl}/subscribe`, {
                  //   method: "POST",
                  //   headers: {
                  //     "Content-Type": "application/json",
                  //   },
                  //   body: JSON.stringify({ id: res.data.id,topic:responseData.companyId,token:tkn }),
                  // });

                  // if(subscribeRes.ok){
                  //   navigate("/startonboarding");
                  // }
                  // else{
                  //   console.log("Failed");
                  // }                  
                 
                } else {
                  console.log("Failed");
                }
              } catch (error) {
                console.log("Error fetching", error);
              }
            };
            fetchData();
          } else if (res.data.whatsappNumber) {
            localStorage.setItem("token", res.data.id);
            localStorage.setItem("token1", res.data.company);
            
            navigate("/dashboard");

          } else {
            localStorage.setItem("agentid", res.data.id);
            localStorage.setItem("token1", res.data.companyId);
            
            navigate("/dashboard");
            
          }

          
        } else {
          // window.alert(res.message);
          console.error("Signin failed");
          toast.error(res.message);
        }
      } catch (error) {
        console.error("Error during signin:", error);
      }
    }
  };

  
  

  return (
    <div className="full-container1">
      <div className="signinimageouter">
        <img className="signinimage" src={img} alt="img" />
      </div>
      <div className="formOuter">
        <form className="container-01" onSubmit={handleSignIn}>
          <div className="header">
            <div className="text1">Sign in to your Account</div>
          </div>
          <div className="email1">
            <div className="h2">Email Id</div>
            <input
              className="c21"
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="    xyz@gmail.com"
              required
            />
            {errors?.email ? (
              <span style={{ color: "red" }}>{errors.email}</span>
            ) : (
              <></>
            )}
          </div>
          <div className="your">
            <div className="h2">Enter Your Password</div>
            <div className="WrapperIB">
              <input
                //className='c2'
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="    xxxxxxx"
                required
              />
              <button type="button" onClick={handleTogglePassword}>
                <img src={eye} alt="img" />
              </button>
            </div>
            {errors?.password ? (
              <span style={{ color: "red" }}>{errors.password}</span>
            ) : (
              <></>
            )}
          </div>
          <div className="foo-gap-2">
            <Button type="submit" variant="primary" className="signup">
              Sign In
            </Button>
          </div>
          {/* <div className="underline1">
            <div>OR</div>
          </div> */}
          <div className="sign-up-google">
            {/* <Button variant="primary" className="btn2">
              <FaGoogle style={{ color: "#4285F4" }} /> &nbsp; Sign in with
              Google
            </Button> */}
            <div>
              <p>
                New to Mastork?
                <button
                  className="signup-button"
                  onClick={() => navigate("/auth/signup")}
                >
                  Sign Up
                </button>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
