import React, { useEffect, useState } from "react";
import style from "../../CustomerManagement/CustomerDashboard/CustomerDashboard.module.css";
import delete1 from "../../../Assets/delete1.png";
import edit1 from "../../../Assets/edit1.png";
import whatsapp1 from "../../../Assets/whatsapp1png.png";
import headingImage from "../../../Assets/headingimage.png";
import Drawer from "../../Drawer/Drawer";
import { useNavigate } from "react-router-dom";
import oopsimage from "../../../Assets/oops.png";
import { useRef } from "react";
import backendUrl from "../../../config";

const DeleteGroupModal = ({ closePopup, data }) => {
  console.log("data", data);
  const handleDeleteCustomer = async () => {
    const passData = {
      companyId: localStorage.getItem("token1"),
    };
    console.log(passData);
    try {
      const response = await fetch(`${backendUrl}/group/${data.id}`, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(passData),
      });

      if (response.ok) {
        closePopup();
        window.location.reload();
      } else {
        console.log("Delete Failed");
      }
    } catch (error) {
      console.log("Error during deleting Customer ", error);
    }
  };

  return (
    <div className={style.modalOuter}>
      <div className={style.modalname}>{data.groupName}</div>
      <div className={style.modaltext}>
        Are you sure you want to delete this Group?
      </div>
      <div className={style.modalbuttons}>
        <div className={style.modalcancel} onClick={closePopup}>
          Cancel
        </div>
        <div className={style.modaldelete} onClick={handleDeleteCustomer}>
          Delete
        </div>
      </div>
    </div>
  );
};

const DeleteModal = ({ closePopup, data }) => {
  console.log("data", data);
  const handleDeleteCustomer = async () => {
    const passData = {
      phone: data.phone,
      companyId: localStorage.getItem("token1"),
    };
    console.log(passData);
    try {
      const response = await fetch(`${backendUrl}/customer`, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(passData),
      });

      if (response.ok) {
        closePopup();
        window.location.reload();
      } else {
        console.log("Delete Failed");
      }
    } catch (error) {
      console.log("Error during deleting Customer ", error);
    }
  };

  return (
    <div className={style.modalOuter}>
      <div className={style.modalname}>{data.name}</div>
      <div className={style.modaltext}>
        Are you sure you want to delete this Customer?
      </div>
      <div className={style.modalbuttons}>
        <div className={style.modalcancel} onClick={closePopup}>
          Cancel
        </div>
        <div className={style.modaldelete} onClick={handleDeleteCustomer}>
          Delete
        </div>
      </div>
    </div>
  );
};

const SelectModal = ({ closeSelectmodal }) => {
  const navigate = useNavigate();

  return (
    <div className={style.modalOuter}>
      <div className={style.modalbuttons}>
        <div
          className={style.modalcancel}
          onClick={() => navigate("/customer/bulk")}
        >
          Bulk Upload
        </div>
        <div
          className={style.modalcancel}
          onClick={() => navigate("/addcustomer")}
        >
          Single Upload
        </div>
      </div>
      <div className={style.modaldelete} onClick={closeSelectmodal}>
        Close
      </div>
    </div>
  );
};

const CustomerDashboard = () => {
  const [datalist, setDatalist] = useState({});
  const [grouplist, setGrouplist] = useState([]);
  const [category, setCategory] = useState("Individuals");
  const [agentList, setAgentList] = useState();
  const [showNotInterested, setShowNotInterested] = useState(false);
  const [showHuman, setShowHuman] = useState(false);
  const [showInterested, setShowInterested] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("agentid")) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${backendUrl}/agent/${localStorage.getItem("agentid")}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log("response", response);
          const responseData = await response.json();
          console.log("responseData agent", responseData);

          if (response.ok) {
            let assignedCustomers = responseData.assignedCustomers;
            setAgentList(assignedCustomers);
          } else {
            console.error("Did not receive the  agent ");
          }
        } catch (error) {
          console.error("Error during Fetching:", error);
        }
      };
      fetchData();
    }
  }, []);
  console.log(" agent list data list", agentList, datalist);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/customer/all/${localStorage.getItem("token1")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log("customer response data", responseData);

        if (response.ok) {
          let allcust = responseData.customers;

          if (agentList && localStorage.getItem("agentid")) {
            const combinedData = agentList.map(id => {
              const matchingData = allcust.find(item => item.comid === id);
              return matchingData ? { id, ...matchingData } : null;
            });
            console.log("combinedData", combinedData);

            setDatalist(combinedData);
          } else {
            setDatalist(allcust);
          }
        } else {
          console.error("Fetching failed");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchData();
  }, [agentList]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/group/all/${localStorage.getItem("token1")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log("group response data", responseData);

        if (response.ok) {
          setGrouplist(responseData);
        } else {
          console.error("Fetching failed");
        }
      } catch (error) {
        console.error("Error during Fetching:", error);
      }
    };
    fetchdata();
  }, []);

  const [selecteditem, setSelecteditem] = useState();
  const [selectedgroup, setSelectedgroup] = useState();
  const [selectModal, setSelectmodal] = useState(false);
  const closePopup = () => {
    setSelecteditem();
    setSelectedgroup();
  };
  const handleDelete = item => {
    setSelecteditem(item);
  };
  const handleDeleteGroup = item => {
    setSelectedgroup(item);
  };
  const closeSelectmodal = () => {
    setSelectmodal(false);
  };

  return (
    <>
      <Drawer />

      {/* Existing modals */}
      {selectedgroup && (
        <div className={style.popup}>
          <div className={style.popup_inner}>
            <DeleteGroupModal closePopup={closePopup} data={selectedgroup} />
          </div>
        </div>
      )}
      {selecteditem && (
        <div className={style.popup}>
          <div className={style.popup_inner}>
            <DeleteModal closePopup={closePopup} data={selecteditem} />
          </div>
        </div>
      )}
      {selectModal && (
        <div className={style.popup}>
          <div className={style.popup_inner}>
            <SelectModal closeSelectmodal={closeSelectmodal} />
          </div>
        </div>
      )}

      <div className={style.cdMainOuter}>
        <div className={style.cdOuter}>
          <div className={style.cdupper}>
            <div className={style.cdHeading}>
              <div className={style.cdheadingwrapper}>
                <div className={style.cdheading1}>
                  <img className={style.cdimg} src={headingImage} alt="img" />
                  Customer Management
                </div>
                <div
                  className={style.cdbtn}
                  onClick={() => setSelectmodal(true)}
                >
                  + Add Customer
                </div>
              </div>
              <div className={style.cdheadingtext}>
                Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                Lorem Ipsum Lorem Ipsum Lorem Ipsum
              </div>
            </div>
          </div>

          {/* Add the filter UI elements here */}
          <div className={style.filter} ref={dropdownRef}>
      <button
        className={style.dropdownButton}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        Filter Options
      </button>
      {dropdownOpen && (
        <div className={style.dropdownMenu}>
          <label>
            <input
              type="checkbox"
              checked={showNotInterested}
              onChange={() => setShowNotInterested(!showNotInterested)}
            />
            Show Not Interested
          </label>
          <label>
            <input
              type="checkbox"
              checked={showHuman}
              onChange={() => setShowHuman(!showHuman)}
            />
            Show Human
          </label>
          <label>
            <input
              type="checkbox"
              checked={showInterested}
              onChange={() => setShowInterested(!showInterested)}
            />
            Show Interested
          </label>
        </div>
      )}
    </div>

          <div className={style.cdcatbuttons}>
            <div
              className={
                category === "Individuals" ? style.cdselect : style.cddeselect
              }
              onClick={() => setCategory("Individuals")}
            >
              Individuals
            </div>
            <div
              className={
                category === "Groups" ? style.cdselect : style.cddeselect
              }
              onClick={() => setCategory("Groups")}
            >
              Groups
            </div>
          </div>

          {category === "Individuals" ? (
            <div className={style.cdbottom}>
              {Object.keys(datalist).length === 0 ? (
                <div className={style.adoopsOuter}>
                  <div className={style.cdheading1}>Oops</div>
                  <div className={style.adoopstext}>
                    Looks like you have not added any customer
                  </div>
                  <div className={style.adoopsimg}>
                    <img style={{ width: "54%" }} src={oopsimage} alt="img" />
                  </div>
                  <div
                    className={style.cdbtn}
                    onClick={() => setSelectmodal(true)}
                  >
                    + Add Customer
                  </div>
                </div>
              ) : (
                <>
                  {Object.values(datalist).map(
                    item =>
                      // Add conditions for the new filters
                      (!showNotInterested || item.notInterested) &&
                      (!showHuman || item.human) &&
                      (!showInterested || item.interested) && (
                        <div className={style.cdone} key={item.id}>
                          <div
                            className={style.cdname}
                            onClick={() =>
                              navigate(
                                `/customerdetails/${`${localStorage.getItem(
                                  "token1"
                                )}-${item.phone}`}`
                              )
                            }
                          >
                            {item.name}
                          </div>
                          <div className={style.cdcontent}>
                            <div
                              className={style.cdinfoOuter}
                              onClick={() =>
                                navigate(`/customerdetails/${item.comid}`)
                              }
                            >
                              {item.email ? (
                                <div className={style.cdinfo}>
                                  <div className={style.cdsmallheading}>
                                    Email
                                  </div>
                                  <div className={style.cdsmalldata}>
                                    {item.email}
                                  </div>
                                </div>
                              ) : null}
                              <div className={style.cdinfo}>
                                <div className={style.cdsmallheading}>
                                  Phone Number:
                                </div>
                                <div className={style.cdsmalldata}>
                                  {item.phone}
                                </div>
                              </div>
                            </div>
                            <div
                              className={style.cdicons}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={edit1}
                                alt="img"
                                onClick={() =>
                                  navigate(`/customer/update/${item.comid}`)
                                }
                              />
                              <img
                                src={delete1}
                                alt="img"
                                onClick={() => handleDelete(item)}
                              />
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </>
              )}
            </div>
          ) : (
            <div className={style.cdbottom}>
              {grouplist && grouplist?.length === 0 ? (
                <div className={style.adoopsOuter}>
                  <div className={style.cdheading1}>Oops</div>
                  <div className={style.adoopstext}>
                    Looks like you have not added any Group
                  </div>
                  <div className={style.adoopsimg}>
                    <img style={{ width: "54%" }} src={oopsimage} alt="img" />
                  </div>
                  {/*<div className={style.cdbtn} onClick={()=>setSelectmodal(true)}>+ Add Customer</div>*/}
                </div>
              ) : (
                <>
                  {Object.values(grouplist).map(item => (
                    <div className={style.cdone} key={item.id}>
                      <div
                        className={style.cdname}
                        onClick={() => navigate(`/single-group/${item.id}`)}
                      >
                        {item.groupName}
                      </div>
                      <div className={style.cdcontent}>
                        <div
                          className={style.cdinfoOuter}
                          onClick={() => navigate(`/single-group/${item.id}`)}
                        >
                          <div className={style.cdinfo}>
                            {item.customers && (
                              <div className={style.cdsmalldata}>
                                {item.customers.length}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className={style.cdicons}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={edit1}
                            alt="img"
                            onClick={() => navigate(`/group/update/${item.id}`)}
                          />
                          <img
                            src={delete1}
                            alt="img"
                            onClick={() => handleDeleteGroup(item)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerDashboard;
